import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import format from 'date-fns/format';
import { Paginator } from '../../components/Paginator';
import { fetchUsers } from '../../store/actions/userAction';
import { Link } from 'react-router-dom';
import { useDebouncedEffect } from '../../util/useDebouncedEffect';
import { deleteUser, updateUserStatus } from '../../services/userService';
import useService from '../../util/useService';
import Dialog from '../../util/Dialog';
import Notify from '../../util/Notify';

export function AchievedUsers() {
  const dispatch = useDispatch();

  const { total, loading, users } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const deleteUserCall = useService(deleteUser);


  /**
   *
   * @param {User} user
   */
  const removeUser = async (user) => {
    if(!await Dialog.confirm(`Are you sure you want to delete ${user.fname} ${user.lname}'s account?`)){
      return;
    }

    deleteUserCall
      .call(user.id)
      .onSuccess(() => {
        Notify.success('User account deleted successfully');
        dispatch(fetchUsers(page, search));
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {number} userId
   */
  const unArchiveUser = async (userId) => {
    if(!await Dialog.confirm(`Are you sure you want to activated this user?`)){
      return;
    }
    try{
      await updateUserStatus(userId, 'active');
      Notify.success("User account activated successfully!");
      dispatch(fetchUsers(page, search));
    }
    catch(e) {
      Notify.error(e.message)
    }
  }

  useEffect(() => {
    dispatch(fetchUsers(page, search, {status: 'inactive'}));
  }, [dispatch, page, search]);

  useDebouncedEffect(() => {
    dispatch(fetchUsers(page, search, {status: 'inactive'}));
  },300, [dispatch, page, search]);

  return (
    <>
      <div className="back-button-parent">
      <Link to="/users" className="back-button-flex w-inline-block">
        <div className="back-button-icon" />
        <div>Back</div>
      </Link>
    </div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Archived Users</h1>

          <div className="extended-heading-line custom-pools"></div>
          <div className="filter-button-flex-parent">
    </div>
        </div>
        <div className="custom-input-wrapper">
          <div className="search-input-tag w-embed">
            <input
              type="text"
              className="text-input-field custom"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="filter-button-flex-parent">
            {/* <div className="margin-bottom w-embed">
              <select className="select-input custom no-caps">
                <option value="">Select Investment Pool</option>
              </select>
            </div>
            <div className="dummy-block smaller"></div>
            <div className="w-embed">
              <select value={filterStatus} onChange={(e)=>setFilterStatus(e.target.value)} className="select-input custom no-caps dark-brown">
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">InActive</option>
              </select>
            </div> */}
          </div>
        </div>
        <div>
          <div className="investment-breakdown-header">
            <div className="content-block transaction-history">
              <div>Joined</div>
            </div>
            <div className="content-block transaction-history">
              <div>name</div>
            </div>
            <div className="content-block transaction-history-decription">
              <div>email</div>
            </div>
            <div className="content-block transaction-history">
              <div>Gender</div>
            </div>
            <div className="content-block transaction-history">
              <div>City</div>
            </div>
            <div className="content-block transaction-history">
              <div>Country</div>
            </div>
            <div className="content-block action-block">
              <div>Action</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            {users.map((user) => (
              <UserItem user={user} removeUser={removeUser} unArchiveUser={unArchiveUser} />
            ))}
            {users.length === 0 && !loading ? (
              <div className="no-active-pool">
                <div>No users to display</div>
              </div>
            ) : null}
            <Loading isLoading={users.length === 0 && loading} />
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}
/**
 *
 * @param {{user:User, removeUser:Function, unArchiveUser: Function}} param0
 */
function UserItem({ user, removeUser, unArchiveUser }) {
  return (
    <div className="table-info-flex">
      <div className="column-content transction-history">
        <div className="content-block mobile-header">
          <div>joined</div>
        </div>
        <div>
          <div>{format(new Date(user.created_at), 'd LLL yyyy')}</div>
        </div>
      </div>
      <div className="column-content transction-history">
        <div className="content-block mobile-header">
          <div>name</div>
        </div>
        <div>
          <div>
            {user.fname} {user.lname}
          </div>
        </div>
      </div>
      <div className="column-content transaction-history-description">
        <div className="content-block mobile-header">
          <div>email</div>
        </div>
        <div>
          <div>{user.email}</div>
        </div>
      </div>
      <div className="column-content amount transaction-history">
        <div className="content-block mobile-header">
          <div>Gender</div>
        </div>
        <div>
          <div>{user.gender}</div>
        </div>
      </div>
      <div className="column-content amount transaction-history">
        <div className="content-block mobile-header">
          <div>City</div>
        </div>
        <div>
          <div>{user.city}</div>
        </div>
      </div>
      <div className="column-content amount transaction-history">
        <div className="content-block mobile-header">
          <div>Country</div>
        </div>
        <div>
          <div>{user.country}</div>
        </div>
      </div>

      <div className="action-block users">
        <div className="content-block mobile-header">
          <div>Action</div>
        </div>
        <div className="action-flex auto left">
          <div className="content-block mobile-header"></div>
          <button onClick={(e) => {
              e.preventDefault();
              unArchiveUser(user.id)
            }} className="action-button transaction-history">
            <div>Unarchive</div>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              removeUser(user)
            }}
            disabled={false}
            className={`action-button transaction-history`}
            >
            <div>Delete</div>
          </button>
        </div>
      </div>
    </div>
  );
}
