import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 *
 * @param {number} id
 * @returns {Promise<User>}
 */
export function getUser(id){
  return api.get(`${baseUrl}/users/${id}`);
}

/**
 *
 */
export function changePassword(params){
  return api.post(`${baseUrl}/auth/change-password`, {...params});
}

export function exportUsers(query = {}){
  return api.get(`${baseUrl}/users/export`, {
    params: {
      ...query
    }
  }).then(function(response){

    download('users.csv', response);
    Notify.success("Users data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}

export function exportPayout(query = {}){
  return api.get(`${baseUrl}/transactions/export/payout`, {
    params: {
      ...query
    }
  }).then(function(response){

    download('payout.csv', response);
    Notify.success("Payouts data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}
/**
 *
 * @param {number} userId
 * @param {string} status
 */
export function updateUserStatus(userId, status){
  return api.put(`${baseUrl}/users/update-status`, { id: userId, status: status});
}

/**
 *
 * @param {number} userId
 */
export function deleteUser(userId){
  return api.delete(`${baseUrl}/users/${userId}`);
}

/**
 *
 * @param {number} id
 */
export function bypassUserBVN(id){
  return api.put(`${baseUrl}/users/toggle-kyc-bypass`, {id});
}

/**
 *
 * @param {number} id
 * @param {string} reportLink
 */
export function updateReportLink(id, reportLink){
  return api.put(`${baseUrl}/users/update-reporting-link`, {id, report_link: reportLink});
}



/**
 *
 */
export function createAdmin(params){
  return api.post(`${baseUrl}/users/admin`, {...params});
}


export function payoutUsers(payload){
  return api.post(`${baseUrl}/payments/payout`, {...payload});
}

export function payoutOTP(){
  return api.get(`${baseUrl}/payments/payout-otp`);
}


export function getAdmins(){
  return api.get(`${baseUrl}/users`, {params:{
    has_admin_access: 'true'
  }});
}

export function getUsers(search){
  return api.get(`${baseUrl}/users`, {params:{
    search
  }});
}

export function  getWalletUsers(page) {
  return api.get(`${baseUrl}/users`, {
    params: {
      page,
      wallet_not_empty: true
    }
  });
}