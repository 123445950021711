import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPool } from '../../store/actions/poolAction';
import Loading from '../../components/Loading';
import { fetchPortfolio } from '../../store/actions/portfolioAction';
import { Paginator } from '../../components/Paginator';
import { PortfolioRow } from './PortfolioRow';
import { deletePortfolio, exportPortfolios } from '../../services/poolService';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import scrollToTop from '../../util/scrollToTop';
import Permission from '../../components/Permission'

export default function ContributionPool() {
  const dispatch = useDispatch();

  const { loading: portfolioLoading, portfolios, total: totalPortfolios } = useSelector((state) => state.portfolio);

  const [portfolioPage, setPorfolioPage] = useState(1);


  const deletePortfolioCaller = useService(deletePortfolio);

  /**
   *
   * @param {Portfolio} portfolio
   */
  const removePortfolio = (portfolio) => {
    deletePortfolioCaller.call(portfolio.id).onSuccess(() => {
      dispatch(fetchPortfolio(portfolioPage));
      Notify.success("Portfolio delete successfully!")
    })
    .onError((e) => Notify.error(e.message))
  }

  useEffect(() => {
    dispatch(fetchPool());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchPortfolio(portfolioPage));
    scrollToTop()
  }, [dispatch, portfolioPage]);


  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">All Contributions</h1>
          <div className="extended-heading-line custom-pools"></div>
          <div className="filter-button-flex-parent">
            {/* <div className="w-embed">
              <select className="select-input custom">
                <option value>Filter</option>
              </select>
            </div> */}
            <div className="dummy-block smaller" />
            <button onClick={(event) =>{
              event.preventDefault();
              exportPortfolios({});
            }} className="download-csv w-inline-block">
              <div>DOWNLOAD&nbsp;CSV</div>
            </button>
          </div>
        </div>
        <div>
          <div className="investment-breakdown-header">
            <div className="content-block extended-width">
              <div>Bought</div>
            </div>
            <div className="content-block extended-width">
              <div>User</div>
            </div>
            <div className="content-block extended-width">
              <div>Pool</div>
            </div>
            <div className="content-block extended-width">
              <div>amount</div>
            </div>
            <div className="content-block extended-width">
              <div>ROI</div>
            </div>
            <div className="content-block extended-width">
              <div>First Investment</div>
            </div>
            <div className="content-block extended-width">
              <div>Start</div>
            </div>
            <div className="content-block extended-width">
              <div>Yield</div>
              </div>
            <Permission roles={['super-admin']}>
              <div className="content-block action-block">
                <div>Action</div>
              </div>
            </Permission>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={portfolioLoading && portfolios.length === 0} />
            {portfolios.map((portfolio) => (
              <PortfolioRow deleteItem={removePortfolio} key={portfolio.id} portfolio={portfolio} />
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPorfolioPage} total={totalPortfolios} page={portfolioPage} />
    </>
  );
}
