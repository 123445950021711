import React from 'react';

const CustomCheckbox = ({
  onChange = () => {},
  checked = false,
  label = '',
  name = 'checkbox',
}) => {
  return (
    <div className="custom-checkbox">
      <div className={`checkbox-input-wrapper ${checked ? 'checked' : ''}`}>
        <input
          checked={checked}
          name={name}
          onChange={onChange}
          type="checkbox"
          className="checkbox-input-custom"
        />
        {checked && <img src="/assets/images/check-icon.svg" className="checkbox-icon" alt="" />}
      </div>
      {label && (
        <label htmlFor={name} className="checkbox-label">
          {label}
        </label>
      )}
    </div>
  );
};

export default CustomCheckbox;
