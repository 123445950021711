import React from 'react';
import clsx from 'clsx';
const paginate = require('jw-paginate');
/**
 *
 * @param {{page:number, total: number, setPage: (page:number)=>void }} param0
 */
export function Paginator({ page, total, setPage }) {
  const perPage = 50;
  const maxPages = 5;
  const pager = paginate(total, page, perPage, maxPages);

  return (
    <div className="pagination-wrapper">
      <div className="pagination-flex">
        <button
          onClick={() => setPage(pager.currentPage - 1)}
          className={clsx('pagination-item arrow left', {
            'btn-disabled': pager.currentPage === 1,
          })}
        ></button>

        {pager.pages.map((page) => (
          <button
            key={page}
            onClick={() => setPage(page)}
            className={clsx('pagination-item', { active: page === pager.currentPage })}
          >
            <div>{page} </div>
          </button>
        ))}
        <button
          onClick={() => setPage(pager.currentPage + 1)}
          className={clsx('pagination-item arrow right', {
            'btn-disabled': pager.currentPage === pager.totalPages,
          })}
        ></button>
      </div>
    </div>
  );
}
