/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPool } from '../../store/actions/poolAction';
import formatMoney from '../../util/formatMoney';
import Loading from '../../components/Loading';
import { fetchPortfolio } from '../../store/actions/portfolioAction';
import format from 'date-fns/format';
// import { Paginator } from '../../components/Paginator';
import { Link } from 'react-router-dom';
import { fetchTransaction } from '../../store/actions/transactionAction';
import { getPoolOverview } from '../../services/poolService';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { getGrowths } from '../../services/growthService';
import { getLogs } from '../../services/logService';
import { PoolCard } from '../../components/PoolCard';

import Permission from '../../components/Permission'

export default function Overview() {
  const dispatch = useDispatch();
  const { loading: poolLoading, pools } = useSelector((state) => state.pool);
  const { loading: transactionLoading, transactions } = useSelector((state) => state.transaction)


  const poolOverviewCall = useService(getPoolOverview);
  /**
   * @type {{
   *    current:{subscriptions:number,value:number},
   *    lifetime:{subscriptions:number,value:number}
   * }}}
   */
  const poolOverview = poolOverviewCall.data;
  const getGrowthCall = useService(getGrowths);

  /**
   * @type {VolitionGrowth[]}
   */
  const growths = getGrowthCall?.data?.data ?? [];

  const [portfolioPage] = useState(1);

  const contributionPools = pools.filter((pool) => pool.type !== 'custom');
  const customPools = pools.filter((pool) => pool.type === 'custom');

  const logCaller = useService(getLogs);

  /**
   * @type {Log[]}
   */
  const logs = logCaller.data?.data ?? [];
  
  useEffect(() => {
    getGrowthCall.call('1').onError((e) => Notify.error(e.message));
  }, []);

  useEffect(() => {
    dispatch(fetchPool());
    dispatch(fetchTransaction(1));
    poolOverviewCall.call().onError((e) => Notify.error(e.message));
    logCaller.call().onError((e) => Notify.error(e.message));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPortfolio(portfolioPage));
  }, [dispatch, portfolioPage]);

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block">
          <h1 className="brown-text">Overview</h1>
          <div className="extended-heading-line" />
        </div>
        <div className="overview-card-holder overview">
          <div className="pool-card big first">
            <div className="pool-card-upper">
              <div className="pool-title">
                <div>Current Net Value</div>
              </div>
              <div className="pool-amount">
                <div>{poolOverview ? formatMoney(poolOverview.current.value) : 'Loading...'} </div>
              </div>
              <div className="pool-roi-and-duration-flex"></div>
            </div>
            <div className="total-subscription-flex-wrapper">
              <div>
                <div className="total-contributions">
                  <div className="gray-text">Total Contributions</div>
                </div>
                <div className="contribution-number">
                  <div className="brown-text">
                    {poolOverview ? poolOverview.current.subscriptions : 'Loading...'}
                  </div>
                </div>
              </div>
              <div>
                <a href="/pools" className="view-all-button brown w-button">
                  View All
                </a>
              </div>
            </div>
          </div>
          <div className="pool-card big">
            <div className="pool-card-upper">
              <div className="pool-title">
                <div>Lifetime Value</div>
              </div>
              <div className="pool-amount">
                <div>{poolOverview ? formatMoney(poolOverview.lifetime.value) : 'Loading...'}</div>
              </div>
              <div className="pool-roi-and-duration-flex">
                <div className="roi-flex"></div>
              </div>
            </div>
            <div className="total-subscription-flex-wrapper">
              <div>
                <div className="total-contributions">
                  <div className="gray-text">Total Contributions</div>
                </div>
                <div className="contribution-number">
                  <div className="brown-text">
                    {poolOverview
                      ? poolOverview.lifetime.subscriptions
                      : 'Loading...'}
                  </div>
                </div>
              </div>
              <div>
                <a href="/pools" className="view-all-button brown w-button">
                  View All
                </a>
              </div>
            </div>
          </div>


          <div className="pool-card big">
            <div className="pool-card-upper">
              <div className="pool-title">
                <div>Number Of Admin Fees Paid</div>
              </div>
            </div>
            <div className="total-subscription-flex-wrapper">
              <div>
                <div className="total-contributions">
                  <div className="gray-text">Total Fee</div>
                </div>
                <div className="contribution-number">
                  <div className="brown-text">
                    {/* {poolOverview
                      ? poolOverview.lifetime.subscriptions
                      : 'Loading...'} */}
                      1822
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pool-section">
        <div className="section-heading-block">
          <h1 className="brown-text">Growth</h1>
          <div className="extended-heading-line" />
          <Permission roles={['super-admin']}>
            <Link to="/volition-growth" className="view-all-button small brown w-button">
              Create New
            </Link>
          </Permission>
        </div>
        <div className="overview-card-holder overview">
          {growths.map(growth => (
            <Link to={`/volition-growth/${growth.id}`} className="pool-card big first">
              <div className="pool-card-upper">
                <div className="pool-title">
                  <div>{ growth.title }</div>
                  <span className="gray-text">Duration: {growth.duration ? `${growth.duration} Months` : 0}</span>
                </div>
                <div className="pool-amount">
                  <div>{growth.cost ? formatMoney((growth.cost * (growth.slot - growth.availableSlot)), growth.currency === 'naira' ? '₦' : '$') : '₦ 0'} </div>
                </div>
                <div className="pool-roi-and-duration-flex"></div>
              </div>
              <div className="total-subscription-flex-wrapper">
                <div>
                  <div>
                    <div className="brown-text">
                      <span className="gray-text">
                        Total number of slots:
                      </span>
                      {growth.slot ? `${growth.slot} slots` : 0}
                    </div>
                    <div className="brown-text">
                      <span className="gray-text">
                        Number of slots purchased:
                      </span>
                      {`${growth.slot - growth.availableSlot} slots`}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <Loading isLoading={growths.length === 0 && poolLoading} />
        {!poolLoading && growths.length === 0 ?
          <div className="no-active-pool">
            <div>No growth post available</div>
          </div> : null}
      </div>
      <div className="pool-section">
        <div className="section-heading-block">
          <h1 className="brown-text">Current Pool Values</h1>
          <div className="extended-heading-line" />
        </div>
        <div>
          <div className="overview-card-holder">
            {contributionPools.map((pool) => (
              <PoolCard key={pool.id} pool={pool} />
            ))}
          </div>
          <Loading isLoading={contributionPools.length === 0 && poolLoading} />
          {contributionPools.length === 0 && !poolLoading ? (
            <div className="no-active-pool">
              <div>No available contribution pools</div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="pool-section margin-bottom">
        <div className="section-heading-block">
          <h1 className="brown-text">Custom Pools</h1>
          <div className="extended-heading-line custom-pools" />
          <Permission roles={['super-admin']}>
            <Link to="/pools/create" className="view-all-button small brown w-button">
              Create New
            </Link>
          </Permission>
        </div>
        <div>
          <div className="overview-card-holder">
            {customPools.map((pool) => (
              <PoolCard key={pool.id} pool={pool} />
            ))}
            <div className="hide"></div>
          </div>
          <div className="dashboard-content-wrapper-inner">
            <Loading isLoading={customPools.length === 0 && poolLoading} />
          </div>
          {customPools.length === 0 && !poolLoading ? (
            <div className="no-active-pool">
              <div>No available custom pools</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="pool-section">
        <div className="pool-section-inner-flex">
          <div className="dashboard-content-wrapper recent-transaction">
            <div className="dashboard-content-wrapper-top-border-wrapper">
              <div className="smaller-border-line" />
              <div className="dashboard-content-heading flex">
                <div className="margin-right">Transaction History</div>
                <Link to="transaction-history" className="view-all-button small brown w-button">
                  View All
                </Link>
              </div>
              <div className="extender-border" />
            </div>
            <div className="dashboard-content-wrapper-inner">
              <Loading isLoading={transactions.length === 0 && transactionLoading} />
              {transactions.slice(0, 5).map((transaction) => (
                <div className="recent-transaction-card">
                  <div className="pool-title-widget">
                    <div>{format(new Date(transaction.created_at), 'd LLL yyyy')}&nbsp;&nbsp;</div>
                    <div className="light-green-text">{formatMoney(transaction.amount, transaction.currency === 'NGN' ? '₦' : '$')}</div>
                  </div>
                  <div>
                    <div>{transaction.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="dashboard-content-wrapper recent-transaction last">
            <div className="dashboard-content-wrapper-top-border-wrapper">
              <div className="smaller-border-line" />
              <div className="dashboard-content-heading flex">
                <div className="margin-right">Recent Activity</div>
                <a href="/activity-logs" className="view-all-button small brown w-button">
                  View All
                </a>
              </div>
              <div className="extender-border" />
            </div>
            <div className="dashboard-content-wrapper-inner">
              <Loading isLoading={logCaller.loading || logs.length===0} />
              {logs.slice(0, 5).map((log) => (
                <div className="recent-transaction-card">
                  <div className="pool-title-widget">
                    <div>{format(new Date(log.created_at), 'd LLL yyyy')}</div>
                  </div>
                  <div>
                    <div>{log.message}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


