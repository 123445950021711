import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 *
 * @param {number} userId
 */
export  function getUserTransactions(userId){
  return api.get(`${baseUrl}/transactions/user/${userId}`);
}

/**
 *
 * @param {number} transactionId
 */
export  function deleteTransaction(transactionId){
  return api.delete(`${baseUrl}/transactions/${transactionId}`);
}

/**
 *
 * @param {number} transactionId
 * @param {{}} item
 */
export  function editTransaction(transactionId, item){
  return api.put(`${baseUrl}/transactions/${transactionId}`, item);
}

/**
 *
 * @param {{}} item
 */
export  function createTransaction(item){
  return api.post(`${baseUrl}/transactions`, item);
}

/**
 *
 * @param {number} transactionId
 */
export  function getTransaction(transactionId){
  return api.get(`${baseUrl}/transactions/${transactionId}`);
}

export function exportTransactions(query){
  return api.get(`${baseUrl}/transactions/export`, {params: query}).then(function(response){

    download('transactions.csv', response);
    Notify.success("Transactions data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}

export function resendTransactionReceipt(reference){
  return api.get(`${baseUrl}/transaction/resend_receipt?reference=${reference}`).then(function(response){
    Notify.success("Transactions receipt has been sent successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}


export function downloadTransactionReceipt(reference, user){
  return api.get(`${baseUrl}/transactions/download_receipt?reference=${reference}`, {responseType: 'blob', "headers": {
    'content-type': 'application/pdf',
    'Accept': 'application/pdf'
  }}).then(function(response){
    // window.open(encodeURIComponent('data:application/pdf;,'), '_blank');
    let blob = new Blob([response]);
    blob = blob.slice(0, blob.size, "application/pdf")
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${user.fname.toLowerCase()}-${user.lname.toLowerCase()}-receipt.pdf`);
    document.body.appendChild(link);
    link.click();
    Notify.success("Transactions receipt downloaded successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}
