import React from 'react';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';

export function RegularBodyEditor({ updateCmsPostForm, cmsPostForm }) {
  return <div className="portfolio-management-form-flex space-between">
    <div className="portfolio-managment-input-block full">
      <label htmlFor="Message" className="form-field-label">
        Body
        <span className="gray-text" />
      </label>
      <ReactQuill onChange={(html) => updateCmsPostForm('content', { body: html })}
          value={cmsPostForm.content.body?? ''} modules={modules} formats={formats} theme="snow" />
    </div>
  </div>;
}
