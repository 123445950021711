import api from '../util/api';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * @param {MediaPost} mediaPost
 */
export function saveMediaPost(mediaPost){

  return api.post(`${baseUrl}/media`, {...mediaPost});
}

/**
 *
 * @param {Number} page
 */
export function getMediaPosts(page){
  return api.get(`${baseUrl}/media`, {params:{
    page
  }});
}


/**
 *
 * @param {Number} id
 */
export function deleteMedia(id){
  return api.delete(`${baseUrl}/media/${id}`);
}

