// @ts-nocheck
import React, { useRef } from 'react';
import formatMoney from '../../util/formatMoney';
import format from 'date-fns/format';
import './investments-styles.css';
import Permission from '../../components/Permission';
import { Link } from 'react-router-dom'

/**
 *
 * @param {{ user:User, setUserList: Function, isChecked: boolean }}
 */


export function UserRow ({ user, setUserList, isChecked }) {

  // setUserList()
  const checkRef = useRef()

  
  const handleChange = () => {
    if (checkRef.current.checked) {
      setUserList((prev) => ({
        ...prev,
        [user.id]: {
          user_id: user.id,
          amount: user.wallet.referral_amount,
          reason: "Wallet withdrawal to bank account"
        }
      }))
    } else {
      setUserList((prev) => {
        const _prev = Object.assign({}, prev);
        if(_prev[user.id]) {
          delete _prev[user.id]
          return _prev;
        }
        return _prev;
      })
    }
  }

  return (
    <>
      <svg className="checkbox-symbol">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline
            points="1.5 6 4.5 9 10.5 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></polyline>
        </symbol>
      </svg>
      <div className="table-info-flex">
        <Permission roles={['super-admin']}>
        <div className="column-content">
          <div className="content-block mobile-header">
            <div></div>
          </div>
          <div>
            <div>
              <input type="checkbox" id={user.id} className="checkbox-input" ref={checkRef} onChange={handleChange} checked={isChecked} />
              <label className="checkbox" htmlFor={user.id}>
                <span>
                  <svg width="12px" height="10px">
                    <use xlinkHref="#check"></use>
                  </svg>
                </span>
              </label>
            </div>
          </div>
        </div>
        </Permission>
        <div className="column-content">
          <div className="content-block mobile-header">
            <div>Date</div>
          </div>
          <div>
            <div>{format(new Date(user.updated_at), 'd LLL yyyy')}</div>
          </div>
        </div>
        <div className="column-content" style={{ width: '20%' }}>
          <div className="content-block mobile-header">
            <div>name</div>
          </div>
          <div>
            <div>
              {user.fname} {user.lname}
            </div>
          </div>
        </div>
        <div className="column-content amount" style={{ width: '30%' }}>
          <div className="content-block mobile-header">
            <div>Email</div>
          </div>
          <div>
            <div>{user.email} </div>
          </div>
        </div>
        <div className="column-content amount">
          <div className="content-block mobile-header">
            <div>Referral Bonus</div>
          </div>
          <div>
            <div>{formatMoney(user.wallet.referral_amount, '₦')} </div>
          </div>
        </div>
        <div className="column-content amount">
          <div className="content-block mobile-header">
            <div>Action</div>
          </div>
          <div>
            <Link to={`transaction-history?user_id=${user.id}&type=referral`} target="_blank" className="action-button transaction-history">
              <div>View</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
