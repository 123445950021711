// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import React, { useEffect, useState } from 'react';
// @ts-ignore
// import { useDispatch } from 'react-redux';
// @ts-ignore
import { useParams, useHistory, Link } from 'react-router-dom';
// @ts-ignore
import formatMoney from '../../util/formatMoney';
import Loading from '../../components/Loading';
// @ts-ignore
import format from 'date-fns/format';

import useService from '../../util/useService';
import { bypassUserBVN, getUser, updateUserStatus, updateReportLink } from '../../services/userService';
// import { getBanks } from '../../services/bankService';
import { getUserTransactions } from '../../services/transactionService';
// @ts-ignore
import { getUserLogs } from '../../services/logService';
import Notify from '../../util/Notify';
import { getUserPools } from '../../services/poolService';
import clsx from 'clsx';
import Dialog from '../../util/Dialog';
import { getGrowthMembership } from '../../services/growthService';
import Permission from '../../components/Permission';

const imageBaseUrl = 'https://api.volitioncap.com/';

export function UserProfile() {
  const history = useHistory();
  // @ts-ignore
  /**
   * @type {{id:string}}
   */
  const params = useParams();

  const userId = Number(params.id);
  /**
   * @type {{call:(id:number)=>void,loading:boolean,data: User}}
   */
  const userCaller = useService(getUser);

  /**
   * @type {{call:(userId:number)=>any,loading:boolean;data: {transactions: Transaction[], total: number}}}
   */
  const userTransaction = useService(
    getUserTransactions /* , {
    cache: 'memory',
    name: `user-transactions/${userId}`,
  } */,
  );

  const logCaller = useService(getUserLogs);

  /**
   * @type {Log[]}
   */
  const logs = logCaller.data?.data ?? [];

  const user = userCaller.data;

  const getUserPoolsCall = useService(getUserPools);

  const getGrowthMembershipCall = useService(getGrowthMembership);

  console.log('user', user);
  /**
   * @type {Pool[]}
   */
  const pools = (getUserPoolsCall.data ?? []).filter((pool) => pool.subscriptions);

  const [reportLink, setReportLink] = useState('');

  useEffect(() => {
    // @ts-ignore
    userCaller.call(userId).onError((e) => Notify.error(e.message));
    // @ts-ignore
    userTransaction.call(userId).onError((e) => Notify.error(e.message));

    logCaller.call(1, userId).onError((e) => Notify.error(e.message));

    getUserPoolsCall.call(userId).onError((e) => Notify.error(e.message));

    getGrowthMembershipCall.call(userId).onError((e) => Notify.error(e.message));
  }, [userId]);

  const bypassBVNCaller = useService(bypassUserBVN);

  const toggleByPassBVN = async () => {
    if (!(await Dialog.confirm(`Are you sure you want to perform this operation?`))) {
      return;
    }

    bypassBVNCaller
      .call(userId)
      .onSuccess(() => {
        Notify.success('BVN bypass updated successfully!');
        userCaller.call(userId).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  const updateReportCaller = useService(updateReportLink);

  const updateRepLink = async () => {
    if (!(await Dialog.confirm(`Are you sure you want to perform this operation?`))) {
      return;
    }

    updateReportCaller
      .call(userId, reportLink)
      .onSuccess(() => {
        Notify.success('Report link updated successfully!');
        userCaller.call(userId).onError((e) => Notify.error(e.message));
        setReportLink('');
      })
      .onError((e) => Notify.error(e.message));
  };

  const archiveUser = async () => {
    if (!(await Dialog.confirm(`Are you sure you want to achieve this user?`))) {
      return;
    }
    try {
      await updateUserStatus(userId, 'inactive');
      Notify.success('User account achieved successfully!');
      history.goBack();
    } catch (e) {
      Notify.error(e.message);
    }
  };

  return (
    <>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">User Profile</h1>
          <div className="extended-heading-line custom-pools"></div>
          <Permission roles={['super-admin', 'admin']}>
            <div className="margin-left no-margin">
              <button className="button w-button" onClick={archiveUser}>
                Archive User
              </button>
            </div>
          </Permission>
        </div>
        {userCaller.loading ? (
          <>
            <Loading isLoading={true} />
          </>
        ) : null}
        {user ? (
          <>
            <div className="pool-section-inner-flex message">
              <div className="message-block">
                <div className="dashboard-content-wrapper messages">
                  <div className="dashboard-content-wrapper-top-border-wrapper">
                    <div className="smaller-border-line"></div>
                    <div className="dashboard-content-heading flex profile">
                      <div className="margin-right">
                        {user.fname} {user.lname}
                      </div>
                      {!!user?.kyc_document && (
                        <Permission roles={['super-admin', 'admin']}>
                          <a
                            href={`${imageBaseUrl}${user.kyc_document}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="button bypass-btn"
                          >
                            View kyc document
                          </a>
                        </Permission>
                      )}
                    </div>
                    <div className="extender-border"></div>
                  </div>
                  <div className="dashboard-content-wrapper-inner">
                    <div className="recent-transaction-card">
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>First Name</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.fname}</div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-title">
                            <div>Last Name</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.lname}</div>
                          </div>
                        </div>
                      </div>
                      <Permission roles={['super-admin', 'admin']}>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Phone</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.phone}</div>
                            </div>
                          </div>
                        </div>
                      </Permission>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Email</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.email}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Gender</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.gender}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Date of Birth</div>
                          </div>
                          <div className="profile-value">
                            <div>{format(new Date(user.dob), 'd LLL yyyy')}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Country</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.country}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>City</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.city}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Income Bracket</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.income_bracket}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Source of Income</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.income_source}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Heard About Volition</div>
                          </div>
                          <div className="profile-value">
                            <div style={{ textTransform: 'capitalize' }}>
                              {user.heard_about_us}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Referral Person</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.referral_person}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Permission roles={['super-admin', 'admin']}>
                  <div className="dashboard-content-wrapper messages bank-details">
                    <div className="dashboard-content-wrapper-top-border-wrapper">
                      <div className="smaller-border-line"></div>
                      <div className="dashboard-content-heading flex profile">
                        <div className="margin-right">Consent</div>
                      </div>

                      <div className="extender-border"></div>
                    </div>
                    <div className="dashboard-content-wrapper-inner">
                      <div className="recent-transaction-card">
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Family Office</div>
                            </div>
                            <div className="profile-value">
                              <div>{!!user?.accept_family_office ? 'Yes' : 'No'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Politically Exposed Person</div>
                            </div>
                            <div className="profile-value">
                              <div>{!!user?.pep ? 'Yes' : 'No'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Accredited Investor</div>
                            </div>
                            <div className="profile-value">
                              <div>{!!user?.accredited_investor ? 'Yes' : 'No'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Privacy Policy, Terms & Bylaws</div>
                            </div>
                            <div className="profile-value">
                              <div>{!!user?.terms_and_conditions ? 'Yes' : 'No'}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Permission>

                <div className="dashboard-content-wrapper messages bank-details">
                  <div className="dashboard-content-wrapper-top-border-wrapper">
                    <div className="smaller-border-line"></div>
                    <div className="dashboard-content-heading flex profile">
                      <div className="margin-right">Referrer Information</div>
                    </div>
                    <div className="extender-border"></div>
                  </div>
                  <div className="dashboard-content-wrapper-inner">
                    {user.refferer ? (
                      <div className="recent-transaction-card">
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Referrer Name</div>
                            </div>
                            <div className="profile-value">
                              <div>
                                {user.refferer.user.fname} {user.refferer.user.lname}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Referrer Email</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.refferer.user.email}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Referrer Code</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.refferer.code}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Account Name</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.refferer.user.acct_details?.account_name}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Referrer Bank Name</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.refferer.user.acct_details?.bank_name}</div>
                            </div>
                          </div>
                          <div>
                            <div className="profile-title">
                              <div>Account Number</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.refferer.user.acct_details?.account_number}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h2>This user has no referrer.</h2>
                    )}
                  </div>
                </div>
              </div>
              <div className="message-block last">
                <Permission roles={['super-admin', 'admin']}>
                  <div className="dashboard-content-wrapper messages">
                    <div className="dashboard-content-wrapper-top-border-wrapper">
                      <div className="smaller-border-line"></div>
                      <div className="dashboard-content-heading flex profile">
                        <div className="margin-right">Bank Information</div>
                        <Permission roles={['super-admin', 'admin']}>
                          <button className="button bypass-btn" onClick={toggleByPassBVN}>
                            {user.can_bypass_kyc ? 'Disable BVN Bypass' : 'Bypass BVN'}
                          </button>
                        </Permission>
                      </div>
                      <div className="extender-border"></div>
                    </div>
                    <div className="dashboard-content-wrapper-inner">
                      <div className="recent-transaction-card">
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Bank Name</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.acct_details?.bank_name}</div>
                            </div>
                          </div>
                          <div>
                            <div className="profile-title">
                              <div>Account Number</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.acct_details?.account_number}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>BVN</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.acct_details?.bvn}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Permission>

                <Permission roles={['super-admin', 'admin']}>
                  <div className="dashboard-content-wrapper messages bank-details">
                    <div className="dashboard-content-wrapper-top-border-wrapper">
                      <div className="smaller-border-line"></div>
                      <div className="dashboard-content-heading flex profile">
                        <div className="margin-right">USD or GBP Account</div>
                      </div>
                      <div className="extender-border"></div>
                    </div>
                    <div className="dashboard-content-wrapper-inner">
                      <div className="recent-transaction-card">
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Currency</div>
                            </div>
                            <div className="profile-value">
                              <div>
                                {user?.usd_accounts?.currency
                                  ? user?.usd_accounts?.currency?.toUpperCase()
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="profile-title">
                              <div>Account Number</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.account_number ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Account Name</div>
                            </div>
                            <div className="profile-value">
                              <div> {user?.usd_accounts?.account_name ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Bank</div>
                            </div>
                            <div className="profile-value">
                              <div> {user?.usd_accounts?.bank ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Bank Address</div>
                            </div>
                            <div className="profile-value">
                              <div> {user?.usd_accounts?.bank_address ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Customer Address</div>
                            </div>
                            <div className="profile-value">
                              <div> {user?.usd_accounts?.customer_address ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Swift Code</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.swift_code ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Routing Number/ABA</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.routing_number ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Correspondent Bank</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.correspondent_bank ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Correspondent Bank Account Number</div>
                            </div>
                            <div className="profile-value">
                              <div>
                                {user?.usd_accounts?.correspondent_bank_account_number ?? ''}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Correspondent Bank Swift Code</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.correspondent_bank_swift_code ?? ''}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Correspondent Bank Address</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.usd_accounts?.correspondent_bank_address ?? ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Permission>

                <Permission roles={['super-admin', 'admin']}>
                  <div className="dashboard-content-wrapper messages bank-details">
                    <div className="dashboard-content-wrapper-top-border-wrapper">
                      <div className="smaller-border-line"></div>
                      <div className="dashboard-content-heading flex profile">
                        <div className="margin-right">Next of Kin</div>
                      </div>
                      <div className="extender-border"></div>
                    </div>
                    <div className="dashboard-content-wrapper-inner">
                      <div className="recent-transaction-card">
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Full Name</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.nk_name}</div>
                            </div>
                          </div>
                          <div>
                            <div className="profile-title">
                              <div>Phone Number</div>
                            </div>
                            <div className="profile-value">
                              <div>{user?.nk_phone ?? '--'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Email</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.nk_email}</div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-flex">
                          <div>
                            <div className="profile-title">
                              <div>Address</div>
                            </div>
                            <div className="profile-value">
                              <div>{user.nk_address}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Permission>

                <div className="dashboard-content-wrapper messages bank-details">
                  <div className="dashboard-content-wrapper-top-border-wrapper">
                    <div className="smaller-border-line"></div>
                    <div className="dashboard-content-heading flex profile">
                      <div className="margin-right">Volition Growth</div>
                      <Permission roles={['super-admin']}>
                        <Link
                          to={`/users/${user.id}/update_growth_membership`}
                          className="button bypass-btn"
                        >
                          Update Growth Membership
                        </Link>
                      </Permission>
                    </div>

                    <div className="extender-border"></div>
                  </div>
                  <div className="dashboard-content-wrapper-inner">
                    <div className="recent-transaction-card">
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Subscription Status</div>
                          </div>
                          <div className="profile-value">
                            <div>
                              {getGrowthMembershipCall.data?.status === 'active'
                                ? 'Subscribed'
                                : 'Expired'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Subscription Expiry</div>
                          </div>
                          <div className="profile-value">
                            <div>
                              {getGrowthMembershipCall.data?.expiry_date
                                ? format(
                                    new Date(getGrowthMembershipCall.data?.expiry_date),
                                    'd LLL yyyy',
                                  )
                                : 'Nil'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboard-content-wrapper messages bank-details">
                  <div className="dashboard-content-wrapper-top-border-wrapper">
                    <div className="smaller-border-line"></div>
                    <div className="dashboard-content-heading flex profile">
                      <div className="margin-right">Report Link</div>
                      <Permission roles={['super-admin', 'admin']}>
                        <button className="button bypass-btn" onClick={updateRepLink}>
                          Update Report Link
                        </button>
                      </Permission>
                    </div>

                    <div className="extender-border"></div>
                  </div>
                  <div className="dashboard-content-wrapper-inner">
                    <div className="recent-transaction-card">
                      <div className="profile-flex">
                        <div>
                          <div className="profile-title">
                            <div>Report Link</div>
                          </div>
                          <div className="profile-value">
                            <div>{user.report_link ? user.report_link : 'No Report Link'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-flex">
                        <div className="full-width">
                          <div className="profile-title">
                            <div>Enter New Report Link</div>
                          </div>
                          <div className="profile-value">
                            <input
                              type="text"
                              className="profile-input"
                              value={reportLink}
                              style={{ border: 'none', outline: 'none' }}
                              onChange={(e) => setReportLink(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-heading-block margin-bottom">
              <h1 className="brown-text">Pools</h1>
              <div className="extended-heading-line custom-pools"></div>
              <div className="margin-left">
                <Permission roles={['super-admin']}>
                  <div className="filter-button-flex-parent">
                    <Link to={`/users/${user.id}/send_statement`} className="button w-button">
                      Send Statement
                    </Link>
                    <div className="dummy-block smaller"></div>
                    <Link to={`/users/${user.id}/add_subscription`} className="button w-button">
                      Add Subscription
                    </Link>
                    <div className="dummy-block smaller"></div>
                    <Link to={`/users/${user.id}/update_membership`} className="button w-button">
                      Update Memberships
                    </Link>
                    {/*
                  <div className="dummy-block smaller"></div>
                  <a href="#" className="button dark-brown w-button">
                    View More
                  </a> */}
                  </div>
                </Permission>
              </div>
            </div>
            <div className="pools-flex-block">
              <Loading isLoading={getUserPoolsCall.loading} />
              {pools.length === 0 ? (
                <div className="no-active-pool justify-content-center w-100">
                  <span>No available contribution pools</span>
                </div>
              ) : (
                ''
              )}
              {pools.map((pool) => {
                const matches = /(black|platinum|red|brown|black|green|privilege|custom)/i.exec(
                  pool.type,
                );
                const type = (matches?.[0] ?? '').toLowerCase();
                const color = clsx({
                  brown: type === 'privilege',
                  gray: type === 'platinum',
                  black: type === 'black',
                  green: type === 'green',
                  red: type === 'red',
                  purple: type === 'custom',
                });
                return (
                  <Link
                    // @ts-ignore
                    to={`/users/${userId}/pools/${pool.id}/details`}
                    className="pool-card user-profile"
                  >
                    <div className="pool-card-inner">
                      <div className={`pool-type-block ${color}-pool`}>
                        <div>{pool.name}</div>
                      </div>
                      <div className="pool-roi-and-duration-flex vertical-flex">
                        <div className="total-subscription-block">
                          <div>Total Subscription(s)</div>
                        </div>
                        <div className={`subscription-number ${color}-text`}>
                          <div>{pool.subscriptions}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`card-bottom-flex ${color}-pool`}>
                      <div>Membership:</div>
                      <div>
                        {pool.membership_expiry
                          ? format(new Date(pool.membership_expiry), 'd LLL yyyy')
                          : 'None'}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="pool-section">
              <div className="pool-section-inner-flex">
                <UserTransactions userId={userId} userTransaction={userTransaction} />
                <div className="dashboard-content-wrapper recent-transaction last h-500">
                  <div className="dashboard-content-wrapper-top-border-wrapper">
                    <div className="smaller-border-line"></div>
                    <div className="dashboard-content-heading flex">
                      <div className="margin-right">Recent Activity</div>
                      <Link
                        to={`/activity-logs?user_id=${userId}`}
                        className="view-all-button small brown w-button"
                      >
                        View All
                      </Link>
                    </div>
                    <div className="extender-border"></div>
                  </div>
                  <div className="dashboard-content-wrapper-inner">
                    <Loading isLoading={logCaller.loading && logs.length === 0} />
                    {logs.slice(0, 10).map((log) => (
                      <div className="recent-transaction-card">
                        <div className="pool-title-widget">
                          <div>{format(new Date(log.created_at), 'd LLL yyyy')}</div>
                        </div>
                        <div>
                          <div>{log.message}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
/**
 *
 * @param {{userId:number, userTransaction:{loading:boolean;data: {transactions: Transaction[], total: number}}}} param0
 */
function UserTransactions({ userTransaction, userId }) {
  const { loading, data } = userTransaction;
  const transactions = data?.data.slice(0, 5) ?? [];

  return (
    <div className="dashboard-content-wrapper recent-transaction">
      <div className="dashboard-content-wrapper-top-border-wrapper">
        <div className="smaller-border-line"></div>
        <div className="dashboard-content-heading flex">
          <div className="margin-right">Transaction History</div>
          <Link
            to={`/transaction-history?user_id=${userId}`}
            className="view-all-button small brown w-button"
          >
            View All
          </Link>
        </div>
        <div className="extender-border"></div>
      </div>
      <div className="dashboard-content-wrapper-inner">
        {loading ? <Loading isLoading={true} /> : null}
        {transactions.slice(0, 10).map((transaction) => (
          <div className="recent-transaction-card">
            <div className="pool-title-widget">
              <div>{format(new Date(transaction.created_at), 'd LLL yyyy')} </div>
              <div className="light-green-text">{formatMoney(transaction.amount, transaction.currency === 'NGN' ? '₦' : '$')}</div>
            </div>
            <div>
              <div>{transaction.desc}</div>
              <div>
                Status:{' '}
                {transaction.status === 'pending' ? (
                  <span className="yellow-text">{transaction.status}</span>
                ) : (
                  <span className="light-green-text">{transaction.status}</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
