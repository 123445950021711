import React from 'react';
import Button from '@material-ui/core/Button';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';



/**
 *
 * @param {Object} props
 */
export function FaqBodyEditor({ updateCmsPostForm, cmsPostForm }) {
  /**
   * @type {Faq[]}
   */
  const faqs = cmsPostForm.content.body;

  /**
   *
   * @param {number} index
   * @param {Faq} faq
   */
  const onFaqChange = (index, faq) => {
    const newFaqs = [...faqs];
    newFaqs[index] = faq;

    updateCmsPostForm('content', { body: newFaqs });
  };

  const addNewFaqs = () => {
    const newFaqs = [...faqs];

    const faq = {
      title: "",
      // description: "",
      questions: [
        {question: "", answer: "",}
      ],
    };

    if(cmsPostForm.slug === 'faq-dashboard') {
      faq.description = "";
    }

    newFaqs.push(faq);

    updateCmsPostForm('content', { body: newFaqs });
  }

  /**
   *
   * @param {number} index
   */
  const onDeleteFAQ = (index) => {
    const newFaqs = [...faqs];
    newFaqs.splice(index, 1);
    updateCmsPostForm('content', { body: newFaqs });
  }



  return (
    <>
      <br />
      {faqs.map((faq, index) => (
        <FaqItem slug={cmsPostForm.slug} faq={faq} onChange={(faq) => onFaqChange(index, faq)} onDelete={() => onDeleteFAQ(index)} />
      ))}
      <Button variant="contained" disableElevation onClick={addNewFaqs}>
        Add FAQ
      </Button>
      <br />
      <br />
    </>
  );
}

/**
 *
 * @param {Object} props
 * @param {Faq} props.faq
 * @param {string} props.slug
 * @param {Function} props.onChange
 * @param {Function} props.onDelete
 */
function FaqItem({ faq, onChange, onDelete, slug }) {
  const onInputChange = (name, value) => {
    onChange({
      ...faq,
      [name]: value,
    });
  };

  /**
   *
   * @param {number} index
   * @param {FaqQuestion} question
   */
  const onQuestionChange = (index, question) => {
    const questions = [...faq.questions];

    questions[index] = question;

    onInputChange('questions', questions);
  };

  const addNewQandA = () => {
    const questions = [...faq.questions];
    questions.push({
      question: "",
      answer: ""
    });
    onInputChange('questions', questions);
  };

  /**
   *
   * @param {number} index
   */
  const onDeleteQandA = (index) => {
    const questions = [...faq.questions];
    questions.splice(index, 1);
    onInputChange('questions', questions);
  }

  return (
    <div className="faq-bordered">
      <div className="text-right">
          <span className="close-button" onClick={onDelete}></span>
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Faq Title
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('title', e.target.value)}
          value={faq.title}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="Title"
          data-name="Title"
          required
        />
      </div>
      {slug === 'faq-dashboard' ? <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Faq Description
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('description', e.target.value)}
          value={faq.description}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="Title"
          data-name="Title"
          required
        />
      </div> : null}
      <br />
      {faq.questions.map((question, index) => (
        <QuestionAndAnswer
          onDelete={() => onDeleteQandA(index)}
          onChange={(question) => onQuestionChange(index, question)}
          question={question}
        />
      ))}
      <div className="text-right">
        <Button variant="contained" disableElevation style={{ color: '#a0830b' }} onClick={addNewQandA}>
          Add Q&amp;A
        </Button>
      </div>
    </div>
  );
}

/**
 *
 * @param {Object} props
 * @param {FaqQuestion} props.question
 * @param {Function} props.onChange
 * @param {Function} props.onDelete
 */
function QuestionAndAnswer({ question, onChange, onDelete }) {
  const onQAChange = (name, value) => {


    onChange({
      ...question,
      [name]: value,
    });
  };

  return (
    <div className="inner-faq-bordered">
      <div>
        <div className="text-right">
          <span className="close-button" onClick={onDelete}></span>
        </div>

      </div>

      <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Question
          <span className="gray-text" />
        </label>
        <input
          type="text"
          className="text-input-field portfolio-management w-input bg-beige"
          name="question"
          data-name="Title"
          value={question.question}
          required
          onChange={(e) => onQAChange(e.target.name, e.target.value)}
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Answer
          <span className="gray-text" />
        </label>

        <ReactQuill onChange={(html) => onQAChange('answer', html )}
            value={question.answer ?? ''} modules={modules} formats={formats} theme="snow" />

      </div>
    </div>
  );
}
