import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";


export  function getLogs(params){
  return api.get(`${baseUrl}/logs`, {params: params}).then((logs)=>{
    return logs;
  });
}

/**
 *
 * @param {number} page
 * @param {number} userId
 */
export  function getUserLogs(page, userId){
  return api.get(`${baseUrl}/logs/${userId}`, {params: {page}}).then((logs)=>{
    return logs;
  });
}

export function exportLogs(){
  return api.get(`${baseUrl}/logs/export`).then(function(response){

    download('activity-logs.csv', response);
    Notify.success("Activity logs data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}
