import {
    createStore,
    combineReducers,
    applyMiddleware
} from 'redux';
import {
    loadingBarReducer,
    loadingBarMiddleware
} from 'react-redux-loading-bar'
import thunk from 'redux-thunk';
import {
    composeWithDevTools,
} from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import { save, load } from "redux-localstorage-simple";
import poolReducer from './reducers/poolReducer';
import portfolioReducer from './reducers/portfolioReducer';
import transactionReducer from './reducers/transactionReducer';
import userReducer from './reducers/userReducer';

const rootReducer = combineReducers({
  pool: poolReducer,
  auth: authReducer,
  portfolio: portfolioReducer,
  loadingBar: loadingBarReducer,
  transaction: transactionReducer,
  user: userReducer
});



const createStoreWithMiddleware
    = composeWithDevTools(applyMiddleware(
      thunk,
      save({
        states: ['auth'],
        namespace: 'store'
      }), // Saving done here
      loadingBarMiddleware()
    ))(createStore);

const store = createStoreWithMiddleware(
  rootReducer,
  load({
    states: ['auth'],
    namespace: 'store'
  }) // Loading done here
);

export default store;
