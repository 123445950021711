import api from '../util/api';
// import Notify from '../util/Notify';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 *
 * @param {string[]} emails
 */
export function createReferrals(emails){
  return api.post(`${baseUrl}/referrals/generate-multiple`, {emails});
}


export function getReferrals(query = {}) {
  return api.get(`${baseUrl}/referrals`, {
    params: query
  });
}

