/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { getRate, updateRate } from '../../services/rateService';
import { useParams, useHistory } from 'react-router-dom';

export function RateEdit() {
  const param = useParams();
  const history = useHistory();
  const rateId = param.id;

  const [newRate, setNewRate] = useState(null);
  const [onePercent, setOnePercent] = useState(null);

  const updateRateCall = useService(updateRate);
  const getRateCall = useService(getRate);
  /**
   * @type {Rate}
   */
  const rate = getRateCall.data;



  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const editRate = (e) => {
    e.preventDefault();
    updateRateCall
      .call(rateId, newRate, onePercent)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Rate updated successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };
  useEffect(()=>{
    setNewRate(rate?.rate);
  }, [rate])
  useEffect(() => {
    getRateCall.call(rateId).onError((e) => Notify.error(e.message));
  }, [rateId]);

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Edit Rate</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="portolio-management-form-block">
        <div className="w-form">
          <form id="email-form" name="email-form" data-name="Email Form" onSubmit={editRate}>
            <div className="portfolio-management-form-flex">
              <div className="portfolio-managment-input-block first">
                <label htmlFor="Month" className="form-field-label small">
                  Month
                </label>
                <input
                  value={rate?.cycle?.month}
                  className="porfolio-management-select-input w-select"
                  required
                />
              </div>
              <div className="portfolio-managment-input-block">
                <label htmlFor="Year" className="form-field-label small">
                  Year
                </label>
                <input
                  value={rate?.cycle?.year}
                  className="porfolio-management-select-input w-select"
                  required
                />

              </div>
            </div>
            <div className="portfolio-management-form-flex">
              <div className="portfolio-managment-input-block first">
                <label htmlFor="Pool" className="form-field-label small">
                  Pool
                </label>
                <input
                  value={rate?.pool?.name}
                  className="porfolio-management-select-input w-select"
                  required
                />
              </div>
              <div className="portfolio-managment-input-block first">
                <label htmlFor="Change" className="form-field-label small">
                  Change <span className="gray-text">(%)</span>
                </label>
                <input
                  value={newRate}
                  onChange={(e)=>{
                    setNewRate(Number(e.target.value))
                  }}
                  type="number"
                  className="text-input-field portfolio-management w-input"
                  name="Change"
                  data-name="Change(%)"
                  id="Change"
                  required
                  step="any"
                />
              </div>
            </div>
            <div className="portfolio-management-form-flex">
              <div className="portfolio-managment-input-block first">
                <label htmlFor="Charge" className="form-field-label small">
                Charged percentage <span className="gray-text">(%)</span>
                </label>
                <select
                  value={onePercent}
                  onChange={(e)=>{
                    setOnePercent(Number(e.target.value))
                  }}
                  id="Charge"
                  name="Charge"
                  data-name="Charged percentage(%)"
                  required
                  className="porfolio-management-select-input w-select"
                >
                  <option value="" />
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <input
                type="submit"
                value={'Submit'}
                defaultValue="Update"
                className="button margin-bottom w-button"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
