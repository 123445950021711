import * as actionTypes from './actionTypes';
import api from '../../util/api';
import Notify from '../../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

export const fetchPool = () => {
    return async (dispatch) => {
        dispatch(fetchPoolStarted());
        try {
            const pools = await api.get(`${baseUrl}/pools`);
            dispatch(fetchPoolSuccess({
              pools
            }));

        } catch (error) {
          dispatch(fetchPoolError(error));
          Notify.error(error.message);
        }
    }
};


export const fetchPoolStarted = () => {
    return {
        type: actionTypes.FETCH_POOLS
    };
};

export const fetchPoolError = (error) => {
    return {
        payload: error,
        type: actionTypes.FETCH_POOLS_ERROR
    };
};

export const fetchPoolSuccess = (payload) => {
    return {
        payload,
        type: actionTypes.FETCH_POOLS_SUCCESS
    };
};
