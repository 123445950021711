import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * @param {BroadcastMessage} message
 */
export function sendBroadcastMessage(message){
  if (!message.user_id) {
    delete message.user_id;
  }
  return api.post(`${baseUrl}/messaging/broadcast`, {...message});
}

/**
 *
 * @param {Number} page
 */
export function getMessages(page){
  return api.get(`${baseUrl}/messaging`, {params:{
    page
  }});
}

/**
 *
 * @param {{[key:string]:string}} filter
 */
export function exportMessages(filter){
  return api.get(`${baseUrl}/messaging/export`,{
    params: {...filter}
  }).then(function(response){
    download('messages.csv', response);
    Notify.success("Messages data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}

