import React from 'react';
import formatMoney from '../util/formatMoney';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
/**
 *
 * @param {{pool:Pool}} props
 */
export function PoolCard({ pool }) {
  const matches = /(black|platinum|red|brown|black|green|privilege)/i.exec(pool.name);
  const name = (matches?.[0] ?? '').toLowerCase();
  const color = clsx({
    brown:  name === 'privilege' || name === 'platinum',
    black: name === 'black',
    green: name === 'green',
    red: name === 'red'
  });

  return (
    <div className="pool-card small right">
      <div className="pool-card-upper">
        <div className={`pool-title ${color}-text flex-direction-column`}>
          <div className={`${color}-text`}>{pool.name}</div>
          <div className="roi-flex">
            <div>
              <span className="gray-text">Duration:</span> {pool.duration} months
            </div>
          </div>
        </div>
        <div className="pool-amount small">
          <div>{formatMoney(pool.total_capital, pool.currency === 'naira' ? '₦' : '$')}</div>
        </div>
        <div className="pool-roi-and-duration-flex"></div>
      </div>
      <div className="total-subscription-flex-wrapper">
        <div>
          <div className="total-contributions">
            <div className="gray-text">Subscriptions</div>
          </div>
          <div className="contribution-number">
            <div className={`${color}-text`}>{pool.subscriptions}</div>
          </div>
        </div>
        <div>
          <Link
            to={`pools/${pool.id}/details`}
            className={`view-all-button small ${color} w-button`}
          >
            View All
          </Link>
        </div>
      </div>
    </div>
  );
}
