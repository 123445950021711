import * as actionTypes from './actionTypes';
import api from '../../util/api';
import Notify from '../../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

export const login = (email, password) => {
    return async (dispatch) => {
        dispatch(loginStarted());
        try {
            /**
             * @type {User}
             */
            const user = await api.post(`${baseUrl}/auth/login`, {
                email,
                password
            });
            if(user.role === 'user') {
              throw new Error("You must be an admin to login");
            }
            dispatch(loginSuccess({
              user
            }));

        } catch (error) {
            dispatch(loginError(error));
            if (error.statusCode === 401) {
                Notify.error("Username or password incorrect!")
            }
            else{
                Notify.error(error.message);
            }
        }
    }
};


export const loginStarted = () => {
    return {
        type: actionTypes.LOGIN
    };
};

export const loginInit = () => {
    return {
        type: actionTypes.LOGIN_INIT
    };
};

export const loginError = (error) => {
    return {
        payload: error,
        type: actionTypes.LOGIN_ERROR
    };
};

export const loginSuccess = (payload) => {
    return {
        payload,
        type: actionTypes.LOGIN_SUCCESS
    };
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  return {
      type: actionTypes.LOGOUT
  };
};
