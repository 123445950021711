import * as actionTypes from './actionTypes';
import api from '../../util/api';
import Notify from '../../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";
/**
 *
 * @param {number} page
 */
export const fetchPortfolio = (page) => {
  return async (dispatch) => {
    dispatch(fetchPortfolioStarted());
    try {
      const {
        portfolios,
        total
      } = await api.get(`${baseUrl}/portfolio/list`, {
        params: {
          page
        }
      });
      dispatch(fetchPortfolioSuccess({
        portfolios,
        total
      }));

    } catch (error) {
      dispatch(fetchPortfolioError(error));
      Notify.error(error.message);
    }
  }
};

/**
 * @param {number} pool
 * @param {number} page
 */
export const fetchPortfolioByPool = (params) => {
  return async (dispatch) => {
    dispatch(fetchPortfolioStarted());
    try {
      const {
        portfolios,
        total
      } = await api.get(`${baseUrl}/portfolio/list`, {
        params
      });
      dispatch(fetchPortfolioSuccess({
        portfolios,
        total
      }));

    } catch (error) {
      dispatch(fetchPortfolioError(error));
      Notify.error(error.message);
    }
  }
};


export const fetchPortfolioStarted = () => {
  return {
    type: actionTypes.FETCH_PORTFOLIOS
  };
};

export const fetchPortfolioError = (error) => {
  return {
    payload: error,
    type: actionTypes.FETCH_PORTFOLIOS_ERROR
  };
};

export const fetchPortfolioSuccess = (payload) => {
  return {
    payload,
    type: actionTypes.FETCH_PORTFOLIOS_SUCCESS
  };
};
