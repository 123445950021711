import React from 'react';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';

export const GettingStartedEditor = ({ updateCmsPostForm, cmsPostForm }) => {
  const updateGettingStarted = (key, value) => {
    const newCmsPostForm = { ...cmsPostForm.content.body };
    newCmsPostForm[key] = value;

    updateCmsPostForm('content', { body: newCmsPostForm });
  };

  return (
    <div>
      <br />
      <br />
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Text
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('about_text', html)}
            value={cmsPostForm.content.body.about_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Directors Text
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('director_text', html)}
            value={cmsPostForm.content.body.director_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Our Contribution Structure Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('contribution_structure', html)}
            value={cmsPostForm.content.body.contribution_structure ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Green Pool Description
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('green_pool_description', html)}
            value={cmsPostForm.content.body.green_pool_description ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Green Pool Foot Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('green_pool_foot_text', html)}
            value={cmsPostForm.content.body.green_pool_foot_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Green 12 months ROI text
            <span className="gray-text" />
          </label>
          <input
            value={cmsPostForm.content.body.green_12_months_roi_text ?? ''}
            onChange={(e) => updateGettingStarted('green_12_months_roi_text', e.target.value)}
            type="text"
            className="text-input-field portfolio-management w-input"
            required
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Black Pool Description
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('black_pool_description', html)}
            value={cmsPostForm.content.body.black_pool_description ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Black Pool Foot Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('black_pool_foot_text', html)}
            value={cmsPostForm.content.body.black_pool_foot_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Black 12 months ROI text
            <span className="gray-text" />
          </label>
          <input
            value={cmsPostForm.content.body.black_12_months_roi_text ?? ''}
            onChange={(e) => updateGettingStarted('black_12_months_roi_text', e.target.value)}
            type="text"
            className="text-input-field portfolio-management w-input"
            required
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Red Pool Description
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('red_pool_description', html)}
            value={cmsPostForm.content.body.red_pool_description ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Red Pool Foot Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('red_pool_foot_text', html)}
            value={cmsPostForm.content.body.red_pool_foot_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Privilege Pool Description
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('privilege_pool_description', html)}
            value={cmsPostForm.content.body.privilege_pool_description ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Privilege Pool Left Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('privilege_pool_left_text', html)}
            value={cmsPostForm.content.body.privilege_pool_left_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Platinum Pool Description
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('platinum_pool_description', html)}
            value={cmsPostForm.content.body.platinum_pool_description ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Platinum Pool Left Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('platinum_pool_left_text', html)}
            value={cmsPostForm.content.body.platinum_pool_left_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <br />
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Volition Extra Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('volition_extra_text', html)}
            value={cmsPostForm.content.body.volition_extra_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Volition Growth Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('volition_growth_text', html)}
            value={cmsPostForm.content.body.volition_growth_text ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <br />
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 1 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_1', html)}
            value={cmsPostForm.content.body.how_to_subscribe_1 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 2 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_2', html)}
            value={cmsPostForm.content.body.how_to_subscribe_2 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 3 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_3', html)}
            value={cmsPostForm.content.body.how_to_subscribe_3 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 4 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_4', html)}
            value={cmsPostForm.content.body.how_to_subscribe_4 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 5 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_5', html)}
            value={cmsPostForm.content.body.how_to_subscribe_5 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to Subscribe 6 Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_subscribe_6', html)}
            value={cmsPostForm.content.body.how_to_subscribe_6 ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <br />

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Membership & Withdrawal fees Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('membership_withdrawal_fees', html)}
            value={cmsPostForm.content.body.membership_withdrawal_fees ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to pay Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_pay', html)}
            value={cmsPostForm.content.body.how_to_pay ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Remittances Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('remittances', html)}
            value={cmsPostForm.content.body.remittances ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            How to refer someone Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('how_to_refer_someone', html)}
            value={cmsPostForm.content.body.how_to_refer_someone ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Contact Information Text
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('contact_information', html)}
            value={cmsPostForm.content.body.contact_information ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
          Why We Are Different
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('why_we_are_different', html)}
            value={cmsPostForm.content.body.why_we_are_different ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
          Investment Pools and Features
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('investment_pools_and_features', html)}
            value={cmsPostForm.content.body.investment_pools_and_features ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
          FAQs
            <span className="gray-text" />
          </label>

          <ReactQuill
            onChange={(html) => updateGettingStarted('faqs', html)}
            value={cmsPostForm.content.body.faqs ?? ''}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </div>
      </div>
    </div>
  );
};
