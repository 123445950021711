// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  createTransaction,
} from '../../services/transactionService';
import { fetchPool } from '../../store/actions/poolAction';
import AutoCompleteUser from '../ContributionPool/AutoCompleteUser';

export function CreateTransaction() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [transactionType, setTransactionType] = useState('');

  const [currentPool, setCurrentPool ] = useState(null);

  const [referrer, setReferrer] = useState(null);

  const [referred, setReferred] = useState(null);

  const amountRef = useRef(null);

  const descriptionRef = useRef(null);

  const poolInputRef = useRef(null);

  const { pools, loading: poolLoading } = useSelector((state) => state.pool);

  const createTransactionCall = useService(createTransaction);

  useEffect(() => {
    dispatch(fetchPool());
  }, [dispatch]);

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const submitCreateTransaction = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);

    const pool = data.get('pool');
    const amount = data.get('amount');
    const description = data.get('description');

    const transaction = {
      type: transactionType,
      pool_id: pool,
      amount,
      description,
      referrer: referrer.id,
      referred: referred.id,
    };

    console.log(transaction)


    createTransactionCall
      .call(transaction)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Transaction created successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Create Transaction</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Transaction Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form
                  onSubmit={submitCreateTransaction}
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                >
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Select Transaction Type
                      </label>
                      <select
                        id="transaction_type"
                        name="transaction_type"
                        data-name="transaction_type"
                        required
                        className="porfolio-management-select-input w-select"
                        value={transactionType}
                        onChange={(e) => {
                          const type = e.target.value;
                          setTransactionType(type);
                        }}
                      >
                        <option selected disabled value=""></option>

                        <option value={'referral'}>Referral Transaction</option>
                      </select>
                    </div>
                    <div className="portfolio-managment-input-block edit-information">
                      <AutoCompleteUser
                        label="Select Referrer"
                        handleSelectedUser={(user) => {
                          setReferrer(user)
                        }}
                      />
                      <br />
                    </div>

                    <div className="portfolio-managment-input-block edit-information">
                      <AutoCompleteUser
                        label="Select Referred"
                        handleSelectedUser={(user) => {
                          setReferred(user);

                          const name = user?.fname + ' ' + user?.fname;

                          descriptionRef.current.value = `Referral bonus for ${name}'s ${currentPool?.name || "Pool"} membership fee payment`
                        }}
                      />
                      <br />
                    </div>

                    {['referral'].includes(transactionType) ? (
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="pool" className="form-field-label small">
                          Select Investment Pool
                        </label>
                        <select
                          id="pool"
                          name="pool"
                          data-name="pool"
                          required
                          className="porfolio-management-select-input w-select"
                          ref={poolInputRef}
                          onChange={(event) => {
                            const poolId = event.target.value;
                            const pool = pools.find((pool) => pool.id === Number(poolId));
                            setCurrentPool(pool)
                            if (transactionType === 'referral') {
                              amountRef.current.value = pool.fee * 0.2;

                              const name = referred?.fname + ' ' + referred?.lname;

                              descriptionRef.current.value = `Referral bonus for ${name}'s ${pool.name} membership fee payment`
                            }
                          }}
                        >
                          <option value="" disabled selected key="default">
                            {poolLoading ? 'Loading...' : ''}
                          </option>
                          {pools
                            .filter((pool) => transactionType === 'referral' && pool.id < 12)
                            .map((pool) => (
                              <option key={pool.id} value={pool.id}>
                                {pool.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    ) : null}
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Amount
                      </label>
                      <input
                        ref={amountRef}
                        // min={selectedPool?.min_amount ?? 0}
                        type="number"
                        id="amount"
                        name="amount"
                        data-name="amount"
                        required
                        className="porfolio-management-select-input w-input"
                      />
                    </div>
                  </div>
                  <div className="portfolio-managment-input-block edit-information">
                    <label htmlFor="pool" className="form-field-label small">
                      Description
                    </label>
                    <textarea
                      name="description"
                      ref={descriptionRef}
                      className="porfolio-management-select-input w-input"
                    ></textarea>
                  </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={createTransactionCall.loading ? 'Please wait...' : 'Create'}
                      className={clsx('button w-button', {
                        'btn-disabled': createTransactionCall.loading || (transactionType === 'referral' && !(referrer && referred) ),
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
