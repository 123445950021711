/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Loading from '../../components/Loading';
import format from 'date-fns/format';
import { Paginator } from '../../components/Paginator';
import { getLogs } from '../../services/logService';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useLocation } from 'react-router-dom';
import getQueryParams from '../../util/getQueryParams';

export function ActivityLog() {

  const location = useLocation();
  const query = useMemo(() => getQueryParams(location.search), [location.search]);

  const logCaller = useService(getLogs);

  /**
   * @type {Log[]}
   */
  const logs = logCaller.data?.data ?? [];
  const total = logCaller.data?.total ?? 0;
  const [page, setPage] = useState(1);
  const [search] = useState('');

  useEffect(() => {
    logCaller.call({page, causer_id: query.user_id, subject_id: query.subject_id}).onError((e) => Notify.error(e.message))
  }, [page, search]);

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Activity Log </h1>
          <div className="extended-heading-line custom-pools" />
          <div className="filter-button-flex-parent">
            {/* <div className="w-embed">
              <select className="select-input custom">
                <option value>Filter</option>
              </select>
            </div>
            <div className="dummy-block smaller" />
            <a href="#" className="download-csv w-inline-block">
              <div>CSV</div>
            </a> */}
          </div>
        </div>
        <div className="margin-top">
          <div className="investment-breakdown-header activity-log">
            <div className="content-block transaction-history">
              <div>Date</div>
            </div>
            <div className="content-block transaction-history">
              <div>Time</div>
            </div>
            <div className="content-block transaction-history-decription">
              <div>Description</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={logCaller.loading && logs.length === 0} />
            {logs.map((log) => (
              <div className="table-info-flex activity-log">
                <div className="column-content transction-history">
                  <div className="content-block mobile-header">
                    <div>Date</div>
                  </div>
                  <div>
                    <div>{format(new Date(log.created_at), 'd LLL yyyy')}</div>
                  </div>
                </div>
                <div className="column-content transction-history">
                  <div className="content-block mobile-header">
                    <div>Time</div>
                  </div>
                  <div>
                    <div>{format(new Date(log.created_at), 'hh:mm a')}</div>
                  </div>
                </div>
                <div className="column-content activity-log">
                  <div className="content-block mobile-header">
                    <div>DESCRIPTION</div>
                  </div>
                  <div>
                    <div>
                      {log.message}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}
