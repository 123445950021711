import * as actionTypes from '../actions/actionTypes';

/**
 * @type {PoolState}
 */
const initialState = {
  pools:[],
  loading: false,
  error: null,
}

const poolReducer = (state = initialState, action) => {
  const stateClone = {
    ...state
  };
  switch (action.type) {
    case actionTypes.FETCH_POOLS: {
      stateClone.loading = true;
      break;
    }
    case actionTypes.FETCH_POOLS_SUCCESS: {
      stateClone.loading = false;
      stateClone.pools = action.payload.pools;
      break;
    }

    case actionTypes.FETCH_POOLS_ERROR: {
      stateClone.loading = false;
      stateClone.error = action.payload;
      break;
    }

    case actionTypes.LOGOUT: {
      return initialState;
    }

    default:
      ;
  }
  return stateClone;
};

export default poolReducer;
