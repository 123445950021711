import api from '../util/api';
// import Notify from '../util/Notify';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * @param {VolitionGrowth} growthPost
 */
export function saveGrowth(growthPost){

  return api.post(`${baseUrl}/growth`, {...growthPost});
}

/**
 * @param {number} growthPostId
 * @param {VolitionGrowth} growthPost
 */
export function updateGrowth(growthPostId, growthPost){

  return api.put(`${baseUrl}/growth/update`, { ...growthPost, id: growthPostId});
}

/**
 *
 * @param {Number} page
 */
export function getGrowths(page){
  return api.get(`${baseUrl}/growth/all`, {params:{
    page
  }});
}

/**
 *
 * @param {Number} id
 * @param {string} status
 */
export function toggleGrowthStatus(id, status){
  return api.put(`${baseUrl}/growth/toggle`, {
    growth_id: id,
    status
  });
}

/**
 *
 * @param {Number} id
 */
export function getGrowthById(id){
  return api.get(`${baseUrl}/growth/${id}`);
}
/**
 *
 * @param {Number} id
 */
export function getGrowthTransactionsById(id){
  return api.get(`${baseUrl}/growth/${id}/transactions`);
}

/**
 *
 * @param {Number} userId
 */
export function getGrowthMembership(userId){
  return api.get(`${baseUrl}/growth/memberships/${userId}`);
}

export function updateGrowthMembership(param){

  return api.post(`${baseUrl}/growth/memberships`, param);
}

/**
 *
 * @param {Number} id
 */
export function deleteGrowthById(id){
  return api.delete(`${baseUrl}/growth/${id}`);
}
