import React from 'react';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';


export const OurPeopleEditor = ({ updateCmsPostForm, cmsPostForm }) => {
  const updateGettingStarted = (key, value) => {
    const newCmsPostForm = { ...cmsPostForm.content.body };
    newCmsPostForm[key] = value;

    updateCmsPostForm('content', { body: newCmsPostForm });
  };

  return (
    <div>
      <br />
      <br />
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Kola Oyeneyin
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('about_kola', html)}
            value={cmsPostForm.content.body.about_kola ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Subomi Plumptre
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('about_subomi', html)}
            value={cmsPostForm.content.body.about_subomi ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Lilian Olubi
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('about_lilian', html)}
            value={cmsPostForm.content.body.about_lilian ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            About Zeal Akaraiwe
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('about_zeal', html)}
            value={cmsPostForm.content.body.about_zeal ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>
    </div>
  );
};
