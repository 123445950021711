/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { Link } from 'react-router-dom';
import { saveExtra, getBlues, deleteBlueById } from '../../services/blueService';
import format from 'date-fns/format';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '../../util/Dialog';
import Permission from '../../components/Permission'


// import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";

// import SlimSelect from 'slim-select';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

const defaultBlueFormState = {
  title: '',
  body: '',
  accept_message: 1
};
export function VolitionBlue() {
  const dispatch = useDispatch();
  const sendBlueCall = useService(saveExtra);

  const [page, setPage] = useState(1);
  const getBlueCall = useService(getBlues);
  const deleteBlueCall = useService(deleteBlueById);

  /**
   * @type {Blue[]}
   */
  const posts = getBlueCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getBlueCall?.data?.total ?? 0;

  useEffect(() => {

  }, [dispatch]);

  useEffect(() => {
    getBlueCall.call(page).onError((e) => Notify.error(e.message));
  }, [page]);

 
  const [newBlue, setBlueData] = useState(defaultBlueFormState);

  /**
   *
   * @param {'title'|'body'} inputName
   * @param {string} inputData
   */
  const updateBlueData = (inputName, inputData) => {
    setBlueData({
      ...newBlue,
      [inputName]: inputData,
    });
  };

  const sendBlue = (e) => {
    e.preventDefault();

    sendBlueCall
      .call(newBlue)
      .onSuccess(() => {
        Notify.success('Volition Extra post created');
        setBlueData(defaultBlueFormState);

        getBlueCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {number} blueId
   */
  const deleteBlue = async (blueId) => {
    if(!await Dialog.confirm("Are you sure you want to delete this post?")){
      return;
    }

    deleteBlueCall
      .call(blueId)
      .onSuccess(() => {
        Notify.success('Extra post deleted successfully');
        getBlueCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };
  return <>
    <div className="pool-section">
      <Permission roles={['super-admin', 'admin']}>
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Volition Extra</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="portolio-management-form-block">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" onSubmit={sendBlue}>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Subject" className="form-field-label">
                    Title
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newBlue.title}
                    onChange={(e) => updateBlueData('title', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Title"
                    data-name="Title"
                    id="Title"
                    required
                  />
                </div>
              </div>
              <div className="portfolio-management-form-flex space-between">
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Message" className="form-field-label">
                    Body
                    <span className="gray-text" />
                  </label>
                  <CKEditor
                    config={ {
                      ckfinder: {
                        uploadUrl: baseUrl + '/image/upload'
                    }
                      // plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
                      // toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                  } }
                    editor={ ClassicEditor }
                    data={newBlue.body}
                    onChange={ ( event, editor ) => {
                      updateBlueData('body', editor.getData())
                    } }
                  />
                </div>
              </div>
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Create"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
      </Permission>

      <div>
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">All Volition Extra</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="investment-breakdown-header all-messages">
          <div className="content-block all-messages">
            <div>Date</div>
          </div>
          <div className="content-block all-message-name">
            <div>Title</div>
          </div>
          <div className="content-block all-blue-name">
            <div>Body</div>
          </div>
          <Permission roles={['super-admin', 'admin']}>
            <div className="content-block all-messages">
              <div>Actions</div>
            </div>
          </Permission>
        </div>
        <div className="investment-breakdown-block">
          <Loading isLoading={getBlueCall.loading && posts.length===0} />
          {posts.map((post, index) => (
            <div className="table-info-flex all-mesages" key={index}>
              <div className="column-content all-messages">
                <div className="content-block mobile-header">
                  <div>Date</div>
                </div>
                <div>
                  <div>{format(new Date(post.created_at), 'd LLL yyyy')}</div>
                </div>
              </div>
              <div className="column-content all-messages-name">
                <div className="content-block mobile-header">
                  <div>title</div>
                </div>
                <div>
                  <div>{post.title} </div>
                </div>
              </div>
              <div className="column-content all-blue-name">
                <div className="content-block mobile-header">
                  <div>Body</div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: post.body}} className='html-preview-body-content'>
                  {/* <Truncate
                    lines={2}
                    dangerouslySetInnerHTML={{ __html: post.body}}
                  /> */}
                </div>
              </div>
              <Permission roles={['super-admin', 'admin']}>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>Action</div>
                  </div>
                  <div className="action-flex auto left">
                    <div className="content-block mobile-header"></div>
                    <Link to={`/volition-extra/${post.id}`} className="action-button transaction-history">
                      <div>View</div>
                    </Link>
                    <Link to={`/volition-extra/${post.id}/edit`}  className="action-button transaction-history">
                      <div>Edit</div>
                    </Link>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        deleteBlue(post.id);
                      }}
                      disabled={deleteBlueCall.loading}
                      className={`action-button transaction-history delete`}
                      >
                      <div>Delete</div>
                    </button>
                  </div>
                </div>
              </Permission>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Paginator setPage={setPage} total={total} page={page} />
  </>;
}
