/* eslint-disable no-useless-escape */
// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import format from 'date-fns/format';
import { saveMediaPost, getMediaPosts, deleteMedia } from '../../services/mediaService';
import Dialog from '../../util/Dialog';
import Permission from '../../components/Permission'




// import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";

// import SlimSelect from 'slim-select';

const defaultMediaPostState = {
  media: '',
  description: '',
  media_type: '',
};

export function Media() {
  const saveMediaCall = useService(saveMediaPost);
  const deleteMediaCall = useService(deleteMedia);

  const [page, setPage] = useState(1);
  const getMediaPostCall = useService(getMediaPosts);

  /**
   * @type {MediaPost[]}
   */
  const posts = getMediaPostCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getMediaPostCall?.data?.total ?? 0;

  useEffect(() => {
    getMediaPostCall.call(page).onError((e) => Notify.error(e.message));
  }, [page]);

  /**
   * @type {[MediaPost, Function]}
   */

  const [mediaPost, setMediaPost] = useState(defaultMediaPostState);

  /**
   *
   * @param {'media'|'description'|'media_type'|'order'} inputName
   * @param {string} inputData
   */
  const updateMediaPost = (inputName, inputData) => {
    if(inputName === 'media' && /<iframe/i.test(inputData)){
      // eslint-disable-next-line no-undef
      inputData = $(inputData).attr('src');
    }
    else if (/youtube.com/.test(inputData)){
      const mediaId = getParameterByName('v', inputData);
      inputData = `https://www.youtube.com/embed/${mediaId}`;
    }
    else if (/youtu.be/.test(inputData)){
      const mediaId = inputData.split('.be/')[1]
      inputData = `https://www.youtube.com/embed/${mediaId}`;
    }

    setMediaPost({
      ...mediaPost,
      [inputName]: inputData,
    });
  };

  const createMediaPost = (e) => {
    e.preventDefault();
    saveMediaCall
      .call(mediaPost)
      .onSuccess(() => {
        Notify.success('Media post created successfully');
        setMediaPost(defaultMediaPostState);

        getMediaPostCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };
  const deleteMediaPost = async (id) => {
    if(!await Dialog.confirm("Are you sure you want to delete this media? ")){
      return;
    }
    deleteMediaCall
      .call(id)
      .onSuccess(() => {
        Notify.success('Resource deleted successfully');
      })
      .onError((e) => Notify.error(e.message))
      .onDone(()=>{
        getMediaPostCall.call(page).onError((e) => Notify.error(e.message));
      });
  };

  return (
    <>
      <div className="pool-section">
        <Permission roles={['super-admin', 'admin']}>
          <div className="section-heading-block margin-bottom">
            <h1 className="brown-text">Resources</h1>
            <div className="extended-heading-line custom-pools" />
          </div>
          <div className="portolio-management-form-block">
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                onSubmit={createMediaPost}
              >
                <div>
                  <div className="portfolio-managment-input-block full">
                    <label htmlFor="Subject" className="form-field-label">
                      Resource Url
                      <span className="gray-text" />
                    </label>
                    <input
                      value={mediaPost.media}
                      onChange={(e) => updateMediaPost('media', e.target.value)}
                      type="url"
                      className="text-input-field portfolio-management w-input"
                      name="media"
                      data-name="Resource Url"
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="portfolio-managment-input-block full">
                    <label htmlFor="Subject" className="form-field-label">
                      Resource Type
                      <span className="gray-text" />
                    </label>
                    <select
                      value={mediaPost.media_type}
                      onChange={(e) => updateMediaPost('media_type', e.target.value)}
                      className="text-input-field portfolio-management w-select"
                      required
                    >
                      <option value="" selected disabled></option>
                      <option value="audio">Audio</option>
                      <option value="video">Video</option>
                      <option value="document">Document</option>
                    </select>
                    {/* <input
                      value={newBlue.title}
                      onChange={(e) => updateBlueData('title', e.target.value)}
                      type="text"
                      className="text-input-field portfolio-management w-input"
                      name="Title"
                      data-name="Title"
                      id="Title"
                      required
                    /> */}
                  </div>
                </div>
                <div className="portfolio-management-form-flex space-between">
                  <div className="portfolio-managment-input-block full">
                    <label htmlFor="Message" className="form-field-label">
                      Description
                      <span className="gray-text" />
                    </label>
                    <textarea
                      maxLength={300}
                      value={mediaPost.description}
                      onChange={(e) => updateMediaPost('description', e.target.value)}
                      className="text-input-field w-input"
                    />
                  </div>
                </div>
                <div className="submit-button-flex">
                  <input
                    type="submit"
                    defaultValue="Create"
                    data-wait="Please wait..."
                    className="send-message-button w-button"
                  />
                </div>
              </form>
            </div>
          </div>
        </Permission>
        <div>
          <div className="section-heading-block margin-bottom">
            <h1 className="brown-text">All Resources</h1>
            <div className="extended-heading-line custom-pools" />
          </div>
          <div className="investment-breakdown-header all-messages">
            <div className="content-block all-messages">
              <div>Date</div>
            </div>
            <div className="content-block all-message-name">
              <div>Resource Type</div>
            </div>
            <div className="content-block all-blue-name">
              <div>Resource Description</div>
            </div>
            <div className="content-block all-message-name">
              <div>Actions</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={getMediaPostCall.loading && posts.length === 0} />
            {posts.length === 0 && !getMediaPostCall.loading ? (
              <div className="no-active-pool">
                <div>No Resources to display</div>
              </div>
            ) : null}
            {posts.map((post, index) => (
              <div className="table-info-flex all-mesages" key={index}>
                <div className="column-content all-messages">
                  <div className="content-block mobile-header">
                    <div>Date</div>
                  </div>
                  <div>
                    <div>{format(new Date(post.created_at), 'd LLL yyyy')}</div>
                  </div>
                </div>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>Resource Type</div>
                  </div>
                  <div>
                    <div>{post.media_type} </div>
                  </div>
                </div>
                <div className="column-content all-blue-name">
                  <div className="content-block mobile-header">
                    <div>Resource Description</div>
                  </div>
                  <div>
                    <div>{post.description} </div>
                  </div>
                </div>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>Action</div>
                  </div>
                  <div className="action-flex auto left">
                    <div className="content-block mobile-header"></div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={post.media_url}
                      className="action-button transaction-history"
                    >
                      <div>Preview</div>
                    </a>
                    <Permission roles={['super-admin', 'admin']}>
                      <button
                        onClick={(e)=>{
                          e.preventDefault();
                          deleteMediaPost(post.id);
                        }}
                        className="action-button transaction-history delete"
                      >
                        <div>Delete</div>
                      </button>
                    </Permission>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
