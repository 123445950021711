
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGOUT = 'LOGOUT';

export const FETCH_POOLS = 'FETCH_POOLS';
export const FETCH_POOLS_SUCCESS = 'FETCH_POOLS_SUCCESS';
export const FETCH_POOLS_ERROR = 'FETCH_POOLS_ERROR';

export const FETCH_PORTFOLIOS = 'FETCH_PORTFOLIOS';
export const FETCH_PORTFOLIOS_SUCCESS = 'FETCH_PORTFOLIOS_SUCCESS';
export const FETCH_PORTFOLIOS_ERROR = 'FETCH_PORTFOLIOS_ERROR';

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';

export const FETCH_ACTIVITY_LOGS = 'FETCH_ACTIVITY_LOGS';
export const FETCH_ACTIVITY_LOGS_SUCCESS = 'FETCH_ACTIVITY_LOGS_SUCCESS';
export const FETCH_ACTIVITY_LOGS_ERROR = 'FETCH_ACTIVITY_LOGS_ERROR';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
