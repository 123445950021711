/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { appConstant } from '../../util/constant';
import api from '../../util/api';
import Loading from '../../components/Loading';
import { extractDayAndMonth } from '../../util/helpers';

const imageBaseUrl = 'https://api.volitioncap.com/';

const SuggestedDeals = () => {
  const dealsList = useState([]);
  const isFetching = useState(true);
  const searchValue = useState('');
  const deferred = useDebounce(searchValue[0]);

  const hasNodata = !isFetching[0] && dealsList[0].length === 0;
  const hasData = !isFetching[0] && dealsList[0].length > 0;

  const handleSearch = (e) => {
    searchValue[1](e.target.value);
  };

  const fetchSuggestedDeals = (search) => {
    const queryParam = !!search ? `?keyword=${search}` : '';
    isFetching[1](true);
    api
      .get(`${appConstant.baseUrl}/community/deals${queryParam}`)
      .then((response) => {
        dealsList[1](response?.data ?? []);
      })
      .catch(() => {})
      .finally(() => {
        isFetching[1](false);
      });
  };

  useEffect(() => {
    fetchSuggestedDeals(deferred);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred]);

  return (
    <section className="cmnt_deal_room_inner padding">
      <h5 className="cmnt_deal_room_text heading">Suggested Deals</h5>
      <section className="cmnt_member_filter_block suggested_deal">
        {/* <div className="cmnt_member_item_flex">
          <p className="cmnt_member_textStyle gray">Sort by</p>
          <div className="cmnt_member_item_flex cmnt_member_item_margin">
            <p className="cmnt_member_textStyle">Category</p>
            <img
              src="/assets/images/funnel-icon.svg"
              className="cmnt_member_item_image cmnt_member_item_margin"
              alt=""
            />
          </div>
          <div className="cmnt_member_item_flex cmnt_member_item_margin">
            <p className="cmnt_member_textStyle">Date</p>
            <img
              src="/assets/images/funnel-icon.svg"
              className="cmnt_member_item_image cmnt_member_item_margin"
              alt=""
            />
          </div>
        </div> */}
        <div>
          <input
            type="text"
            value={searchValue[0]}
            onChange={handleSearch}
            className="cmnt_member_search"
            placeholder="Search by name, industry, interest or location"
            name=""
            id=""
          />
        </div>
      </section>
      <div className="cmnt_deal_room_inner cmnt_deal_room_padding_20">
        <div className="cmnt_deal_room_container_flow">
          {hasNodata && <div className="cmnt_member_table_empty">No deal available</div>}
          {isFetching[0] && <Loading isLoading={true} />}

          {hasData && (
            <>
              {dealsList[0].map((deal, index) => {
                return (
                  <div key={`deallist${index}`} className="suggested_deal_card">
                    <div className="suggested_deal_card_deal">
                      <p className="cmnt_deal_room_text description">{deal.category}</p>
                      <p className="cmnt_deal_room_text title">{deal.title}</p>
                      <p className="cmnt_deal_room_text description">{deal.description}</p>
                      <div className="suggested_deal_card_contact hide_on_desktop">
                        <div className="cmnt_deal_room_flex_date">
                          <p className="cmnt_deal_room_text description">
                            {extractDayAndMonth(deal.created_at)}
                          </p>
                        </div>
                        <p className="cmnt_deal_room_text title">Contact Details</p>
                        <p className="cmnt_deal_room_text description">
                          Name: {deal?.contact_fname ?? '-'}
                          {deal?.contact_lname ?? '-'}
                        </p>
                        <p className="cmnt_deal_room_text description">
                          Phone: {deal?.contact_phone ?? '-'}
                        </p>
                        <p className="cmnt_deal_room_text description">
                          Email: {deal?.contact_email ?? '-'}
                        </p>
                      </div>
                    </div>
                    <div className="suggested_deal_card_buttons">
                      <a
                        href={`${imageBaseUrl}${deal.deal_sheet_file}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="cmnt_deal_room_button"
                      >
                        Deal Sheet
                      </a>
                      {/* <div className="cmnt_deal_room_margin_12">
                        <button className="cmnt_deal_room_button gold">Contact</button>
                      </div> */}
                    </div>
                    <div className="suggested_deal_card_contact hide_on_mobile">
                      <div className="cmnt_deal_room_flex_date">
                        <p className="cmnt_deal_room_text description">
                          {extractDayAndMonth(deal.created_at)}
                        </p>
                      </div>
                      <p className="cmnt_deal_room_text title">Contact Details</p>
                      <p className="cmnt_deal_room_text description">
                        Name: {deal?.contact_fname ?? '-'}
                        {deal?.contact_lname ?? '-'}
                      </p>
                      <p className="cmnt_deal_room_text description">
                        Phone: {deal?.contact_phone ?? '-'}
                      </p>
                      <p className="cmnt_deal_room_text description">
                        Email: {deal?.contact_email ?? '-'}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SuggestedDeals;
