/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatMoney from '../../util/formatMoney';
import Loading from '../../components/Loading';
import format from 'date-fns/format';
import { fetchTransaction } from '../../store/actions/transactionAction';
import useService from '../../util/useService';
import { createReferrals, getReferrals } from '../../services/referralsService';
import Notify from '../../util/Notify';
import { getLogs } from '../../services/logService';
import { Link } from 'react-router-dom';

export function Referrals() {
  const dispatch = useDispatch();


  // const query = useMemo(() => getQueryParams(location.search), [location.search]);



  const createReferralCall = useService(createReferrals);

  const getReferralsCall = useService(getReferrals);
  const [emails, setEmails] = useState('');

  /**
   * @type {Referral[]}
   */
  const referrals = (getReferralsCall?.data?.data ?? []);
  // const total = getReferralsCall?.data?.total ?? 0;
  const amountDue = getReferralsCall?.data?.monthly_amount_due ?? 0;
  const monthlyRefferals = getReferralsCall?.data?.monthly_refferals ?? 0;


  const logCaller = useService(getLogs);


  const { loading: transactionLoading, transactions } = useSelector((state) => state.transaction);

  const filteredTransactions = transactions;//.filter((t)=>t.type==='referral');


  const submitReferral = () => {
    createReferralCall
      .call(emails.replace(/\s/g, '').split(','))
      .onSuccess(() =>{
        getReferralsCall.call().onError((e) => Notify.error(e.message))
        Notify.success('Referral has been made successfully!');
        setEmails('');
      })
      .onError((e) => Notify.error(e.message));

  };

  useEffect(()=>{
    getReferralsCall.call().onError((e) => Notify.error(e.message))
    logCaller.call().onError((e) => Notify.error(e.message))
    dispatch(fetchTransaction(1, {type: 'referral'}));
  },[]);

  return (
    <div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Referrals</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="manage-referrals-wrapper">
          <div className="send-referrals-block">
            <div className="send-referral-code-heading">
              <div>Send referral code to verified request</div>
            </div>
            <div>
              <div className="w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <label htmlFor="Email" className="form-field-label">
                    Email Addresses
                  </label>
                  <input
                    value={emails}
                    onChange={(event) => setEmails(event.target.value)}
                    type="text"
                    className="text-input-field w-input"
                    maxLength={256}
                    name="Email"
                    data-name="Email"
                    id="Email"
                    required
                    placeholder="hi@hello.com, me@example.com, e.t.c"
                  />
                  <input
                    onClick={submitReferral}
                    type="submit"
                    defaultValue="Send"
                    data-wait="Please wait..."
                    className="button w-button"
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="referral-stats-wrapper-flex">
            <div className="referral-stat-block">
              <div className="big-number-block">
                <div>{formatMoney(amountDue)}</div>
              </div>
              <div className="referral-stat-divider-lone" />
              <div>
                <div>Amount Due</div>
              </div>
            </div>
            <div className="referral-stat-block">
              <div className="big-number-block">
                <div>{monthlyRefferals}</div>
              </div>
              <div className="referral-stat-divider-lone" />
              <div>
                <div>Referral Made</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pool-section">
        <div className="pool-section-inner-flex">
          <div className="dashboard-content-wrapper recent-transaction h-500">
            <div className="dashboard-content-wrapper-top-border-wrapper">
              <div className="smaller-border-line" />
              <div className="dashboard-content-heading flex">
                <div className="margin-right">Referrals Made</div>
                <Link to="/referrals/all" className="view-all-button small brown w-button">
                  View All
                </Link>
              </div>
              <div className="extender-border" />
            </div>
            <div className="dashboard-content-wrapper-inner">
              <Loading isLoading={getReferralsCall.loading} />
              {referrals.map((referral)=><div className="recent-transaction-card">
                <div className="pool-title-widget">
                  <div>{format(new Date(referral.created_at), 'd LLL yyyy')}</div>
                </div>
                <div className="referral-history-flex">
                  <div>
                    <div>
                      <span className="gray-text">Email:</span> {referral.referred}
                    </div>
                    <div>
                      <span className="gray-text">Referral Code:</span> {referral.code}
                    </div>
                    <div>
                      <span className="gray-text">Referred By:</span> {referral.user?.email}
                    </div>
                  </div>
                  {/* <div className="referral-history-email-block">
                    <div className="bottom-margin _10">
                      <div className="gray-text">Email</div>
                    </div>
                    <div>
                      <div>{referral.referred}</div>
                    </div>
                  </div>
                  <div>
                    <div className="bottom-margin _10">
                      <div className="gray-text">Referral Code</div>
                    </div>
                    <div>
                      <div>{referral.code}</div>
                    </div>
                  </div> */}
                </div>
              </div>)}
            </div>
          </div>
          <div className="dashboard-content-wrapper recent-transaction last h-500">
            <div className="dashboard-content-wrapper-top-border-wrapper">
              <div className="smaller-border-line" />
              <div className="dashboard-content-heading flex">
                <div className="margin-right">Referrals Transactions</div>
                <Link to="/transaction-history?type=referral" className="view-all-button small brown w-button">
                  View All
                </Link>
              </div>
              <div className="extender-border" />
            </div>
            <div className="dashboard-content-wrapper-inner">
              <Loading isLoading={transactionLoading && filteredTransactions.length===0} />
              {filteredTransactions.map((transaction)=><div className="recent-transaction-card">

                <div className="pool-title-widget">
                  <div>{format(new Date(transaction.created_at), 'd LLL yyyy')}</div>
                  <div className="red-text">{formatMoney(transaction.amount)}</div>
                </div>
                <div>
                  <div>
                    <span className="gray-text">Referrer:</span> {transaction.user.email}
                  </div>
                  <div>
                    <span className="gray-text">Description:</span> {transaction.desc}
                  </div>
                </div>
              </div>)
            }</div>
          </div>
        </div>
      </div>
    </div>
  );
}
