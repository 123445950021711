import React from 'react';
import Button from '@material-ui/core/Button';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';



/**
 *
 * @param {Object} props
 */
export function CareerBodyEditor ({ updateCmsPostForm, cmsPostForm }) {
  
  const careers = cmsPostForm.content.body;

  const onCareerChange = (index, position) => {
    const newCareers = [...careers];
    newCareers[index] = position;

    updateCmsPostForm('content', { body: newCareers });
  };

  const addNewCareers = () => {
    const newCareers = [...careers];

    const position = {
      title: "",
      // description: "",
      questions: [
        { question: "", answer: "", }
      ],
    };

    if (cmsPostForm.slug === 'careers') {
      position.description = "";
    }

    newCareers.push(position);

    updateCmsPostForm('content', { body: newCareers });
  }

  /**
   *
   * @param {number} index
   */
  const onDeleteCareers = (index) => {
    const newCareers = [...careers];
    newCareers.splice(index, 1);
    updateCmsPostForm('content', { body: newCareers });
  }



  return (
    <>
      <br />
      {careers.map((position, index) => (
        <CareerItem slug={cmsPostForm.slug} position={position} onChange={(position) => onCareerChange(index, position)} onDelete={() => onDeleteCareers(index)} />
      ))}
      <Button variant="contained" disableElevation onClick={addNewCareers}>
        Add Position
      </Button>
      <br />
      <br />
    </>
  );
}

function CareerItem ({ position, onChange, onDelete, slug }) {
  const onInputChange = (name, value) => {
    onChange({
      ...position,
      [name]: value,
    });
  };

  const onQuestionChange = (index, question) => {
    const questions = [...position.questions];

    questions[index] = question;

    onInputChange('questions', questions);
  };

  /**
   *
   * @param {number} index
   */
  const onDeleteQandA = (index) => {
    const questions = [...position.questions];
    questions.splice(index, 1);
    onInputChange('questions', questions);
  }

  return (
    <div className="position-bordered">
      <div className="text-right">
        <span className="close-button" onClick={onDelete}></span>
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="title" className="form-field-label">
          Job Title
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('title', e.target.value)}
          value={position.title}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="title"
          data-name="title"
          id="title"
          required
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="reporting_line" className="form-field-label">
          Reporting Line
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('reporting_line', e.target.value)}
          value={position.reporting_line}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="reporting_line"
          data-name="reporting_line"
          id="reporting_line"
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="location" className="form-field-label">
          Location
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('location', e.target.value)}
          value={position.location}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="location"
          data-name="location"
          id="location"
          required
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="engagement" className="form-field-label">
          Mode of engagement
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('engagement', e.target.value)}
          value={position.engagement}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="engagement"
          data-name="engagement"
          id="engagement"
          required
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="period" className="form-field-label">
          Probationary Period
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('period', e.target.value)}
          value={position.period}
          type="text"
          className="text-input-field portfolio-management w-input"
          name="period"
          data-name="period"
          id="period"
          required
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="application_link" className="form-field-label">
          Application Link
          <span className="gray-text" />
        </label>
        <input
          onChange={(e) => onInputChange('application_link', e.target.value)}
          value={position.application_link}
          type="url"
          className="text-input-field portfolio-management w-input"
          name="application_link"
          data-name="application_link"
          id="application_link"
          required
        />
      </div>
      {slug === 'careers' ? <div className="portfolio-managment-input-block full">
        <label htmlFor="description" className="form-field-label">
          Job Description
          <span className="gray-text" />
        </label>
        <ReactQuill
          onChange={(html) => onInputChange('description', html)}
          value={position.description ?? ''}
          modules={modules}
          formats={formats}
          theme="snow" />
      </div> : null}
      <br />
      {position.body?.map((question, index) => (
        <QuestionAndAnswer
          onDelete={() => onDeleteQandA(index)}
          onChange={(question) => onQuestionChange(index, question)}
          question={question}
        />
      ))}
    </div>
  );
}


function QuestionAndAnswer ({ question, onChange, onDelete }) {
  const onQAChange = (name, value) => {


    onChange({
      ...question,
      [name]: value,
    });
  };

  return (
    <div className="inner-position-bordered">
      <div>
        <div className="text-right">
          <span className="close-button" onClick={onDelete}></span>
        </div>

      </div>

      <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Question
          <span className="gray-text" />
        </label>
        <input
          type="text"
          className="text-input-field portfolio-management w-input bg-beige"
          name="question"
          data-name="Title"
          value={question.question}
          required
          onChange={(e) => onQAChange(e.target.name, e.target.value)}
        />
      </div>
      <div className="portfolio-managment-input-block full">
        <label htmlFor="Message" className="form-field-label">
          Answer
          <span className="gray-text" />
        </label>

        <ReactQuill onChange={(html) => onQAChange('answer', html)}
          value={question.answer ?? ''} modules={modules} formats={formats} theme="snow" />

      </div>
    </div>
  );
}
