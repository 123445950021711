/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import useService from '../../util/useService';
import { getGrowthById, getGrowthTransactionsById } from '../../services/growthService';
import Notify from '../../util/Notify';
import format from 'date-fns/format';
import formatMoney from '../../util/formatMoney';



export function VolitionGrowthContent() {
  const history = useHistory();
  // @ts-ignore
  /**
   * @type {{id:string}}
   */
  const params = useParams();
  const volitionGrowthId = Number(params.id);

  const getGrowthCall = useService(getGrowthById);
  const getGrowthTransactionsCall = useService(getGrowthTransactionsById);

  useEffect(() => {
    getGrowthCall.call(volitionGrowthId).onError((e) => Notify.error(e.message));
    getGrowthTransactionsCall.call(volitionGrowthId).onError((e) => Notify.error(e.message));
  }, [volitionGrowthId]);

  /**
   * @type {VolitionGrowth}
   */
  const volitionGrowth = getGrowthCall.data ?? {};
  const growthTransactions = getGrowthTransactionsCall.data ?? []

  return (
    <div>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      {getGrowthCall.loading ? (
        <Loading isLoading />
      ) : (
        <div>
          <h1>{volitionGrowth.title}</h1>
          <div
          style={{ display: 'flex', justifyContent: 'space-between', width: '30%', marginTop: '10px' }}>
            <div style={{ textTransform: 'capitalize' }}>Currency: { volitionGrowth.currency }</div>
              <div>Cost: {formatMoney(volitionGrowth.cost, volitionGrowth.currency === 'naira' ? '₦' : '$' ) }</div>
            <div>Slots: { volitionGrowth.slot }</div>
          </div>
          <div
            style={{ marginTop: '30px' }}
            dangerouslySetInnerHTML={{ __html: volitionGrowth.body }}
          ></div>
        </div>
      )}
      
      <div>
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Transactions</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="investment-breakdown-header all-messages">
          <div className="content-block all-messages">
            <div>Date</div>
          </div>
          <div className="content-block all-messages">
            <div>User</div>
          </div>
          <div className="content-block all-message-name">
            <div>Slots</div>
          </div>
          <div className="content-block all-Growth-name">
            <div>Total Amount</div>
          </div>
        </div>
        <div className="investment-breakdown-block">
          {growthTransactions.length ? growthTransactions.map(transaction => (
            <div className="table-info-flex all-mesages">
              <div className="column-content all-messages">
                <div className="content-block mobile-header">
                  <div>Date</div>
                </div>
                <div>
                  <div>{format(new Date(transaction.created_at), 'd LLL yyyy')}</div>
                </div>
              </div>
              <div className="column-content all-messages">
                <div className="content-block mobile-header">
                  <div>User</div>
                </div>
                <div>
                  <div>{`${transaction.user.fname} ${transaction.user.lname}`} </div>
                </div>
              </div>
              <div className="column-content all-messages-name">
                <div className="content-block mobile-header">
                  <div>Slots</div>
                </div>
                <div>
                  <div>{transaction.quantity} </div>
                </div>
              </div>
              <div className="column-content all-Growth-name">
                <div className="content-block mobile-header">
                  <div>Amount</div>
                </div>
                <div>
                  <div>{formatMoney(transaction.amount, volitionGrowth.currency === 'naira' ? '₦' : '$')}</div>
                </div>
              </div>
            </div>
          )): 
          <div className="table-info-flex all-mesages">No Transactions available</div>
          }
        </div>
      </div>
    </div>
  );
}
