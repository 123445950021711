// @ts-nocheck
import React from 'react';
import formatMoney from '../../util/formatMoney';
import format from 'date-fns/format';
import Dialog from '../../util/Dialog';
import { Link } from 'react-router-dom';
import Permission from '../../components/Permission'
import moment from 'moment';
/**
 *
 * @param {{portfolio:Portfolio, deleteItem: Function}}
 */
export function PortfolioRow({ portfolio, deleteItem }) {
  return (
    <div className="table-info-flex">
      <div className="column-content extended-width">
        <div className="content-block mobile-header">
          <div>Bought</div>
        </div>
        <div>
          <div>
            {portfolio.created_at ? format(new Date(portfolio.created_at), 'd LLL yyyy') : '--'}
          </div>
        </div>
      </div>
      <div className="column-content extended-width extend">
        <div className="content-block mobile-header">
          <div>User</div>
        </div>
        <div>
          <div>
            {portfolio.user ? portfolio.user.fname : '--'}{' '}
            {portfolio.user ? portfolio.user.lname : '--'}
          </div>
        </div>
      </div>
      <div className="column-content extended-width">
        <div className="content-block mobile-header">
          <div>Pool</div>
        </div>
        <div>
          <div>{portfolio.pool ? portfolio.pool.name : '--'}</div>
        </div>
      </div>
      <div className="column-content amount extended-width extend">
        <div className="content-block mobile-header">
          <div>Amount</div>
        </div>
        <div>
          <div>
            {formatMoney(
              portfolio.capital ? portfolio.capital : 0,
              portfolio.currency === 'NGN' ? '₦' : '$',
            )}{' '}
          </div>
        </div>
      </div>
      <div className="column-content amount extended-width">
        <div className="content-block mobile-header">
          <div>ROI</div>
        </div>
        <div>
          <div>
            {formatMoney(
              portfolio.roi_amount ? portfolio.roi_amount : 0,
              portfolio.currency === 'NGN' ? '₦' : '$',
            )}{' '}
            ({portfolio.roi ? portfolio.roi : 0}%){' '}
          </div>
        </div>
      </div>
      <div className="column-content extended-width">
        <div className="content-block mobile-header">
          <div>First </div>
        </div>
        <div>
          {portfolio.is_first_investment ? (
            <div className="red-text">YES</div>
          ) : (
            <div className="gray-text">NO</div>
          )}
        </div>
      </div>
      <div className="column-content extended-width">
        <div className="content-block mobile-header">
          <div>Start</div>
        </div>
        <div>
          <div>
            {portfolio.start_date
              ? moment(portfolio.start_date, 'YYYY-MM-DD').format('MMM Do, YYYY')
              : '--'}
          </div>
        </div>
      </div>
      <div className="column-content extended-width">
        <div className="content-block mobile-header">
          <div>Yield</div>
        </div>
        <div>
          <div>
            {portfolio.end_date
              ? moment(portfolio.end_date, 'YYYY-MM-DD').format('MMM Do, YYYY')
              : '--'}
          </div>
        </div>
      </div>
      <Permission roles={['super-admin']}>
        <div className="column-content action-block">
          <div className="content-block mobile-header">
            <div>ACtion</div>
          </div>
          <div className="action-flex auto justify-contents-space-evenly">
            {/* <div className="action-button">
              <div>View</div>
            </div> */}
            <Link
              to={`/users/${portfolio.user_id ? portfolio.user_id : '--'}/portfolios/${
                portfolio.id ? portfolio.id : '--'
              }/edit`}
              className="action-button alone"
            >
              <div>Edit</div>
            </Link>
            <div
              className="action-button delete alone"
              onClick={async () => {
                if (
                  !(await Dialog.confirm(`Are you sure you want to delete this subscription?`))
                ) {
                  return;
                }
                deleteItem(portfolio);
              }}
            >
              <div>Delete</div>
            </div>
          </div>
        </div>
      </Permission>
    </div>
  );
}
