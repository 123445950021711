/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { fetchPortfolioByPool, fetchPortfolioSuccess } from '../../store/actions/portfolioAction';
import { Paginator } from '../../components/Paginator';
import { useHistory, useParams } from 'react-router-dom';
import { PortfolioRow } from './PortfolioRow';
import useService from '../../util/useService';
import { getPoolById, exportPortfolios, deletePortfolio } from '../../services/poolService';
import Notify from '../../util/Notify';
import { getUser } from '../../services/userService';


export default function PoolDetails() {
  const dispatch = useDispatch();
  const history = useHistory();


  /**
   * @type {{id:string,user_id:string|null}}
   */
  const params = useParams();
  const poolId = Number(params.id);
  const userId = params.user_id? Number(params.user_id): null;

  const [portfolioPage, setPorfolioPage] = useState(1);

  /**
   * @type {{call:Function,loading:boolean,data: User}}
   */
  const userCaller = useService(getUser);

  const { data, call: fetchPool} = useService(getPoolById);
  /**
   * @type {Pool}
   */
  const pool = data;

  const { loading: portfolioLoading, portfolios, total: totalPortfolios } = useSelector((state) => state.portfolio);

  const deletePortfolioCaller = useService(deletePortfolio);

  /**
   *
   * @param {Portfolio} portfolio
   */
  const removePortfolio = (portfolio) => {
    deletePortfolioCaller.call(portfolio.id).onSuccess(() => {
      dispatch(fetchPortfolioByPool({pool_id: poolId, page: portfolioPage, user_id: userId}));
      Notify.success("Portfolio delete successfully!")
    })
    .onError((e) => Notify.error(e.message))
  }

  useEffect(() => {
    fetchPool(poolId).onError((e) => Notify.error(e.message))
  }, [poolId]);

  useEffect(() => {
    if(userId){
      userCaller.call(userId).onError((e) => Notify.error(e.message))
    }
  }, [userId]);

  useEffect(() => {
    dispatch(fetchPortfolioSuccess({
      portfolios: [],
      total: 0
    }));
    dispatch(fetchPortfolioByPool({pool_id: poolId, page: portfolioPage, user_id: userId}));
  }, [portfolioPage, poolId, userId]);

  return (
    <>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">{userCaller.data? `${userCaller.data.fname} ${userCaller.data.lname} - `:""} {pool? pool.name: 'Pool Details'}</h1>
          <div className="extended-heading-line custom-pools" />
          <div className="filter-button-flex-parent">
            {/* <div className="w-embed">
              <select className="select-input custom">
                <option value>Filter</option>
              </select>
            </div> */}
            <div className="dummy-block smaller" />
            <a href="#" onClick={(event) =>{
              event.preventDefault();
              exportPortfolios({pool_id: String(poolId), user_id: userId });
            }} className="download-csv w-inline-block">
              <div>DOWNLOAD&nbsp;CSV</div>
            </a>
          </div>
        </div>
        {/* <div className="custom-input-wrapper">
          <div className="search-input-tag w-embed">
            <input type="text" className="text-input-field custom" placeholder="Search" />
          </div>
        </div> */}
        <div>
          <div className="investment-breakdown-header">
            <div className="content-block contribution-pool-open extended-width">
              <div>Bought</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>User</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>Pool</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>Amount</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>ROI</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>First Investment</div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>Start </div>
            </div>
            <div className="content-block contribution-pool-open extended-width">
              <div>Yield</div>
            </div>
            <div className="c">
              <div>Action</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={portfolioLoading && portfolios.length === 0} />
            {portfolios.map((portfolio) => (
              <PortfolioRow deleteItem={removePortfolio} key={portfolio.id} portfolio={portfolio} />
            ))}

          </div>
          <Paginator setPage={setPorfolioPage} page={portfolioPage} total={totalPortfolios}  />
        </div>
        <div className="overlay-prompt">
          <div className="delete-prompt">
            <div>
              <h2>Are you sure you want to delete?</h2>
            </div>
            <div className="delete-button-flex">
              <a
                data-w-id="374e8df4-eb59-c375-5418-3a49ee56a67d"
                href="#"
                className="button w-button">
                Yes
              </a>
              <a
                data-w-id="374e8df4-eb59-c375-5418-3a49ee56a67f"
                href="#"
                className="button margin-left w-button">
                No
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
