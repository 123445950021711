/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
// @ts-ignore
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import { getUserPools, addPortfolio } from '../../services/poolService';
import clsx from 'clsx';
import { getCycles } from '../../services/rateService';
import { addMonths, endOfMonth } from 'date-fns';
import { format } from 'date-fns/esm';
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const months = new Set();
let currentMonth = (new Date().getMonth()) + 1;
if (currentMonth === 12) {
  currentMonth = 0;
}
for (let month = currentMonth; month < 12; month++) {
  months.add(monthNames[month]);
}
for (let month = 0; month < currentMonth; month++) {
  months.add(monthNames[month]);
}


export function AddSubscription() {
  const history = useHistory();
  /**
   * @type {{id:string}}
   */
  const param = useParams();

  const userId = Number(param.id);

  const capitalRef = useRef(null);

  const endDateRef = useRef(null);

  const [formData, setFormData] = useState({});

  /**
   * @type {[Pool, Function]}
   */
  const [selectedPool, setSelectedPool] = useState(null);

  const getUserPoolsCall = useService(getUserPools);

  /**
   * @type {Pool[]}
   */
  const pools = getUserPoolsCall.data ?? [];
  const poolLoading = getUserPoolsCall.loading;

  const addPortfolioCall = useService(addPortfolio);

  const getCyclesCall = useService(getCycles);

  /**
   * @type {Cycle[]}
   */
  const cycles = getCyclesCall?.data ?? [];

  const years = new Set();

  cycles.forEach((cycle) => {
    months.add(cycle.month);
    years.add(cycle.year);
  });

  const currentCycle = cycles.find(
    (cycle) => cycle.isActive,
  );

  useEffect(() => {
    getUserPoolsCall.call(userId).onError((e) => Notify.error(e.message));
    getCyclesCall.call(0).onError((e) => Notify.error(e.message));
  }, [userId]);


  const onInputChange = (e) => {
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setFormData(updatedFormData);

    // @ts-ignore
    const poolId = updatedFormData.pool;
    if (!poolId) {
      return;
    }
    const selectedPool = pools.find((pool) => pool.id === Number(poolId));
    // @ts-ignore
    const month = updatedFormData.Month ?? currentCycle.month;
    // @ts-ignore
    const year = updatedFormData.Year ?? currentCycle.year;

    endDateRef.current.value = format(endOfMonth(addMonths(Date.parse(`${month} ${year}`), selectedPool.duration - 1)), "yyyy-MM-dd");
  }

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const addPortfolioCB = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);

    const month = data.get('Month');
    const year = data.get('Year');

    const endDate = data.get('endDate');


    const capital = data.get('capital');
    const includeMembership = data.get('include_membership') === 'on';

    const selectedCycle = cycles.find(
      (cycle) => cycle.year === Number(year) && cycle.month === month,
    );




    if (!selectedCycle) {
      return Notify.error("Invalid cycle selected");
    }


    const isCurrentCycle = selectedCycle.isActive;

    const portfolio = {
      pool: selectedPool.id,
      cycle: selectedCycle.id,
      user: userId,
      capital: capital,
      status: isCurrentCycle ? 'pending' : 'active',
      include_membership: includeMembership,
      endDate
    };
    addPortfolioCall
      .call(portfolio)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Subscription added successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Add Subscription</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Subscription Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form
                  // @ts-ignore
                  onSubmit={addPortfolioCB} id="email-form" name="email-form" data-name="Email Form">
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Select Investment Pool
                      </label>
                      <select
                        id="pool"
                        name="pool"
                        data-name="pool"
                        required
                        className="porfolio-management-select-input w-select"
                        onChange={(event) => {
                          const poolId = event.target.value;
                          const pool = pools.find((pool) => pool.id === Number(poolId));
                          // setEditFormData({
                          //   name: pool.name,
                          //   fee: String(pool.fee),
                          //   duration: String(pool.duration),
                          //   min_amount: String(pool.min_amount),
                          //   currency: String(pool.currency),
                          // });
                          capitalRef.current.value = pool.min_amount;
                          setSelectedPool(pool);
                          onInputChange(event)
                        }}
                      >
                        <option value="" disabled selected key="default">
                          {poolLoading ? 'Loading...' : ''}
                        </option>
                        {pools.filter(function (value) {
                          return ![1, 3, 8, 9].includes(value.id)
                        }).map((pool) => (
                          <option key={pool.id} value={pool.id}>
                            {pool.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Capital
                      </label>
                      <input
                        ref={capitalRef}
                        // min={selectedPool?.min_amount ?? 0}
                        type='number'
                        id="capital"
                        name="capital"
                        data-name="capital"
                        required
                        className="porfolio-management-select-input w-input"
                      />
                    </div>
                    {currentCycle ?
                      <>
                        <div className="portfolio-managment-input-block edit-information">
                          <label htmlFor="Month" className="form-field-label small">
                            Start Month
                          </label>
                          <select
                            onChange={onInputChange}
                            id="Month"
                            name="Month"
                            data-name="Month"
                            required
                            className="porfolio-management-select-input w-select"
                            defaultValue={currentCycle?.month ?? ""}
                          >
                            <option value="" />
                            {Array.from(months).map((month,
                              // @ts-ignore
                              index) => (
                              <option value={month}>{month}</option>
                            ))}
                          </select>
                        </div>
                        <div className="portfolio-managment-input-block edit-information">
                          <label htmlFor="Year" className="form-field-label small">
                            Start Year
                          </label>
                          <select
                            onChange={onInputChange}
                            id="Year"
                            name="Year"
                            data-name="Year"
                            required
                            className="porfolio-management-select-input w-select"
                            defaultValue={currentCycle?.year ?? ""}
                          >
                            {Array.from(years).map((year) => (
                              <option value={year}>{year}</option>
                            ))}
                          </select>
                        </div>

                        <div className="portfolio-managment-input-block edit-information">
                          <label htmlFor="Year" className="form-field-label small">
                            End Date
                          </label>
                          <input
                            // min={selectedPool?.min_amount ?? 0}
                            ref={endDateRef}
                            type='date'
                            id="endDate"
                            name="endDate"
                            data-name="endDate"
                            required
                            className="porfolio-management-select-input w-input"
                          />
                        </div>

                      </> : "Please wait..."
                    }
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="Month" className="form-field-label small">
                        Membership Fee Included in Payment <input name='include_membership' type='checkbox' />
                      </label>

                    </div>

                  </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={addPortfolioCall.loading ? 'Please wait...' : 'Create'}
                      className={clsx('button w-button', {
                        'btn-disabled': addPortfolioCall.loading,
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
