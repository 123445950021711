/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import useService from '../../util/useService';
import { updateGrowth, getGrowthById } from '../../services/growthService';
import Notify from '../../util/Notify';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

const defaultGrowthFormState = {
  title: '',
  body: '',
  accept_message: 1,
  cost: 0,
  slot: 0,
  currency: '',
  duration: '',
  payout_frequency: '',
};

export function VolitionGrowthEdit() {
  const history = useHistory();
  const updateGrowthCall = useService(updateGrowth);

  /**
   * @type {[VolitionGrowth, Function]}
   */

  const [newGrowth, setGrowthData] = useState(defaultGrowthFormState);

  /**
   *
   * @param {'title'|'body'|'duration'|'cost'|'payout_frequency'|'slot'|'currency'} inputName
   * @param {string} inputData
   */
  const updateGrowthData = (inputName, inputData) => {
    setGrowthData({
      ...newGrowth,
      [inputName]: inputData,
    });
  };

  // @ts-ignore
  /**
   * @type {{id:string}}
   */
  const params = useParams();
  const volitionGrowthId = Number(params.id);

  const getGrowthCall = useService(getGrowthById);

  useEffect(() => {
    getGrowthCall
      .call(volitionGrowthId)
      .onSuccess((data) => {
        updateGrowthData('title', data.title);
        updateGrowthData('body', data.body);

        setGrowthData({
          title: data.title,
          body: data.body,
          cost: data.cost,
          slot: data.slot,
          duration: data.duration,
          currency: data.currency
        });
      })
      .onError((e) => Notify.error(e.message));
  }, [volitionGrowthId]);

  const sendGrowth = (e) => {
    e.preventDefault();

    updateGrowthCall
      .call(volitionGrowthId, newGrowth)
      .onSuccess(() => {
        Notify.success('Growth post updated successfully');
        history.goBack()
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      {getGrowthCall.loading ? (
        <Loading isLoading />
      ) : (
        <div className="portolio-management-form-block" style={{marginTop: '0px'}}>
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" onSubmit={sendGrowth}>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Title" className="form-field-label">
                    Title
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.title}
                    onChange={(e) => updateGrowthData('title', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Title"
                    data-name="Title"
                    id="Title"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Duration" className="form-field-label">
                    Duration (months)
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.duration}
                    onChange={(e) => updateGrowthData('duration', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Duration"
                    data-name="Duration"
                    id="Duration"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Amount" className="form-field-label">
                    Amount per slot
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.cost}
                    onChange={(e) => updateGrowthData('cost', e.target.value)}
                    type="number"
                    className="text-input-field portfolio-management w-input"
                    name="Amount"
                    data-name="Amount"
                    id="Amount"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Slots" className="form-field-label">
                    Available Slots
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.slot}
                    onChange={(e) => updateGrowthData('slot', e.target.value)}
                    type="number"
                    className="text-input-field portfolio-management w-input"
                    name="Slots"
                    data-name="Slots"
                    id="Slots"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Currency" className="form-field-label">
                    Currency
                    <span className="gray-text" />
                  </label>
                  <select
                    className="text-input-field portfolio-management w-input"
                    value={newGrowth.currency}
                    onChange={(e) => updateGrowthData('currency', e.target.value)}
                    required
                    name="Currency"
                    data-name="Currency"
                    id="Currency">
                    <option disabled value="">Select an option</option>
                    <option value="naira">Naira</option>
                    <option value="dollar">Dollar</option>
                  </select>
                </div>
              </div>
              <div className="portfolio-management-form-flex space-between">
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Message" className="form-field-label">
                    Body
                    <span className="gray-text" />
                  </label>
                  <CKEditor
                    config={{
                      ckfinder: {
                        uploadUrl: baseUrl + '/image/upload',
                      },
                      height: '200px'
                      // plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
                      // toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                    }}
                    editor={ClassicEditor}
                    data={newGrowth.body}
                    onChange={(event, editor) => {
                      updateGrowthData('body', editor.getData());
                    }}
                  />
                </div>
              </div>
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Update"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
