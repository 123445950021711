import * as actionTypes from '../actions/actionTypes';

const initialState = {
  authenticated: false,
  user: null,
  loading: false,
  error: null,
}

const authReducer = (state = initialState, action) => {
  const stateClone = {
    ...state
  };
  switch (action.type) {
    case actionTypes.LOGIN: {
      stateClone.loading = true;
      break;
    }
    case actionTypes.LOGIN_INIT: {
      stateClone.loading = false;
      break;
    }
    case actionTypes.LOGIN_SUCCESS: {
      stateClone.loading = false;
      stateClone.authenticated= true;
      stateClone.user = action.payload.user;;
      break;
    }

    case actionTypes.LOGIN_ERROR: {
      stateClone.loading = false;
      stateClone.error = action.payload;
      break;
    }
    case actionTypes.LOGOUT: {
      return initialState;
    }

    default:
      ;
  }
  return stateClone;
};

export default authReducer;
