import api from '../util/api';
// import Notify from '../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * @param {CmsPost} cmsPost
 */
export function saveCmsPost(cmsPost){

  return api.put(`${baseUrl}/cms/update`, {...cmsPost});
}

export function getCmsPosts(){
  return api.get(`${baseUrl}/cms`);
}
