// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory, useParams } from 'react-router-dom';
import { getUserPools, updatePoolMembership } from '../../services/poolService';
import clsx from 'clsx';
import { format } from 'date-fns/esm';

export function UpdateMembership() {
  const history = useHistory();
  /**
   * @type {{id:string}}
   */
  const param = useParams();

  const userId = Number(param.id);

  const endDateRef = useRef(null);

  const [formData, setFormData] = useState({});



  const getUserPoolsCall = useService(getUserPools);

  /**
   * @type {Pool[]}
   */
  const pools = getUserPoolsCall.data ?? [];
  const poolLoading = getUserPoolsCall.loading;

  const updateMembershipCall = useService(updatePoolMembership);

  useEffect(() => {
    getUserPoolsCall.call(userId).onError((e) => Notify.error(e.message));
  }, [userId]);


  const onInputChange = (e)=>{
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setFormData(updatedFormData);

    const poolId = updatedFormData.pool;
    if(!poolId){
      return;
    }
    const selectedPool = pools.find((pool) => pool.id === Number(poolId));


    if(selectedPool.membership_expiry) {
      endDateRef.current.value = format(new Date(selectedPool.membership_expiry), "yyyy-MM-dd");
    }
    else {
      endDateRef.current.value = "";
    }


  }

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const updateMembership = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);

    const pool = data.get('pool');
    const endDate = data.get('endDate');



    const membership = {
      pool: pool,
      end_date: endDate,
      user: userId
    };
    updateMembershipCall
      .call(membership)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Pool membership updated successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Update Membership</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Membership Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form onSubmit={updateMembership} id="email-form" name="email-form" data-name="Email Form">
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Select Investment Pool
                      </label>
                      <select
                        id="pool"
                        name="pool"
                        data-name="pool"
                        required
                        className="porfolio-management-select-input w-select"
                        onChange={(event) => {
                          onInputChange(event)
                        }}
                      >
                        <option value="" disabled selected key="default">
                          {poolLoading ? 'Loading...' : ''}
                        </option>
                        {pools.map((pool) => (
                          <option key={pool.id} value={pool.id}>
                            {pool.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="Year" className="form-field-label small">
                        End Date
                      </label>
                      <input
                        // min={selectedPool?.min_amount ?? 0}
                        ref={endDateRef}
                        type='date'
                        id="endDate"
                        name="endDate"
                        data-name="endDate"
                        required
                        className="porfolio-management-select-input w-input"
                      />
                    </div>
                    {/* <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="Month" className="form-field-label small">
                        Generate a transaction <input name='include_membership' type='checkbox'  />
                      </label>

                    </div> */}
                  </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={updateMembershipCall.loading ? 'Please wait...' : 'Update'}
                      className={clsx('button w-button', {
                        'btn-disabled': updateMembershipCall.loading,
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
