import React, { useState, useRef } from 'react';
import Select from 'react-select';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Notify from '../../util/Notify';
import api from '../../util/api';
import { appConstant } from '../../util/constant';

const categoryOptions = [
  'Real estate',
  'Buy a business/an asset',
  'Digital assets trading',
  'Venture funding',
  'Other',
].map((val) => {
  return { label: val, value: val };
});

const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: state.isSelected ? '#f2f4f7' : 'transparent',
    color: state.isDisabled ? '#aaa' : state.isSelected ? '#838383' : '#1B1B1B',
    ':hover': {
      backgroundColor: !state.isDisabled ? '#f2f4f7' : undefined,
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '100%',
    minWidth: '300',
    paddingLeft: '14px',
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%',
    borderRadius: '3px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: '#f2f4f7',
  }),
  container: (provided, state) => ({
    ...provided,
    height: '42px',
  }),
  menu: (provided, state) => ({
    ...provided,
    top: '107%',
    zIndex: '50',
    width: '100%',
    //   marginLeft: "0.5%",
    borderRadius: '3px',
    marginTop: '2px',
    border: '1px solid #f2f4f7',
    overflow: 'hidden',
    paddingTop: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    rotate: state.isFocused ? '180deg' : '0deg',
  }),
};
const initialValue = {
  category: '',
  other_category: '',
  title: '',
  description: '',
  contact_fname: '',
  contact_lname: '',
  contact_email: '',
  contact_phone: '',
  deal_sheet_file: null,
};

const DealRoom = () => {
  const formState = useState(initialValue);

  const isSubmitting = useState(false);

  const fileRef = useRef(null);

  const updateFormState = (field, val) => {
    formState[1]((prev) => {
      return {
        ...prev,
        [field]: val,
      };
    });
  };

  const handleFileChange = (event) => {
    if (event?.target?.files && event.target.files?.length > 0) {
      if (event.target.files[0].size > 2000000) {
        Notify.error('File too large. File size should be less than 2MB');
        fileRef.current = null;
        return;
      }
      updateFormState('deal_sheet_file', event.target.files[0]);
    } else {
      return false;
    }
  };

  const handleDealCreation = (e) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append('title', formState[0].title);
    payload.append('description', formState[0].description);
    payload.append(
      'category',
      formState[0].category === 'Other' ? formState[0].other_category : formState[0].category,
    );
    payload.append('contact_fname', formState[0].contact_fname);
    payload.append('contact_lname', formState[0].contact_lname);
    payload.append('contact_email', formState[0].contact_email);
    payload.append('contact_phone', formState[0].contact_phone);
    payload.append('deal_sheet_file', formState[0].deal_sheet_file);

    isSubmitting[1](true);

    api
      .post(`${appConstant.baseUrl}/community/deal/create`, payload)
      .then(() => {
        Notify.success('Deal created successfully.');
        formState[1](initialValue);
      })
      .catch(() => {})
      .finally(() => {
        isSubmitting[1](false);
      });
  };

  const isValid =
    formState[0].title &&
    formState[0].description &&
    formState[0].contact_fname &&
    formState[0].contact_lname &&
    formState[0].contact_email &&
    formState[0].contact_phone &&
    !!formState[0].deal_sheet_file &&
    formState[0].category &&
    (formState[0].category === 'Other' ? !!formState[0].other_category : true);

  return (
    <section className="cmnt_suggest_container">
      <form
        autocomplete="off"
        onSubmit={handleDealCreation}
        className="cmnt_suggest_container_flow"
      >
        <div className="cmnt_suggest_grid">
          <div>
            <label for="deal_title" className="cmnt_suggest_inputLabel">
              Deal Title*
            </label>
            <input
              type="text"
              value={formState[0].title}
              onChange={(e) => {
                updateFormState('title', e.target.value);
              }}
              placeholder="Enter Title"
              id="deal_title"
              required
              className="cmnt_suggest_inputField"
            />
          </div>
          <div>
            <label for="deal_category" className="cmnt_suggest_inputLabel">
              Deal Category*
            </label>
            <div>
              <Select
                onChange={(item) => {
                  updateFormState('category', item?.value ?? '');
                }}
                value={
                  !formState[0].category
                    ? null
                    : categoryOptions.find((c) => c.value === formState[0].category)
                }
                className=""
                options={categoryOptions}
                required
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={selectStyle}
              />
            </div>
          </div>
          {formState[0].category === 'Other' && (
            <div>
              <label for="other_category" className="cmnt_suggest_inputLabel">
                Other Category*
              </label>
              <input
                type="text"
                value={formState[0].other_category}
                onChange={(e) => {
                  updateFormState('other_category', e.target.value);
                }}
                id="other_category"
                placeholder="Please specify category"
                required
                className="cmnt_suggest_inputField"
              />
            </div>
          )}
          <div></div>
        </div>
        <div className="cmnt_suggest_grid cmnt_suggest_margin_22">
          <div>
            <div>
              <label for="description" className="cmnt_suggest_inputLabel">
                Short Description of Deal*
              </label>
              <textarea
                type="text"
                value={formState[0].description}
                onChange={(e) => {
                  updateFormState('description', e.target.value);
                }}
                id="description"
                required
                placeholder="Enter Short Description"
                rows="5"
                className="cmnt_suggest_inputField textarea"
              />
            </div>
            <div className="cmnt_suggest_margin_22">
              <label for="description" className="cmnt_suggest_inputLabel">
                Upload Deal Sheet/Deck*
              </label>
              <button type="button" className="cmnt_suggest_upload">
                <input
                  type="file"
                  ref={fileRef}
                  onChange={handleFileChange}
                  required
                  className="cmnt_suggest_upload_file"
                  name="dealsheet"
                  id="dealsheet"
                  accept=".png,.pdf,.jpg,.docx,.xlsx"
                />
                <p className="cmnt_suggest_upload_text">
                  {formState[0]?.deal_sheet_file?.name ?? 'Upload Document'}
                </p>
                <img
                  className="cmnt_suggest_upload_icon"
                  src="/assets/images/upload-icon-white.svg"
                  alt=""
                />
              </button>
              <div className="cmnt_suggest_margin_14">
                <p className="cmnt_suggest_disclaimer">
                  Please ensure the deal sheet or presentation specifies minimum investment amount,
                  tenure, exit points and return on investment.
                </p>
              </div>
            </div>
          </div>
          <div>
            <label className="cmnt_suggest_inputLabel">Contact Information for Deal</label>
            <input
              type="text"
              value={formState[0].contact_fname}
              onChange={(e) => {
                updateFormState('contact_fname', e.target.value);
              }}
              id="first_name"
              required
              placeholder="First Name*"
              className="cmnt_suggest_inputField"
            />
            <div className="cmnt_suggest_margin_14">
              <input
                type="text"
                required
                value={formState[0].contact_lname}
                onChange={(e) => {
                  updateFormState('contact_lname', e.target.value);
                }}
                id="last_name"
                placeholder="Last Name*"
                className="cmnt_suggest_inputField"
              />
            </div>
            <div className="cmnt_suggest_margin_14">
              <input
                id="email"
                value={formState[0].contact_email}
                onChange={(e) => {
                  updateFormState('contact_email', e.target.value);
                }}
                required
                placeholder="Email Address*"
                type="email"
                className="cmnt_suggest_inputField"
              />
            </div>
            <div className="cmnt_suggest_margin_14">
              <PhoneInput
                placeholder="Enter phone number"
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={formState[0].contact_phone}
                onChange={(val) => {
                  updateFormState('contact_phone', val ?? '');
                }}
              />
            </div>
          </div>
          <div></div>
        </div>

        <button
          disabled={!isValid || isSubmitting[0]}
          className="cmnt_suggest_submit_button"
          type="submit"
        >
          {!isSubmitting[0] && <span>Submit</span>}
          {isSubmitting[0] && (
            <div className="loader-ctn">
              <div className="custom_loader reveals" />
            </div>
          )}
        </button>
      </form>
    </section>
  );
};

export default DealRoom;
