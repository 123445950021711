import { Quill } from 'react-quill';
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css';

Quill.register({
  "modules/htmlEditButton": htmlEditButton
})
const fontSizeArr = ['9px','10px', '11px', '12px', '13px', '14px', '15px' ,'16px','20px','24px','32px','42px','54px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);


export const formats = [
  'header', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color'
];
export const modules = {
  htmlEditButton: {},
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'size': fontSizeArr }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#A0830C'] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
};
