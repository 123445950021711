import api from '../util/api';
// import Notify from '../util/Notify';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * @param {VolitionExtra} extra
 */
export function saveExtra(extra){

  return api.post(`${baseUrl}/extra`, {...extra});
}

/**
 * @param {number} extraId
 * @param {VolitionExtra} extra
 */
export function updateBlue(extraId, extra){

  return api.put(`${baseUrl}/extra/update`, {...extra, id: extraId});
}

/**
 *
 * @param {Number} page
 */
export function getBlues(page){
  return api.get(`${baseUrl}/extra`, {params:{
    page
  }});
}

/**
 *
 * @param {Number} id
 */
export function getBlueById(id){
  return api.get(`${baseUrl}/extra/${id}`);
}

/**
 *
 * @param {Number} id
 */
export function deleteBlueById(id){
  return api.delete(`${baseUrl}/extra/${id}`);
}
