/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPool } from '../../store/actions/poolAction';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import Select from 'react-select';

import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/';
import { fetchUsers } from '../../store/actions/userAction';
import { sendBroadcastMessage, getMessages, exportMessages } from '../../services/messageService';
import format from 'date-fns/format';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import nl2br from '../../util/nl2br';
import Permission from '../../components/Permission'
import CustomCheckbox from '../../components/Form/CustomCheckbox';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = 'https://api.volitioncap.com/api';

// import SlimSelect from 'slim-select';

const defaultMessageFormState = {
  subject: '',
  message: '',
  recipient: '',
  user_id: null,
  selected_users: [],
  channel: [],
};
export function BroadcastMessage() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const { users } = useSelector((state) => state.user);
  //const { pools } = useSelector((state) => state.pool);

  //const customPools = pools.filter((pool) => pool.type === 'custom');

  const sendMessageCall = useService(sendBroadcastMessage);

  const [page, setPage] = useState(1);
  const getMessageCall = useService(getMessages);

  /**
   * @type {Message[]}
   */
  const messages = getMessageCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getMessageCall?.data?.total ?? 0;

  useEffect(() => {
    getMessageCall.call(page).onError((e) => Notify.error(e.message));
  }, [page]);

  useEffect(() => {
    dispatch(fetchPool());
    dispatch(fetchUsers(1, search));
  }, [search]);

  /**
   * @type {[BroadcastMessage, Function]}
   */

  const [newMessage, setMessageData] = useState(defaultMessageFormState);

  /**
   *
   * @param {'subject'|'message'|'recipient'|'user_id'|'selected_users'} inputName
   * @param {string|number|array} inputData
   */
  const updateMessageData = (inputName, inputData) => {
    setMessageData({
      ...newMessage,
      [inputName]: inputData,
    });
  };

  const handleChannelChange = (value) => {
    setMessageData((prev) => ({
      ...prev,
      channel: prev.channel.includes(value)
        ? prev.channel.filter((val) => val !== value)
        : [...prev.channel, value],
    }));
  };

  /**
   *
   * @param {string} text
   */
  const updateUserEmails = (text) => {
    const regex = /\s/g;
    updateMessageData('selected_users', text.replace(regex, '').split(','));
  };

  const options =
    users.map((user) => ({
      value: user.id,
      label: `${user.fname} ${user.lname} - ${user.email}`,
    })) || {};

  const sendMessage = (e) => {
    e.preventDefault();
    if (newMessage.channel.length < 1) {
      return Notify.error('Please select at least one channel!');
    }
    if (newMessage.recipient === 'individual' && !newMessage.user_id) {
      return Notify.error('Please select a user!');
    }
    if (newMessage.recipient.startsWith('custom-pool-')) {
      const poolId = newMessage.recipient.replace('custom-pool-', '');
      newMessage.recipient = 'custom_pool';
      newMessage.pool_id = poolId;
    }
    sendMessageCall
      .call(newMessage)
      .onSuccess(() => {
        Notify.success('Message sent successfully!');
        setMessageData(defaultMessageFormState);
        getMessageCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };
  return (
    <>
      <div className="pool-section">
        <Permission roles={['super-admin', 'admin']}>
          <div className="section-heading-block margin-bottom">
            <h1 className="brown-text">Send Broadcast Message</h1>
            <div className="extended-heading-line custom-pools" />
          </div>
          <div className="portolio-management-form-block">
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                onSubmit={sendMessage}
              >
                <div>
                  <div className="portfolio-managment-input-block portfolio-management-form-flex-center">
                    <span className="form-field-label custom-shrink-0">Select Channel</span>
                    <div className="custom-ml-27">
                      <CustomCheckbox
                        onChange={() => {
                          handleChannelChange('email');
                        }}
                        checked={newMessage.channel.includes('email')}
                        label="Email"
                        name="email"
                      />
                    </div>

                    <div className="custom-ml-27">
                      <CustomCheckbox
                        onChange={() => {
                          handleChannelChange('in-app');
                        }}
                        checked={newMessage.channel.includes('in-app')}
                        label="In-App"
                        name="in-app"
                      />
                    </div>
                  </div>
                </div>
                <div className="portfolio-management-form-flex space-between">
                  <div className="portfolio-managment-input-block half">
                    <label htmlFor="Select-Recipient" className="form-field-label">
                      Select Recipient
                    </label>
                    <select
                      value={newMessage.recipient}
                      onChange={(event) => updateMessageData('recipient', event.target.value)}
                      id="Select-Recipient"
                      name="Select-Recipient"
                      data-name="Select Recipient"
                      required
                      className="porfolio-management-select-input w-select"
                    >
                      <option value="" disabled />
                      <option value="all">Everyone</option>
                      <option value="individual">Single Individual</option>
                      <option value="selected_users">Selected Users</option>
                      <option value="active">Active Users</option>
                      <option value="inactive">Inactive Users</option>
                      <option value="black">Black Pool Members</option>
                      <option value="red">Red Pool Members</option>
                      <option value="green">Green Pool Members</option>
                      <option value="platinum">Platinum Pool Members</option>
                      <option value="privilege">Gold Pool Members</option>
                      <option value="custom_pool">Custom Pool Members</option>
                      {/* {customPools.map((pool) => <option value={`custom-pool-${pool.id}`}>{pool.name} Members</option>)} */}
                    </select>
                  </div>
                  {newMessage.recipient === 'individual' ? (
                    <div className="portfolio-managment-input-block half">
                      <label htmlFor="select-user" className="form-field-label">
                        Select User
                      </label>
                      <div>
                        <Select
                          onChange={(item) => {
                            updateMessageData('user_id', item['value']);
                          }}
                          onInputChange={(value) => {
                            if (value.length > 3) {
                              setSearch(value);
                            }
                            if (value.length === 0) {
                              setSearch('');
                            }
                          }}
                          className=""
                          options={options}
                          required
                        />
                      </div>
                    </div>
                  ) : null}
                  {newMessage.recipient === 'selected_users' ? (
                    <div className="portfolio-managment-input-block half">
                      <label htmlFor="select-user" className="form-field-label">
                        Users Emails
                      </label>
                      <div>
                        <input
                          onChange={(e) => updateUserEmails(e.target.value)}
                          type="text"
                          className="text-input-field portfolio-management w-input"
                          name="Subject"
                          data-name="Subject"
                          id="Subject"
                          required
                          placeholder="e.g hello@example.com, mail@example.com, e.t.c"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="portfolio-managment-input-block full">
                    <label htmlFor="Subject" className="form-field-label">
                      Subject
                      <span className="gray-text" />
                    </label>
                    <input
                      value={newMessage.subject}
                      onChange={(e) => updateMessageData('subject', e.target.value)}
                      type="text"
                      className="text-input-field portfolio-management w-input"
                      name="Subject"
                      data-name="Subject"
                      id="Subject"
                      required
                    />
                  </div>
                </div>
                <div className="portfolio-management-form-flex space-between">
                  <div className="portfolio-managment-input-block full">
                    <label htmlFor="Message" className="form-field-label">
                      Message
                      <span className="gray-text" />
                    </label>
                    <CKEditor
                      config={{
                        ckfinder: {
                          uploadUrl: baseUrl + '/image/upload',
                        },
                        height: '200px',
                        // plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
                        // toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                      }}
                      editor={ClassicEditor}
                      data={newMessage.message}
                      onChange={(event, editor) => {
                        updateMessageData('message', editor.getData());
                        // updateBlueData('body', editor.getData());
                      }}
                    />
                    {/* <textarea
                      value={newMessage.message}
                      onChange={(e) => updateMessageData('message', e.target.value)}
                      name="Message"
                      maxLength={5000}
                      id="Message"
                      data-name="Message"
                      required
                      className="text-input-field teaxt-area w-input"
                      defaultValue={''}
                    /> */}
                  </div>
                </div>
                <div className="submit-button-flex">
                  <input
                    type="submit"
                    defaultValue="Send Message"
                    data-wait="Please wait..."
                    className="send-message-button w-button"
                  />
                </div>
              </form>
            </div>
          </div>
        </Permission>
        <div>
          <div className="section-heading-block margin-bottom">
            <h1 className="brown-text">Broadcast Messages</h1>
            <div className="extended-heading-line custom-pools" />
            <div className="filter-button-flex-parent">
              {/* <div className="w-embed">
                <select className="select-input custom">
                  <option value>Filter</option>
                </select>
              </div> */}
              <div className="dummy-block smaller" />
              <button
                onClick={(event) => {
                  event.preventDefault();
                  exportMessages({});
                }}
                className="download-csv w-inline-block"
              >
                <div>DOWNLOAD CSV</div>
              </button>
            </div>
          </div>
          <div className="investment-breakdown-header all-messages">
            <div className="content-block all-messages">
              <div>Date</div>
            </div>
            <div className="content-block all-message-name">
              <div>Name</div>
            </div>
            <div className="content-block all-message-name">
              <div>Email</div>
            </div>
            <div className="content-block all-messages">
              <div>Subject</div>
            </div>
            <div className="content-block all-messages">
              <div>Description</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={getMessageCall.loading && messages.length === 0} />
            {messages.map((message) => (
              <div className="table-info-flex all-mesages">
                <div className="column-content all-messages">
                  <div className="content-block mobile-header">
                    <div>Date</div>
                  </div>
                  <div>
                    <div>
                      {message.created_at
                        ? format(new Date(message.created_at), 'd LLL yyyy')
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>name</div>
                  </div>
                  <div>
                    <div>
                      {message.user && message.user.fname ? message.user.fname : ''}{' '}
                      {message.user && message.user.lname ? message.user.lname : '--'}
                    </div>
                  </div>
                </div>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>email</div>
                  </div>
                  <div>
                    <div>{message.user && message.user.email ? message.user.email : '--'} </div>
                  </div>
                  <div className="w-embed">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n\t.portfolio-managment-pool {\n  \tword-break: break-all\n  }\n',
                      }}
                    />
                  </div>
                </div>
                <div className="column-content portfolio-managment-pool">
                  <div className="content-block mobile-header">
                    <div>Subject</div>
                  </div>
                  <div>
                    <div>{message.subject ? message.subject : '--'}</div>
                  </div>
                </div>
                <div className="column-content portfolio-managment-pool">
                  <div className="content-block mobile-header">
                    <div>Subject</div>
                  </div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: nl2br(message.description) }}></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}
