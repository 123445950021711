import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUsers } from '../../services/userService';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Notify from '../../util/Notify';
import debounce from '../../util/debounce';
const debounceError = debounce(Notify.error, 500)
export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e3dbbb',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(160, 131, 11, 0.3)',
      },
      '&:hover fieldset': {
        borderColor: '#e3dbbb',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e3dbbb',
      },
    },
  },
})(TextField);
/**
 *
 * @param {{handleSelectedUser: Function, isMultiple?: boolean, label: string}} param0
 */
export default function AutoCompleteUser({ handleSelectedUser, isMultiple, label }) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try{
        const users = (await getUsers(search)).data;

        if (active) {
          setOptions(users);
          // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
        }
      }
      catch(e){
        console.log(e)
        debounceError(e.message)
      }

    })();

    return () => {
      active = false;
    };
  }, [loading, search]);

  React.useEffect(() => {
    if(!open || search.length > 2){
      setOptions([]);
    }
  }, [open, search]);

  return (
    <Autocomplete
      id="select-users"
      multiple={isMultiple}
      open={open}
      onOpen={() => {
        setOpen(true);
        setSearch('');
      }}
      onClose={() => {
        setOpen(false);
        setSearch('');
      }}
      onChange={(event, value) => {
        handleSelectedUser(value);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.fname} ${option.lname} (${option.email})`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <CssTextField
          {...params}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }} />
      )} />
  );
}
