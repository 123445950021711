// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
// import { useDispatch } from 'react-redux';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory, useParams } from 'react-router-dom';
// import { createPool, getUserPools, addPortfolio, updatePoolMembership } from '../../services/poolService';
import clsx from 'clsx';
// import { getCycles } from '../../services/rateService';
// import parse from 'date-fns/parse';
// import { format } from 'date-fns/esm';
import { updateGrowthMembership, getGrowthMembership } from '../../services/growthService';

export function UpdateGrowthMembership() {
  const history = useHistory();
  /**
   * @type {{id:string}}
   */
  const param = useParams();

  const userId = Number(param.id);

  const endDateRef = useRef(null);

  const getGrowthMembershipCall = useService(getGrowthMembership);

  const updateMembershipCall = useService(updateGrowthMembership);

  useEffect(() => {
    getGrowthMembershipCall.call(userId).onSuccess((data) => {
      if(data?.expiry_date) {
        endDateRef.current.value = data?.expiry_date;
      }
    } ).onError((e) => Notify.error(e.message));
  }, [userId]);

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const updateMembership = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);

    const endDate = data.get('endDate');



    const membership = {
      end_date: endDate,
      user_id: userId
    };
    updateMembershipCall
      .call(membership)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Pool membership updated successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Update Growth Membership</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Growth Membership Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form onSubmit={updateMembership} id="email-form" name="email-form" data-name="Email Form">
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="Year" className="form-field-label small">
                        End Date
                      </label>
                      <input
                        // min={selectedPool?.min_amount ?? 0}
                        ref={endDateRef}
                        type='date'
                        id="endDate"
                        name="endDate"
                        data-name="endDate"
                        required
                        className="porfolio-management-select-input w-input"
                      />
                    </div>
                  </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={updateMembershipCall.loading ? 'Please wait...' : 'Update'}
                      className={clsx('button w-button', {
                        'btn-disabled': updateMembershipCall.loading,
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
