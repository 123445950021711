/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import clsx from 'clsx';
import { Input, Form, ErrorMessage } from '../../components/Form/Form';
// import initWebflow from '../../util/initWebflow';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginInit } from '../../store/actions/loginAction';
import './index.css';
import { useEffect } from 'react';

export default function Login() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const submitForm = (values) => {
    dispatch(login(values.email, values.password));
  };

  useEffect(() => {
    dispatch(loginInit());
  }, [dispatch]);


  return (
    <div className="login-page">
      <div className="top-block">
        <a href="https://admin.volitioncoop.com" className="logo-block"></a>
      </div>
      <div className="login-form-wrapper">
        <div className="login-form-block">
          <div className="login-cta">
            <div>LOGIN</div>
          </div>
          <div className="w-form">
            <Form onSubmit={submitForm}>
              <div className="input-block">
                <label htmlFor="Email" className="form-field-label">
                  Email
                </label>
                <Input type="email" name="email" />
                <ErrorMessage name="email" />
              </div>
              <div className="input-block">
                <label htmlFor="Password-3" className="form-field-label">
                  Password
                </label>
                <Input type="password" name="password" />
                <ErrorMessage name="password" />
              </div>

              <button
                type="submit"
                className={clsx('login-button w-button', { 'btn-disabled': loading })}
              >
                {loading ? 'Please wait...' : 'Login'}
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
