// @ts-nocheck
import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory } from 'react-router-dom';
import { createPool } from '../../services/poolService';
import clsx from 'clsx';
// import { fetchUsers } from '../../store/actions/userAction';

// import api from '../../util/api';

import AutoCompleteUser from './AutoCompleteUser';

export function CreatePool() {
  const history = useHistory();
  // const dispatch = useDispatch();

  const [targetAudience, setTargetAudience] = useState('');
  const [emails, setEmails] = useState([]);
  const createPoolCall = useService(createPool);

  /**
   *
   * @param {string} text
   */

  const getUserObject = (userObj) => {
    const usersEmail = userObj.map((user) => user.email)
    setEmails(usersEmail)
  }

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const addPool = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);
    const poolName = data.get('pool_name');
    const membershipFee = data.get('membership_fee');
    const duration = data.get('duration');
    const minAmount = data.get('min_amount');
    const currency = data.get('currency');

    const pool = {
      name: poolName,
      duration,
      fee: membershipFee,
      currency: currency,
      min_amount: minAmount,
      type: 'custom',
      audience:
        targetAudience === 'all_users'
          ? { type: 'all_users' }
          : { type: 'selected_users', emails: emails },
    };

    createPoolCall
      .call(pool)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Pool created successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Create Pool</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Pool Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form onSubmit={addPool} id="email-form" name="email-form" data-name="Email Form">
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool_name" className="form-field-label small">
                        Investment Pool Name
                        <span className="gray-text" />
                      </label>
                      <input
                        type="text"
                        className="text-input-field portfolio-management w-input"
                        maxLength={256}
                        name="pool_name"
                        data-name="Investment Pool Name"
                        id="pool_name"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="min_amount" className="form-field-label small">
                        Minimum Amount
                        <span className="gray-text" />
                      </label>
                      <input
                        type="number"
                        className="text-input-field portfolio-management w-input"
                        maxLength={256}
                        name="min_amount"
                        data-name="Membership Fee 2"
                        id="min_amount"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="membership_fee" className="form-field-label small">
                        Membership fee
                        <span className="gray-text" />
                      </label>
                      <input
                        type="number"
                        className="text-input-field portfolio-management w-input"
                        maxLength={256}
                        name="membership_fee"
                        data-name="Membership Fee 2"
                        id="membership_fee"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="duration" className="form-field-label small" style={{ textAlign: 'left' }}>
                        Currency
                      </label>
                      <select
                        className="text-input-field portfolio-management w-input"
                        name="currency"
                        data-name="Currency"
                        id="currency"
                        required>
                        <option disabled value="">Select an option</option>
                        <option value="dollars">Dollars</option>
                        <option value="naira">Naira</option>
                      </select>
                    </div>
                  </div>
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="duration" className="form-field-label small">
                        Duration <span className="gray-text">(Months)</span>
                      </label>
                      <input
                        type="number"
                        className="text-input-field portfolio-management w-input"
                        maxLength={256}
                        name="duration"
                        data-name="Duration Months"
                        id="duration"
                        required
                      />
                    </div>
                  </div>
                  <div className="portfolio-management-form-flex edit-information">
                    <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="duration" className="form-field-label small">
                        Target Audience
                      </label>
                      <select
                        onChange={(e) => {
                          setTargetAudience(e.target.value);
                        }}
                        value={targetAudience}
                        className="text-input-field portfolio-management w-input"
                        name="target_audience"
                        data-name="Target Audience"
                        id="target_audience"
                        required
                      >
                        <option selected disabled value="">Select an option</option>
                        <option value="all_users"> All Users</option>
                        <option value="selected_users">Selected Users</option>
                      </select>
                    </div>
                  </div>
                  {targetAudience === 'selected_users' ? (
                    <div className="portfolio-management-form-flex edit-information">
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="duration" className="form-field-label small">
                          Selected Users
                        </label>
                        <div>
                          <AutoCompleteUser
                          label="Select Users"
                          isMultiple
                          handleSelectedUser={(users) => {
                            getUserObject(users)
                          }}
                        />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={createPoolCall.loading ? 'Please wait...' : 'Create'}
                      className={clsx('button w-button', {
                        'btn-disabled':
                          createPoolCall.loading ||
                          (targetAudience === 'selected_users' && emails.length === 0),
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
