import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 *
 * @param {Number} page
 */
export function getRates(page){
  return api.get(`${baseUrl}/rates`, {params:{
    page
  }});
}

/**
 *
 * @param {Number} rateId
 */
export function getRate(rateId){
  return api.get(`${baseUrl}/rates/${rateId}`);
}

/**
 *
 * @param {Number} rateId
 */
export function deleteRate(rateId){
  return api.delete(`${baseUrl}/rates/${rateId}`);
}

/**
 *
 * @param {number} rateId
 * @param {number} rate
 * @param {number} onePercent
 */
export function updateRate(rateId, rate, onePercent){
  return api.put(`${baseUrl}/rates/${rateId}`, {rate, one_percent_charge: onePercent});
}


export function createRate(params){
  return api.post(`${baseUrl}/rates`, {...params});
}

export function getCycles(){
  return api.get(`${baseUrl}/cycles`);
}

export function exportRates(){
  return api.get(`${baseUrl}/rates/export`).then(function(response){

    download('rates.csv', response);
    Notify.success("Rates data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}
