import React, { useEffect } from 'react';
import initWebflow from '../../util/initWebflow';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions/loginAction';
import api from '../../util/api';
import { useHistory, Link } from 'react-router-dom';
import Permission from '../Permission'

/**
 *
 * @param {{children: React.ReactNode}} props
 */
export default function Navbar(props) {
  const token = useSelector((state) => state.auth.user?.token);
  const isNetworkLoading = useSelector((state) => state.loadingBar.default);

  api.defaults.headers.common.Authorization = `Bearer ${token}`;

  const history = useHistory();
  const isCommunityRoute = !!history?.location?.pathname?.includes('community');

  const dispatch = useDispatch();
const scrollOnLoad = () => {
  window.scrollTo({
    top: 400,
    left: 0,
    behavior: 'smooth',
  });
};

useEffect(() => {
  initWebflow();
  const deviceWidth = window.innerWidth;
  if (deviceWidth <= 500) {
    setTimeout(() => {
      scrollOnLoad();
    }, 50);
  }
}, [history.location.pathname]);

const signout = () => {
  dispatch(logout());
};

return (
  <div className="dashboard-container contribution-pool-page">
    {isNetworkLoading ? <div className="network-loader"></div> : null}
    <div className="logo-and-nav-block">
      <a className="dashboard-logo" href="https://admin.volitioncap.com">
        {' '}
      </a>
      <div className="nav-block-wrapper">
        <div className="nav-block">
          <Link to="/" className="dashboard-nav-link dashboard w-inline-block"></Link>
          <div className="dashboard-link-hover-state dashboard"></div>
          <div className="nav-title">Dashboard</div>
        </div>
        <div className="nav-block">
          <Link to="/community" className="dashboard-nav-link community w-inline-block"></Link>
          <div className="dashboard-link-hover-state community"></div>
          <div className="nav-title">Community</div>
        </div>
        <div className="nav-block contribution">
          <Link
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819f8f"
            to="/pools"
            className="dashboard-nav-link contribution-pool w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state contribution-pool"></div>
          <div className="nav-title">Contribution Pools</div>
        </div>
        <div className="nav-block">
          <Link
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819f8f"
            to="/matured-investments"
            className="dashboard-nav-link wallet w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state wallet"></div>
          <div className="nav-title">Matured Investments</div>
        </div>

        <div className="nav-block">
          <Link
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819f8f"
            to="/referral-bonus"
            className="dashboard-nav-link referral-bonus w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state referral-bonus"></div>
          <div className="nav-title">Referral Bonus</div>
        </div>

        <div className="nav-block">
          <Link
            to="/volition-extra"
            className="dashboard-nav-link volition-blue w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state volition-blue"></div>
          <div className="nav-title">Volition Extra</div>
        </div>
        <div className="nav-block">
          <Link
            to="/volition-growth"
            className="dashboard-nav-link growth-icon w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state growth-icon"></div>
          <div className="nav-title">Volition Growth</div>
        </div>
        <div className="nav-block">
          <Link
            to="/referrals"
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fae"
            className="dashboard-nav-link referrals w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state referrals"></div>
          <div className="nav-title">Referrals</div>
        </div>
        <div className="nav-block">
          <Link
            to="/transaction-history"
            className="dashboard-nav-link transaction-history w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state transaction-history"></div>
          <div className="nav-title">Transactions History</div>
        </div>
        <div className="nav-block">
          <Link
            to="/users"
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fc3"
            className="dashboard-nav-link user w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state user"></div>
          <div className="nav-title">Users</div>
        </div>

        <div className="nav-block">
          <Link
            to="/portfolio-management"
            className="dashboard-nav-link portfolio-management w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state portfolio-management"></div>
          <div className="nav-title">Portfolio Management</div>
        </div>

        <div className="nav-block">
          <Link
            to="/broadcast-messages"
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fbc"
            className="dashboard-nav-link messages w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state messages"></div>
          <div className="nav-title">Broadcast Messages</div>
        </div>
        <div className="nav-block">
          <Link
            to="/admins"
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fc3"
            className="dashboard-nav-link admin-icon w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state admin-icon"></div>
          <div className="nav-title">Administrators</div>
        </div>
        {/* <div className="nav-block">
            <Link
              to="/greensboro"
              data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fc3"
              className="dashboard-nav-link volition-blue w-inline-block"
            ></Link>
            <div className="dashboard-link-hover-state volition-blue"></div>
            <div className="nav-title">Greensboro Sub</div>
          </div> */}
        <div className="nav-block">
          <Link to="/resources" className="dashboard-nav-link media-icon w-inline-block"></Link>
          <div className="dashboard-link-hover-state media-icon"></div>
          <div className="nav-title">Resources</div>
        </div>
        <div className="nav-block">
          <Link
            to="/activity-logs"
            className="dashboard-nav-link activity-log w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state activity-log"></div>
          <div className="nav-title">Activity Logs</div>
        </div>
        <Permission roles={['super-admin', 'admin']}>
          <div className="nav-block">
            <Link to="/cms-posts" className="dashboard-nav-link cms-icon w-inline-block"></Link>
            <Link
              to="/cms-posts"
              className="dashboard-link-hover-state cms-icon w-inline-block"
            ></Link>
            <div className="nav-title">Content Management</div>
          </div>
        </Permission>
        <div className="nav-block">
          <Link
            to="/spooling"
            data-w-id="e39a21bb-f35f-378d-8936-dfda6e819fc3"
            className="dashboard-nav-link spooling w-inline-block"
          ></Link>
          <div className="dashboard-link-hover-state spooling"></div>
          <div className="nav-title">Spooling</div>
        </div>
        {/* <Permission roles={['super-admin', 'admin']}>
            <div className="nav-block">
              <Link to="/dubai" className="dashboard-nav-link cms-icon w-inline-block"></Link>
              <Link
                to="/dubai"
                className="dashboard-link-hover-state cms-icon w-inline-block"
              ></Link>
              <div className="nav-title">Dubai DET</div>
            </div>
          </Permission> */}
        <Permission roles={['super-admin', 'admin']}>
          <div className="nav-block">
            <Link
              to="/dollar-rate"
              className="dashboard-nav-link exchange-rate w-inline-block"
            ></Link>
            <Link
              to="/dollar-rate"
              className="dashboard-link-hover-state exchange-rate w-inline-block"
            ></Link>
            <div className="nav-title">Dollar Exchange Rate</div>
          </div>
        </Permission>
        <div className="nav-block">
          <Link to="/edit-page" className="dashboard-nav-link edit w-inline-block"></Link>
          <Link to="/edit-page" className="dashboard-link-hover-state edit w-inline-block"></Link>
          <div className="nav-title">Settings</div>
        </div>
        <div className="nav-block logout">
          <div onClick={signout} className="dashboard-nav-link logout w-inline-block"></div>
          <div className="dashboard-link-hover-state logout w-inline-block"></div>
        </div>
        <div className="nav-block hide">
          <div className="dashboard-nav-link messages w-inline-block"></div>
          <div className="dashboard-link-hover-state messages"></div>
        </div>
      </div>
    </div>
    <div className={`dashboard-inner-wrapper ${isCommunityRoute ? 'grey' : ''}`}>
      {props.children}
    </div>
  </div>
);
}
