import * as actionTypes from '../actions/actionTypes';

/**
 * @type {PortfolioState}
 */
const initialState = {
  portfolios:[],
  total: 0,
  loading: false,
  error: null,
}

const portfolioReducer = (state = initialState, action) => {
  const stateClone = {
    ...state
  };
  switch (action.type) {
    case actionTypes.FETCH_PORTFOLIOS: {
      stateClone.loading = true;
      break;
    }
    case actionTypes.FETCH_PORTFOLIOS_SUCCESS: {
      stateClone.loading = false;
      stateClone.portfolios = action.payload.portfolios;
      stateClone.total = action.payload.total;
      break;
    }

    case actionTypes.FETCH_PORTFOLIOS_ERROR: {
      stateClone.loading = false;
      stateClone.error = action.payload;
      break;
    }

    case actionTypes.LOGOUT: {
      return initialState;
    }

    default:
      ;
  }
  return stateClone;
};

export default portfolioReducer;
