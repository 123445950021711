/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import { getReferrals } from '../../services/referralsService';
import Notify from '../../util/Notify';
import useService from '../../util/useService';

const AllReferrals = () => {

  const getReferralsCall = useService(getReferrals);

  /**
   * @type {Referral[]}
   */
  const referrals = (getReferralsCall?.data?.data ?? []);
  const total = getReferralsCall?.data?.total ?? 0;

  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');


  useEffect(()=>{
    getReferralsCall.call({page, search}).onError((e) => Notify.error(e.message))
  },[page, search]);

  return ( <>
  <div className="back-button-parent">
    <Link to="/referrals" className="back-button-flex w-inline-block">
      <div className="back-button-icon" />
      <div>Back</div>
    </Link>
  </div>
  <div className="pool-section">
    <div className="section-heading-block margin-bottom">
      <div>
        <h1 className="brown-text">Referral Requests</h1>

      </div>
      <div className="extended-heading-line custom-pools" />
      <div className="filter-button-flex-parent">
      {/* <div className="w-embed">
        <input className="input custom" />
      </div> */}
        {/*
        <div className="dummy-block smaller" />
        <a href="#" className="download-csv w-inline-block">
          <div>DOWNLOAD CSV</div>
        </a> */}
      </div>
    </div>
    <div className="custom-input-wrapper">
          <div className="search-input-tag w-embed">
            <input
              type="text"
              className="text-input-field custom"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="filter-button-flex-parent">
            {/* <div className="margin-bottom w-embed">
              <select className="select-input custom no-caps">
                <option value="">Select Investment Pool</option>
              </select>
            </div>
            <div className="dummy-block smaller"></div>
            <div className="w-embed">
              <select value={filterStatus} onChange={(e)=>setFilterStatus(e.target.value)} className="select-input custom no-caps dark-brown">
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">InActive</option>
              </select>
            </div> */}
          </div>
        </div>

    <div>
      <div className="investment-breakdown-header">
        <div className="content-block ">
          <div>Date</div>
        </div>
        <div className="content-block referral-request-email">
          <div>Referral code</div>
        </div>
        <div className="content-block referral-requests">
          <div>Reffered Email</div>
        </div>


        <div className="content-block referral-request-email">
          <div>Referrer Name</div>
        </div>
        <div className="content-block referral-requests">
          <div>Referrer Email</div>
        </div>

      </div>
      <div className="investment-breakdown-block">
        <Loading isLoading={getReferralsCall.loading && referrals.length === 0} />
        {referrals.map((referral) => (
        <div className="table-info-flex">
          <div className="column-content referral-requests">
            <div className="content-block mobile-header">
              <div>date</div>
            </div>
            <div>
              <div>{format(new Date(referral.created_at), 'd LLL yyyy')}</div>
            </div>
          </div>
          <div className="column-content referral-request-email">
            <div className="content-block mobile-header">
              <div>referral code</div>
            </div>
            <div>
              <div className="brown-text">{referral.code}</div>
            </div>
          </div>
          <div className="column-content referral-request">
            <div className="content-block mobile-header">
              <div>Reffered Email</div>
            </div>
            <div>
              <div>{referral.referred}</div>
            </div>
          </div>
          <div className="column-content referral-request-email">
            <div className="content-block mobile-header">
              <div>Referrer Name</div>
            </div>
            <div>
              <div>{referral.user?.fname} {referral.user?.lname}</div>
            </div>
          </div>
          <div className="column-content  referral-request-email">
            <div className="content-block mobile-header">
              <div>Referrer Email</div>
            </div>
            <div>
              <div>{referral.user?.email}</div>
            </div>
          </div>

        </div>
        ))}
      </div>
    </div>
  </div>
  <Paginator setPage={setPage} total={total} page={page} />
</>);
};
export default AllReferrals;
