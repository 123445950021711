import React, { useState } from 'react'
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { setDollarRate } from '../../services/exchangeRateService';

export function DollarRate() {
    const [newExchangeRate, setnewExchangeRate] = useState(null)
    const sendRateCall = useService(setDollarRate);
    const handleSetDollarRate = (e) => {
        e.preventDefault()

        sendRateCall
      .call(newExchangeRate)
      .onSuccess((res) => {
        Notify.success('Dollar Rate successfully updated');
        setnewExchangeRate(null)
      })
      .onError((e) => Notify.error(e.message));
    }

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Dollar Exchange Rate</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="portolio-management-form-block">
          <div className="w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            onSubmit={handleSetDollarRate}
          >
            {/* <div>
            <div className="portfolio-managment-input-block full">
                <label htmlFor="Subject" className="form-field-label">
                    Current Exchange Rate
                    <span className="gray-text" />
                </label>
                <input
                    value={currentExchangeRate}
                    // onChange={(e) => updateMediaPost('media', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="current-rate"
                    data-name="Resource Url"
                    disabled
                />
                </div>
            </div> */}
            <div>
            <div className="portfolio-managment-input-block full">
                <label htmlFor="Subject" className="form-field-label">
                    Set New Exchange Rate
                    <span className="gray-text" />
                </label>
                <input
                    value={newExchangeRate}
                    onChange={(e) => setnewExchangeRate(e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="current-rate"
                    data-name="Resource Url"
                    required
                />
                </div>
            </div>
            <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Update"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
          </form>
          </div>
        </div>
      </div>
    </>
  )
}
