/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPool } from '../../store/actions/poolAction';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import { getRates, getCycles, createRate, exportRates, deleteRate } from '../../services/rateService';
import { Link } from 'react-router-dom';
import Dialog from '../../util/Dialog';
import Notify from '../../util/Notify';
import Permission from '../../components/Permission'


const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const months = new Set();
const currentMonth = (new Date().getMonth());
for (let month = currentMonth; month < 12; month++){
  months.add(monthNames[month]);
}
for (let month = 0; month < currentMonth; month++) {
  months.add(monthNames[month]);
}


export function PortfolioManagement() {

  const dispatch = useDispatch();

  const { pools } = useSelector((state) => state.pool);

  const getRatesCall = useService(getRates);
  const getCyclesCall = useService(getCycles);
  const createRateCall = useService(createRate);
  const deleteRateCall = useService(deleteRate);

  const [page, setPage] = useState(1);

  /**
   * @type {Rate[]}
   */
  const rates = getRatesCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getRatesCall?.data?.total ?? 0;

  /**
   * @type {Cycle[]}
   */
  const cycles = getCyclesCall?.data ?? [];

  const years = new Set();


  cycles
  .forEach((cycle) => {
    months.add(cycle.month);
    years.add(cycle.year);
  });

  useEffect(() => {
    dispatch(fetchPool());
  }, [dispatch]);

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const addRate = (e) => {
    const form = e.target;
    // @ts-ignore
    const data = new FormData(e.target);
    const month = data.get('Month');
    const year = data.get('Year');
    const poolId = data.get('Pool');
    const rate = data.get('Change');
    const charge = data.get('Charge');

    const selectedCycle = cycles.find(
      (cycle) => cycle.year === Number(year) && cycle.month === month,
    );
    const payload = {
      pool: poolId,
      cycle: selectedCycle.id,
      rate: rate,
      one_percent_charge: charge
    };

    createRateCall
      .call(payload)
      .onSuccess(() => {
        getRatesCall.call().onError((e) => Notify.error(e.message));
        Notify.success('Pool rate has been added successfully!');
        form.reset();
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {number} rateId
   */
  const removeRate = async (rateId) => {
    if(!await Dialog.confirm("Are you sure you want to delete this rate?")){
      return;
    }

    deleteRateCall
      .call(rateId)
      .onSuccess(() => {
        Notify.success('Rate deleted successfully');
        getRatesCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };



  useEffect(() => {
    getRatesCall.call(page).onError((e) => Notify.error(e.message));
    getCyclesCall.call(page).onError((e) => Notify.error(e.message));
  }, [page]);

  return (
    <div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Portfolio Management</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <Permission roles={['super-admin']}>
          <div className="portolio-management-form-block">
            <div className="w-form">
              <form id="email-form" name="email-form" data-name="Email Form" onSubmit={addRate}>
                <div className="portfolio-management-form-flex">
                  <div className="portfolio-managment-input-block first">
                    <label htmlFor="Month" className="form-field-label small">
                      Month
                    </label>
                    <select
                      id="Month"
                      name="Month"
                      data-name="Month"
                      required
                      className="porfolio-management-select-input w-select"
                    >
                      <option value="" />
                      {Array.from(months).map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </select>
                  </div>
                  <div className="portfolio-managment-input-block">
                    <label htmlFor="Year" className="form-field-label small">
                      Year
                    </label>
                    <select
                      id="Year"
                      name="Year"
                      data-name="Year"
                      required
                      className="porfolio-management-select-input w-select"
                    >
                      <option value="" />
                      {Array.from(years).map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="portfolio-management-form-flex">
                  <div className="portfolio-managment-input-block first">
                    <label htmlFor="Pool" className="form-field-label small">
                      Pool
                    </label>
                    <select
                      id="Pool"
                      name="Pool"
                      data-name="Pool"
                      required
                      className="porfolio-management-select-input w-select"
                    >
                      <option value="" />
                      {pools.map((pool) => (
                        <option value={pool.id}>{pool.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="portfolio-managment-input-block first">
                    <label htmlFor="Change" className="form-field-label small">
                      Change <span className="gray-text">(%)</span>
                    </label>
                    <input
                      type="number"
                      className="text-input-field portfolio-management w-input"
                      maxLength={256}
                      name="Change"
                      data-name="Change(%)"
                      id="Change"
                      required
                    />
                  </div>
                </div>
                <div className="portfolio-management-form-flex">
                  <div className="portfolio-managment-input-block first">
                      <label htmlFor="Charge" className="form-field-label small">
                      Charged percentage <span className="gray-text">(%)</span>
                      </label>
                      <select
                      id="Charge"
                      name="Charge"
                      data-name="Charged percentage(%)"
                      required
                      className="porfolio-management-select-input w-select"
                    >
                      <option value="" />
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    </div>
                    <input
                      type="submit"
                      value={'Submit'}
                      defaultValue="Update"
                      className="button margin-bottom w-button"
                    />
                </div>
              </form>
            </div>
          </div>
        </Permission>
        <div>
          <div className="custom-input-wrapper">
            <div className="custom-text-input w-embed">
              {/* <input type="text" className="text-input-field custom" placeholder="Search" /> */}
            </div>
            <div className="filter-button-flex-parent">
              {/* <div className="w-embed">
                <select className="select-input custom">
                  <option value>Filter</option>
                </select>
              </div> */}
              <div className="dummy-block smaller" />
              <button onClick={(event) =>{
              event.preventDefault();
              exportRates();
            }} className="download-csv w-inline-block">
                <div>DOWNLOAD CSV</div>
              </button>
            </div>
          </div>
          <div className="investment-breakdown-header">
            <div className="content-block portfolio-managment">
              <div>Month</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Year</div>
            </div>
            <div className="content-block portfolio-management-pool">
              <div>Pool</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>User</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Percentage Change</div>
            </div>
            <Permission roles={['super-admin', 'admin']}>
              <div className="content-block portfolio-management-pool">
                <div>Action</div>
              </div>
            </Permission>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={rates.length=== 0 && getRatesCall.loading} />
            {rates.map((rate) => (
              <div key={rate.id} className="table-info-flex">
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Month</div>
                  </div>
                  <div>
                    <div>{rate.cycle.month}</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Year</div>
                  </div>
                  <div>
                    <div>{rate.cycle.year}</div>
                  </div>
                </div>
                <div className="column-content portfolio-managment-pool">
                  <div className="content-block mobile-header">
                    <div>Pool</div>
                  </div>
                  <div>
                    <div>{rate.pool.name}</div>
                  </div>
                  <div className="w-embed">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n\t.portfolio-managment-pool {\n  \tword-break: break-all\n  }\n',
                      }}
                    />
                  </div>
                </div>
                <div className="column-content amount portfolio-managment">
                  <div className="content-block mobile-header">
                    <div>User</div>
                  </div>
                  <div>
                    <div>All</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Percentage Change</div>
                  </div>
                  <div>
                    <div>{rate.rate}%</div>
                  </div>
                </div>
                <Permission roles={['super-admin', 'admin']}>
                  <div className="column-content portfolio-managment-pool ">
                    <div className="content-block mobile-header">
                      <div>Action</div>
                    </div>
                    <div className="action-flex auto justify-contents-space-evenly">
                      <Link className="action-button alone" to={`portfolio-management/rate/${rate.id}`}>
                        <div>Edit</div>
                      </Link>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeRate(rate.id);
                        }}
                        className="action-button alone delete">
                        <div>Delete</div>
                      </button>
                    </div>
                  </div>
                </Permission>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </div>
  );
}
