// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPool } from '../../store/actions/poolAction';
// import Loading from '../../components/Loading';
// import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
// import { createReferrals } from '../../services/referralsService';
import Notify from '../../util/Notify';
// import { getRate } from '../../services/rateService';
// import { useHistory } from 'react-router-dom';
import { updatePool } from '../../services/poolService';
import clsx from 'clsx';
import AutoCompleteUser from '../ContributionPool/AutoCompleteUser';
import { changePassword } from '../../services/userService';
import Permission from '../../components/Permission';

const initialEditState = {
  name: '',
  fee: '',
  duration: '',
  min_amount: '',
  currency: '',
  type: '',
  users: {},
};

export function EditPage() {
  const dispatch = useDispatch();

  const updatePoolCall = useService(updatePool);

  /**
   * @type {[Pool, Function]}
   */
  const [selectedPool, setSelectedPool] = useState(null);
  const [editFormData, setEditFormData] = useState(initialEditState);
  const [targetAudience, setTargetAudience] = useState('');
  const [emails, setEmails] = useState([]);

  /**
   *
   * @param {React.FormEvent} e
   */
  const submitUpdateForm = (e) => {
    e.preventDefault();
    updatePoolCall
      .call(selectedPool.id, {
        ...editFormData, audience:
          targetAudience === 'all_users'
            ? { type: 'all_users' }
            : { type: 'selected_users', emails: emails } })
      .onSuccess(() => {
        Notify.success('Pool updated successfully');
      })
      .onError((e) => Notify.error(e.message));
  };

  const getUserObject = (userObj) => {
    const usersEmail = userObj.map((user) => user.email)
    setEmails(usersEmail)
  }

  /**
   *
   * @param {'name'|'fee'|'duration'|'min_amount'|'currency'} inputName
   * @param {string} inputData
   */
  const updateInputData = (inputName, inputData) => {
    setEditFormData({
      ...editFormData,
      [inputName]: inputData,
    });
  };

  const { pools, loading: poolLoading } = useSelector((state) => state.pool);

  const customPools = pools.filter((pool)=> pool.type === 'custom');
  // const history = useHistory();

  

  // const getRateCall = useService(getRate);
  /**
   * @type {Rate}
   */
  // const rate = getRateCall.data;

  useEffect(() => {
    dispatch(fetchPool());
  }, [dispatch]);

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Edit Information</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <Permission roles={['super-admin']}>
          <div className="dashboard-content-wrapper messages half">
            <div className="dashboard-content-wrapper-top-border-wrapper">
              <div className="smaller-border-line" />
              <div className="dashboard-content-heading flex">
                <div className="margin-right">Pool</div>
              </div>
              <div className="extender-border" />
            </div>
            <div className="dashboard-content-wrapper-inner">
              <div className="recent-transaction-card edit-information">
                <div className="w-form">
                  <form name="email-form" data-name="Email Form" onSubmit={submitUpdateForm}>
                    <div className="portfolio-management-form-flex edit-information">
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="pool" className="form-field-label small">
                          Select Custom Pool
                        </label>
                        <select
                          id="pool"
                          name="pool"
                          data-name="pool"
                          required
                          className="porfolio-management-select-input w-select"
                          onChange={(event) => {
                            const poolId = event.target.value;
                            const pool = pools.find((pool) => pool.id === Number(poolId));
                            setEditFormData({
                              name: pool.name,
                              fee: String(pool.fee),
                              duration: String(pool.duration),
                              min_amount: String(pool.min_amount),
                              currency: String(pool.currency),
                              type: String(pool.type),
                              users: pool.users
                            });
                            setSelectedPool(pool);
                            setTargetAudience(pool.users.type)
                            setEmails(pool.users.users)
                          }}
                        >
                          <option value="" disabled selected key="default">
                            {poolLoading ? 'Loading...' : ''}
                          </option>
                          {customPools.map((pool) => (
                            <option key={pool.id} value={pool.id}>
                              {pool.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="Membership-Fee" className="form-field-label small">
                          Pool Name
                          <span className="gray-text" />
                        </label>
                        <input
                          onChange={(e) => updateInputData('name', e.target.value)}
                          type="text"
                          className="text-input-field portfolio-management w-input"
                          name="Membership-Fee"
                          data-name="Membership Fee"
                          id="Membership-Fee"
                          required
                          value={editFormData.name}
                        />
                      </div>
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="Membership-Fee" className="form-field-label small">
                          Membership fee
                          <span className="gray-text" />
                        </label>
                        <input
                          onChange={(e) => updateInputData('fee', e.target.value)}
                          type="number"
                          className="text-input-field portfolio-management w-input"
                          name="Membership-Fee"
                          data-name="Membership Fee"
                          id="Membership-Fee"
                          required
                          value={editFormData.fee}
                        />
                      </div>
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="duration" className="form-field-label small" style={{ textAlign: 'left' }}>
                          Currency
                        </label>
                        <select
                          onChange={(e) => updateInputData('currency', e.target.value)}
                          value={editFormData.currency}
                          className="text-input-field portfolio-management w-input"
                          name="target_audience"
                          data-name="Target Audience"
                          id="target_audience"
                          required>
                          <option selected disabled value="">Select an option</option>
                          <option value="dollars">Dollars</option>
                          <option value="naira">Naira</option>
                        </select>
                      </div>
                    </div>
                    <div className="portfolio-management-form-flex edit-information">
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="Membership-Fee" className="form-field-label small">
                          Min Investment Amount
                          <span className="gray-text" />
                        </label>
                        <input
                          onChange={(e) => updateInputData('min_amount', e.target.value)}
                          type="number"
                          className="text-input-field portfolio-management w-input"
                          name="Membership-Fee"
                          data-name="Membership Fee"
                          id="Membership-Fee"
                          required
                          value={editFormData.min_amount}
                        />
                      </div>
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="Duration-Months" className="form-field-label small">
                          Duration <span className="gray-text">(Months)</span>
                        </label>
                        <input
                          onChange={(e) => updateInputData('duration', e.target.value)}
                          type="number"
                          className="text-input-field portfolio-management w-input"
                          name="Duration-Months"
                          data-name="Duration (Months)"
                          id="Duration-Months"
                          required
                          value={editFormData.duration}
                        />
                        <input
                          type="text"
                          className="text-input-field portfolio-management w-input hide"
                          name="Duration-Months"
                          data-name="Duration (Months)"
                          id="Duration-Months"
                          required
                          defaultValue={editFormData.type}
                        />
                      </div>
                      <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="duration" className="form-field-label small" style={{textAlign: 'left'}}>
                          Target Audience
                        </label>
                        <select
                          onChange={(e) => {
                            setTargetAudience(e.target.value);
                          }}
                          value={targetAudience}
                          defaultValue={editFormData.users.type}
                          className="text-input-field portfolio-management w-input"
                          name="target_audience"
                          data-name="Target Audience"
                          id="target_audience"
                          required>
                          <option selected disabled value="">Select an option</option>
                          <option value="all_users"> All Users</option>
                          <option value="selected_users">Selected Users</option>
                        </select>
                      </div>
                      {targetAudience === 'selected_users' ? (
                        <div className="portfolio-managment-input-block edit-information">
                          <label htmlFor="duration" className="form-field-label small">
                            Selected Users
                          </label>
                          <div>
                            <AutoCompleteUser
                              label="Select Users"
                              isMultiple
                              handleSelectedUser={(users) => {
                                getUserObject(users)
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="portfolio-managment-input-block edit-information">
                        <label htmlFor="currency" className="form-field-label small">
                          Select Currency
                        </label>
                        <select
                          id="currency"
                          name="currency"
                          data-name="currency"
                          required
                          value={editFormData.currency}
                          className="porfolio-management-select-input w-select"
                          onChange={(event) => {
                            const currency = event.target.value;
                            updateInputData('currency', currency);
                          }}
                        >
                          <option value=""></option>
                          <option value="naira">Naira (₦)</option>
                          <option value="dollars">Dollars ($)</option>
                        </select>
                      </div> */}
                    </div>
                    <div className="submit-button-flex">
                      <input
                        type="submit"
                        defaultValue="Update"
                        data-wait="Please wait..."
                        className="button w-button"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Permission>
        <div className="dashboard-content-wrapper messages half last">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Account</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <UpdatePassword />
          </div>
        </div>
      </div>
    </div>
  );
}

const passwordFormDefaultState = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};
function UpdatePassword() {
  const changePasswordCall = useService(changePassword);

  const [passwordFormData, setPasswordFormData] = useState(passwordFormDefaultState);

  const passwordMatch = passwordFormData.new_password === passwordFormData.confirm_password;

  /**
   *
   * @param {'old_password'|'new_password'|'confirm_password'} inputName
   * @param {string} inputData
   */
  const updateInputData = (inputName, inputData) => {
    setPasswordFormData({
      ...passwordFormData,
      [inputName]: inputData,
    });
  };
  /**
   *
   * @param {React.FormEvent} e
   */
  const submitPasswordForm = (e) => {
    e.preventDefault();
    const old = passwordFormData.old_password;
    const new_password = passwordFormData.new_password;
    const new_confirmation = passwordFormData.confirm_password;
    if (!passwordMatch) {
      Notify.error("Password does not match");
      return;
    }
    changePasswordCall
      .call({ old, new: new_password, new_confirmation })
      .onSuccess(() => {
        Notify.success("Password changed successfully!");
        setPasswordFormData(passwordFormDefaultState);
      })
      .onError((e) => Notify.error(e.message));
  };



  return (
    <div className="recent-transaction-card edit-information">
      <div className="w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          onSubmit={submitPasswordForm}
        >
          <div className="portfolio-management-form-flex edit-information">
            <div className="portfolio-managment-input-block edit-information">
              <label htmlFor="Old-Password" className="form-field-label small">
                Old Password
              </label>
              <input
                onChange={(e) => updateInputData('old_password', e.target.value)}
                value={passwordFormData.old_password}
                type="password"
                className="text-input-field portfolio-management w-input"
                name="Old-Password"
                data-name="Old Password"
                id="Old-Password"
                required
                minLength={8}
              />
            </div>
          </div>
          <div className="portfolio-management-form-flex edit-information">
            <div className="portfolio-managment-input-block edit-information">
              <label htmlFor="New-Password" className="form-field-label small">
                New Password
                <span className="gray-text" />
              </label>
              <input
                onChange={(e) => updateInputData('new_password', e.target.value)}
                value={passwordFormData.new_password}
                type="password"
                className="text-input-field portfolio-management w-input"
                name="New-Password"
                data-name="New Password"
                id="New-Password"
                required
                minLength={8}
              />
            </div>
            <div className="portfolio-managment-input-block edit-information">
              <label htmlFor="Confirm-Password" className="form-field-label small">
                Confirm Password
                <span className="gray-text" />
              </label>
              <input
                onChange={(e) => updateInputData('confirm_password', e.target.value)}
                value={passwordFormData.confirm_password}
                type="password"
                className="text-input-field portfolio-management w-input"
                name="Confirm-Password"
                data-name="Confirm Password"
                id="Confirm-Password"
                required
                minLength={8}
              />
              {!passwordMatch ? <div className="color-red">Password does not match</div> : null}
            </div>
          </div>

          <div className="submit-button-flex">
            <input
              type="submit"
              defaultValue="Update"
              data-wait="Please wait..."
              className={clsx('button w-button', { 'btn-disabled': !passwordMatch })}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
