import React, { useState, useEffect } from 'react';
import api from '../../util/api';
import { appConstant } from '../../util/constant';
import Loading from '../../components/Loading';
import { useDebounce } from '../../hooks/useDebounce';

const getInterests = (arr) => {
  const arrToUse = arr ?? [];
  return arrToUse.reduce((prev, curr, index) => {
    return index + 1 === arrToUse.length ? `${prev}${curr}` : `${prev}${curr}, `;
  }, '');
};

const Members = () => {
  const membersList = useState([]);
  const isFetching = useState(true);
  const searchValue = useState('');
  const deferred = useDebounce(searchValue[0]);

  const hasNodata = !isFetching[0] && membersList[0].length === 0;
  const hasData = !isFetching[0] && membersList[0].length > 0;

  const handleSearch = (e) => {
    searchValue[1](e.target.value);
  };

  const fetchMembers = (search) => {
    const queryParam = !!search ? `?keyword=${search}` : '';
    isFetching[1](true);
    api
      .get(`${appConstant.baseUrl}/community/members${queryParam}`)
      .then((response) => {
        membersList[1](response?.data ?? []);
      })
      .catch(() => {})
      .finally(() => {
        isFetching[1](false);
      });
  };

  useEffect(() => {
    fetchMembers(deferred);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferred]);

  return (
    <>
      <div className="cmnt_member_wrapper">
        <section className="cmnt_member_filter_block">
          {/* <div className="cmnt_member_item_flex">
            <p className="cmnt_member_textStyle gray">Filter by</p>
            <div className="cmnt_member_item_flex cmnt_member_item_margin">
              <p className="cmnt_member_textStyle">Industry</p>
              <img
                src="/assets/images/funnel-icon.svg"
                className="cmnt_member_item_image cmnt_member_item_margin"
                alt=""
              />
            </div>
            <div className="cmnt_member_item_flex cmnt_member_item_margin">
              <p className="cmnt_member_textStyle">Interests</p>
              <img
                src="/assets/images/funnel-icon.svg"
                className="cmnt_member_item_image cmnt_member_item_margin"
                alt=""
              />
            </div>
            <div className="cmnt_member_item_flex cmnt_member_item_margin">
              <p className="cmnt_member_textStyle">Location</p>
              <img
                src="/assets/images/funnel-icon.svg"
                className="cmnt_member_item_image cmnt_member_item_margin"
                alt=""
              />
            </div>
          </div> */}
          <div>
            <input
              value={searchValue[0]}
              type="text"
              onChange={handleSearch}
              className="cmnt_member_search"
              placeholder="Search by name, industry, interest or location"
              name="search"
              id="search"
            />
          </div>
        </section>
        <section className="cmnt_member_table_section">
          <div className="cmnt_member_cell_grid gold">
            <div className="cmnt_member_cell_padding">
              <p class="cmnt_member_textStyle heading hide_on_mobile">Name</p>
              <p class="cmnt_member_textStyle heading hide_on_desktop">Members Information</p>
            </div>
            <div className="cmnt_member_cell_padding hide_on_mobile">
              <p className="cmnt_member_textStyle heading">Designation/Role</p>
            </div>
            <div className="cmnt_member_cell_padding hide_on_mobile">
              <p className="cmnt_member_textStyle heading">Industry</p>
            </div>
            <div className="cmnt_member_cell_padding hide_on_mobile">
              <p className="cmnt_member_textStyle heading">Interests</p>
            </div>
            <div className="cmnt_member_cell_padding hide_on_mobile">
              <p className="cmnt_member_textStyle heading">Location</p>
            </div>
            {/* <div className="cmnt_member_cell_padding hide_on_mobile">
              <p className="cmnt_member_textStyle heading">Action</p>
            </div> */}
          </div>
          <div className="cmnt_member_table_section">
            <div className="cmnt_member_table_scroll">
              {hasNodata && <div className="cmnt_member_table_empty">No member available</div>}
              {isFetching[0] && <Loading isLoading={true} />}

              {hasData && (
                <>
                  {membersList[0].map((member, index) => {
                    return <CommunityTableRow key={`memberList${index}`} data={member} />;
                  })}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Members;

function CommunityTableRow({ data }) {
  return (
    <div className="cmnt_member_cell_grid white">
      <div class="cmnt_member_cell_padding cmnt_flex_1 hide_on_desktop">
        <p class="cmnt_member_mobile_flex">
          <span class="cmnt_member_mobile_left cmnt_member_textStyle">Name:</span>
          <span class="cmnt_member_mobile_right cmnt_member_textStyle gray">
            {data.user.fname} {data.user.lname}
          </span>
        </p>
        <p class="cmnt_member_mobile_flex">
          <span class="cmnt_member_mobile_left cmnt_member_textStyle">Designation:</span>
          <span class="cmnt_member_mobile_right cmnt_member_textStyle gray">{data.role}</span>
        </p>
        <p class="cmnt_member_mobile_flex">
          <span class="cmnt_member_mobile_left cmnt_member_textStyle">Industry:</span>
          <span class="cmnt_member_mobile_right cmnt_member_textStyle gray">{data.industry}</span>
        </p>
        <p class="cmnt_member_mobile_flex">
          <span class="cmnt_member_mobile_left cmnt_member_textStyle">Interests:</span>
          <span class="cmnt_member_mobile_right cmnt_member_textStyle gray">
            {getInterests(data.interests)}
          </span>
        </p>
        <p class="cmnt_member_mobile_flex">
          <span class="cmnt_member_mobile_left cmnt_member_textStyle">Location:</span>
          <span class="cmnt_member_mobile_right cmnt_member_textStyle gray">
            Location: {data.user.city}, {data.user.country}
          </span>
        </p>
      </div>

      <div className="cmnt_member_cell_padding hide_on_mobile">
        <p className="cmnt_member_textStyle">
          {data.user.fname} {data.user.lname}
        </p>
      </div>
      <div className="cmnt_member_cell_padding hide_on_mobile">
        <p className="cmnt_member_textStyle">{data.role}</p>
      </div>
      <div className="cmnt_member_cell_padding hide_on_mobile">
        <p className="cmnt_member_textStyle">{data.industry}</p>
      </div>
      <div className="cmnt_member_cell_padding hide_on_mobile">
        <p className="cmnt_member_textStyle">{getInterests(data.interests)}</p>
      </div>
      <div className="cmnt_member_cell_padding hide_on_mobile">
        <p className="cmnt_member_textStyle">
          {data.user.city}, {data.user.country}
        </p>
      </div>
      {/* <div className="cmnt_member_cell_padding">
        <div className="cmnt_member_action_container">
          <Action
            variant="black"
            options={[
              {
                name: 'Send Message',
                onClick: () => {},
              },
            ]}
          />
        </div>
      </div> */}
    </div>
  );
}
