import React from 'react';
import './index.css';

/**
 *
 * @param {{isLoading:boolean}} props
 */
export default function Loading(props) {
  if (props.isLoading) {
    return (<div className='loader-container'><div className="loadingio-spinner-spinner-fox165sqzi"><div className="ldio-mvk9yu39ej8">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div></div>);
  }
  return null;
}
