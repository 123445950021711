import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { getCmsPosts, saveCmsPost } from '../../services/cmsService';
import { FaqBodyEditor } from './FaqBodyEditor';
import { RegularBodyEditor } from './RegularBodyEditor';


import { GettingStartedEditor } from './GettingStartedEditor';
import { OurPeopleEditor } from './OurPeopleEditor';
import { VolitionGrowthEditor } from './VolitionGrowthEditor';
import { VolitionExtraEditor } from './VolitionExtraEditor';
import { CareerBodyEditor } from './CareerEditor'



export //const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

const defaultCmsFormState = {
  title: '',
  slug: '',
  content: { body: '' },
};
export function CmsPost() {
  const dispatch = useDispatch();
  const saveCmsPostCall = useService(saveCmsPost);

  const getCmsPostsCall = useService(getCmsPosts);

  /**
   * @type {CmsPost[]}
   */
  const posts = Object.values(getCmsPostsCall?.data ?? {});

  /**
   * @type {number}
   */
  // const total = getCmsPostsCall?.data?.total ?? 0;

  useEffect(() => {
    getCmsPostsCall.call().onError((e) => Notify.error(e.message));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /**
   * @type {[CmsPost, Function]}
   */

  const [cmsPostForm, setCmsPostForm] = useState(defaultCmsFormState);

  /**
   *
   * @param {'title'|'content'|'slug'} inputName
   * @param {string|{[key:string]:any}} inputData
   */
  const updateCmsPostForm = (inputName, inputData) => {
    setCmsPostForm({
      ...cmsPostForm,
      [inputName]: inputData,
    });
  };

  console.log(cmsPostForm);

  const submitCmsPost = (e) => {
    e.preventDefault();

    console.log(cmsPostForm);
    saveCmsPostCall
      .call(cmsPostForm)
      .onSuccess(() => {
        Notify.success('Post saved successfully!');

        getCmsPostsCall.call().onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Content Management</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="portolio-management-form-block">
          <div className="w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              onSubmit={submitCmsPost}
            >
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Subject" className="form-field-label">
                    Content Category
                    <span className="gray-text" />
                  </label>
                  <select
                    className="text-input-field portfolio-management w-select"
                    name="postType"
                    data-name="postType"
                    id="postType"
                    required
                    defaultValue=""
                    onChange={(e) => {
                      const slug = e.target.value;
                      const post = posts.find((post) => post.slug === slug);

                      //Call this first because of the issue with the title
                      updateCmsPostForm('slug', '');
                      requestAnimationFrame(() => {
                        setCmsPostForm({
                          title: post.title,
                          slug: post.slug,
                          content: post.content,
                        });
                      });
                    }}
                  >
                    <option disabled selected value="">
                      Select Category
                    </option>
                    {posts.map((post) => (
                      <option key={post.id} value={post.slug}>
                        {post.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {cmsPostForm.slug ? (
                <>
                  <div>
                    <div className="portfolio-managment-input-block full">
                      <label htmlFor="Subject" className="form-field-label">
                        Heading
                        <span className="gray-text" />
                      </label>
                      <input
                        value={cmsPostForm.title}
                        onChange={(e) => updateCmsPostForm('title', e.target.value)}
                        type="text"
                        className="text-input-field portfolio-management w-input"
                        name="Title"
                        data-name="Title"
                        id="Title"
                        required
                      />
                    </div>
                  </div>
                  {['terms-and-conditions', 'cooperative-bye-laws', 'privacy-policy'].includes(
                    cmsPostForm.slug,
                  ) ? (
                    <RegularBodyEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}
                  {['faq-dashboard', 'faq-homepage'].includes(cmsPostForm.slug) ? (
                    <FaqBodyEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}

                  {cmsPostForm.slug === 'careers' ? (
                    <CareerBodyEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}

                  {cmsPostForm.slug === 'getting-started' ? (
                    <GettingStartedEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}

                  {cmsPostForm.slug === 'our-people' ? (
                    <OurPeopleEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}

                  {cmsPostForm.slug === 'volition-growth-page' ? (
                    <VolitionGrowthEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}

                  {cmsPostForm.slug === 'volition-extra-page' ? (
                    <VolitionExtraEditor
                      updateCmsPostForm={updateCmsPostForm}
                      cmsPostForm={cmsPostForm}
                    />
                  ) : null}
                </>
              ) : null}
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Save"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}


