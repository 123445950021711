/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { getBlueById } from '../../services/blueService';

export function VolitionBlueContent() {
  const history = useHistory();
  // @ts-ignore
  /**
   * @type {{id:string}}
   */
  const params = useParams();
  const volitionBlueId = Number(params.id);

  const getBlueCall = useService(getBlueById);

  useEffect(() => {
    getBlueCall.call(volitionBlueId).onError((e) => Notify.error(e.message));
  }, [volitionBlueId]);

  /**
   * @type {VolitionExtra}
   */
  const volitionBlue = getBlueCall.data ?? {};

  return (
    <div>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      {getBlueCall.loading ? (
        <Loading isLoading />
      ) : (
        <div>
          <h1>{volitionBlue.title}</h1>
          <div
            style={{ marginTop: '30px' }}
            dangerouslySetInnerHTML={{ __html: volitionBlue.body }}
          ></div>
        </div>
      )}
    </div>
  );
}
