import React, { useContext } from "react";
import { useForm } from "../../../node_modules/react-hook-form/dist/index";

export const FormContext = React.createContext(null);
export const FormProvider = FormContext.Provider;
export const FormConsumer = FormContext.Consumer;

/**
 * Assign the project to an props.
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.type
 */
export function Input(props) {
  const form = useContext(FormContext);
  return (
    <input
      type={props.type || "text"}
      className="text-input-field w-input"
      maxLength={256}
      name={props.name}
      data-name={props.name}
      placeholder=""
      id={props.name}
      ref={form.register({
        required: {
          value: true,
          message: "This field is required!",
        },

      })}
    />
  );
}

/**
 * Assign the project to an props.
 * @param {Object} props
 * @param {string} props.name
 */
export function ErrorMessage(props) {
  const form = useContext(FormContext);
  const error = form.errors[props.name];
    
  if (!error) {
    return null;
  }
  console.log(error);
  return (
    <div className={error ? "color-red" : ""}>{error.message}</div>
  );
}

// {error ? error: "This field is required!"}
/**
 * Assign the project to an props.
 * @param {Object} props
 * @param {(data:object)=>void} props.onSubmit
 * @param {React.ReactNode} props.children
 */
export function Form(props) {
  const form = useForm();

  return (
    <FormProvider value={form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>{props.children}</form>
    </FormProvider>
  );
}
