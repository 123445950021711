import React from 'react';
import ReactQuill from 'react-quill';
import { modules, formats } from './quill_config';


export const VolitionGrowthEditor = ({ updateCmsPostForm, cmsPostForm }) => {
  const updateGettingStarted = (key, value) => {
    const newCmsPostForm = { ...cmsPostForm.content.body };
    newCmsPostForm[key] = value;

    updateCmsPostForm('content', { body: newCmsPostForm });
  };

  return (
    <div>
      <br />
      <br />
      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Volition Growth Writeup
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('growth_writeup', html)}
            value={cmsPostForm.content.body.growth_writeup ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>

      <div className="portfolio-management-form-flex space-between">
        <div className="portfolio-managment-input-block full">
          <label htmlFor="Message" className="form-field-label">
            Growth Pay in Dollar Writeup
            <span className="gray-text" />
          </label>
          <ReactQuill
            onChange={(html) => updateGettingStarted('growth_pay_in_dollar_writeup', html)}
            value={cmsPostForm.content.body.growth_pay_in_dollar_writeup ?? ''}
            modules={modules}
            formats={formats}
            theme="snow" />
        </div>
      </div>
    </div>
  );
};
