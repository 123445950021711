/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import useService from '../../util/useService';
import {  updateBlue } from '../../services/blueService';
import Notify from '../../util/Notify';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getBlueById } from '../../services/blueService';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

const defaultBlueFormState = {
  title: '',
  body: '',
  accept_message: 1,
};

export function VolitionBlueEdit() {
  const history = useHistory();
  const updateBlueCall = useService(updateBlue);

  /**
   * @type {[VolitionExtra, Function]}
   */

  const [newBlue, setBlueData] = useState(defaultBlueFormState);

  /**
   *
   * @param {'title'|'body'} inputName
   * @param {string} inputData
   */
  const updateBlueData = (inputName, inputData) => {
    setBlueData({
      ...newBlue,
      [inputName]: inputData,
    });
  };

  // @ts-ignore
  /**
   * @type {{id:string}}
   */
  const params = useParams();
  const volitionBlueId = Number(params.id);

  const getBlueCall = useService(getBlueById);

  useEffect(() => {
    getBlueCall
      .call(volitionBlueId)
      .onSuccess((data) => {
        updateBlueData('title', data.title);
        updateBlueData('body', data.body);

        setBlueData({
          title: data.title,
          body: data.body,
        });
      })
      .onError((e) => Notify.error(e.message));
  }, [volitionBlueId]);

  const sendBlue = (e) => {
    e.preventDefault();

    updateBlueCall
      .call(volitionBlueId, newBlue)
      .onSuccess(() => {
        Notify.success('Extra post updated successfully');
        history.goBack()
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div>
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      {getBlueCall.loading ? (
        <Loading isLoading />
      ) : (
        <div className="portolio-management-form-block" style={{marginTop: '0px'}}>
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" onSubmit={sendBlue}>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Subject" className="form-field-label">
                    Title
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newBlue.title}
                    onChange={(e) => updateBlueData('title', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Title"
                    data-name="Title"
                    id="Title"
                    required
                  />
                </div>
              </div>
              <div className="portfolio-management-form-flex space-between">
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Message" className="form-field-label">
                    Body
                    <span className="gray-text" />
                  </label>
                  <CKEditor
                    config={{
                      ckfinder: {
                        uploadUrl: baseUrl + '/image/upload',
                      },
                      height: '200px'
                      // plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
                      // toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                    }}
                    editor={ClassicEditor}
                    data={newBlue.body}
                    onChange={(event, editor) => {
                      updateBlueData('body', editor.getData());
                    }}
                  />
                </div>
              </div>
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Update"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
