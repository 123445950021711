import api from '../util/api';
import Notify from '../util/Notify';
import download from '../util/download';
//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

export function createPool(pool){
  return api.post(`${baseUrl}/pools`, {...pool});
}


/**
 *
 * @param {number} poolId
 */
export function getPoolById(poolId){
  return api.get(`${baseUrl}/pools/${poolId}`);
}


export function getPoolOverview(){
  return api.get(`${baseUrl}/pools/overview`);
}

export function updatePool(poolId, pool){
  return api.put(`${baseUrl}/pools/update`, { ...pool, id: poolId });
}

/**
 *
 * @param {number} userId
 */
export function getUserPools(userId){
  return api.get(`${baseUrl}/pools/user/${userId}`);
}

/**
 *
 * @param {{[key:string]:string|number}} filter
 */
export function exportPortfolios(filter){
  return api.get(`${baseUrl}/portfolio/export`,{
    params: {...filter}
  }).then(function(response){
    download('portfolios.csv', response);
    Notify.success("Portfolio data exported successfully");
  }).catch(function(err){
    Notify.error(err.message);
  });
}

export function addPortfolio(portfolio){
  return api.post(`${baseUrl}/portfolio`, {...portfolio});
}

export function updatePoolMembership(membership){
  return api.post(`${baseUrl}/memberships`, {...membership});
}

export function updatePortfolio(portfolioId, portfolio){
  return api.put(`${baseUrl}/portfolio/${portfolioId}`, {...portfolio});
}

/**
 *
 * @param {number} portfolioId
 */
export function deletePortfolio(portfolioId){
  return api.delete(`${baseUrl}/portfolio/${portfolioId}`);
}


/**
 *
 * @param {number} portfolioId
 */
export function getPortfolio(portfolioId){
  return api.get(`${baseUrl}/portfolio/${portfolioId}`);
}
