/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
// @ts-ignore
import clsx from 'clsx';
import Notify from '../../util/Notify';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const months = new Set();
let currentMonth = (new Date().getMonth()) + 1;
if (currentMonth === 12) {
  currentMonth = 0;
}
for (let month = currentMonth; month < 12; month++) {
  months.add(monthNames[month]);
}
for (let month = 0; month < currentMonth; month++) {
  months.add(monthNames[month]);
}


export function SendStatement() {
  const history = useHistory();
  /**
   * @type {{id:string}}
   */
  const param = useParams();

  const userId = Number(param.id);

  const [sendStatememntLoading, setSendStatememntLoading] = useState(false);


  /**
   * @type {[Pool, Function]}
   */



  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const sendStatementCB = (e) => {
    e.preventDefault();
    // @ts-ignore
    setSendStatememntLoading(true);

    const reqBody = {
      user_id: userId
    };

    console.log(reqBody);

    fetch('https://api.volitioncap.com/api/users/send-transactions-statement', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        history.goBack();
        Notify.success('Statement Sent Successfully!');
      })
      .catch((error) => {
        Notify.error(error.message)
      });
    setSendStatememntLoading(false);
  };

  return (
    <div className="pool-section">
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Send Statement</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Statement Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form
                  // @ts-ignore
                  onSubmit={sendStatementCB} id="email-form" name="email-form" data-name="Email Form">
                  <h3>Send Volition Statement to this user.</h3>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={sendStatememntLoading ? 'Please wait...' : 'Send Statement'}
                      className={clsx('button w-button')}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
