import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatMoney from '../../util/formatMoney';
import Loading from '../../components/Loading';
import format from 'date-fns/format';
import { Paginator } from '../../components/Paginator';
import { fetchTransaction } from '../../store/actions/transactionAction';
import {
  exportTransactions,
  resendTransactionReceipt,
  downloadTransactionReceipt,
  deleteTransaction,
} from '../../services/transactionService';
import { Link, useLocation } from 'react-router-dom';
import getQueryParams from '../../util/getQueryParams';

import DatePicker from 'react-date-picker';
import useRouterQuery from '../../util/useRouterQuery';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import Dialog from '../../util/Dialog';
import scrollToTop from '../../util/scrollToTop';
import Permission from '../../components/Permission';

const removeNullItems = (items) => {
  const obj = {};
  Object.keys(items)
    .filter((key) => items[key])
    .forEach((key) => (obj[key] = items[key]));
  return obj;
};

export function TransactionHistory() {
  const dispatch = useDispatch();
  const location = useLocation();
  const routerQuery = useRouterQuery();

  const [dateFilterStart, dateFilterStartChange] = useState(null);
  const [dateFilterEnd, dateFilterEndChange] = useState(null);

  const query = useMemo(() => removeNullItems(getQueryParams(location.search)), [location.search]);

  const { total, loading, transactions } = useSelector((state) => state.transaction);
  const [page, setPage] = useState(1);
  const [search] = useState('');

  const deleteTransactionCaller = useService(deleteTransaction);

  useEffect(() => {
    dispatch(
      fetchTransaction(page, {
        ...query,
        startDate: dateFilterStart?.toJSON().split('T')[0],
        endDate: dateFilterEnd?.toJSON().split('T')[0],
      }),
    );

    scrollToTop()
  }, [dispatch, page, search, query, dateFilterStart, dateFilterEnd]);


  const removeTransaction = async (id) => {
    if(!await Dialog.confirm(`Are you sure you want to delete this transaction?`)){
      return;
    }
    deleteTransactionCaller.call(id).onSuccess((data) => {
      Notify.success(data);

      dispatch(fetchTransaction(page, {
        ...query,
        startDate: dateFilterStart?.toJSON().split('T')[0],
        endDate: dateFilterEnd?.toJSON().split('T')[0],
      }));

    })
    .onError((e) => Notify.error(e.message))
  }

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Transaction History</h1>
          <div className="extended-heading-line custom-pools"></div>
          <div className="filter-button-flex-parent">
            {/* <div className="w-embed">
              <select className="select-input custom">
                <option value="">Filter</option>
              </select>
            </div> */}
            <Permission roles={['super-admin']}>
              <Link to="/transaction-history/create" className="button w-button">
                Create Transaction
              </Link>
              <div className="dummy-block smaller"></div>
            </Permission>
          </div>
        </div>
        <div className="filter-input-container">
          From &nbsp;&nbsp;&nbsp;&nbsp;
          <DatePicker
            maxDate={new Date()}
            className=""
            onChange={dateFilterStartChange}
            value={dateFilterStart}
          />
          &nbsp;&nbsp;&nbsp;&nbsp; to &nbsp;&nbsp;&nbsp;&nbsp;
          <DatePicker
            minDate={dateFilterStart}
            maxDate={new Date()}
            onChange={dateFilterEndChange}
            value={dateFilterEnd}
          />
          &nbsp;&nbsp; of type &nbsp;&nbsp;
          <select
            className="select-input custom no-caps"
            defaultValue={query.type}
            onChange={(e) => routerQuery.addQuery({ type: e.target.value })}
          >
            <option value="">All transactions</option>
            <option value="pool">Investment transactions</option>
            <option value="membership">Membership transactions</option>
            <option value="referral">Referral transactions</option>
            <option value="growth_membership">Growth memberships</option>
            <option value="growth_investment">Growth subscription</option>
            <option value="wallet_topup">Wallet transactions</option>
            <option value="payout">Payout</option>
            <option value="withdrawal">Withdrawal Transaction</option>
          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
            onClick={(event) => {
              event.preventDefault();
              exportTransactions({
                ...query,
                startDate: dateFilterStart?.toJSON().split('T')[0],
                endDate: dateFilterEnd?.toJSON().split('T')[0],
              });
            }}
            className="download-csv w-inline-block"
          >
            <div>DOWNLOAD CSV</div>
          </button>
          {/* <div className="search-input-tag w-embed">
            <input
              type="text"
              className="text-input-field custom"
              placeholder="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div> */}
        </div>
        <div className="parent-table-container overflow-scroll">
          <div className="investment-breakdown-header w-120">
            <div className="content-block transaction-history">
              <div>Date</div>
            </div>

            <div className="content-block transaction-history">
              <div>Reference</div>
            </div>
            <div className="content-block transaction-history extended-width">
              <div>User</div>
            </div>
            <div className="content-block transaction-history extended-width">
              <div>Email</div>
            </div>
            <div className="content-block transaction-history-decription extended-width">
              <div>Description</div>
            </div>
            <div className="content-block transaction-history">
              <div>amount</div>
            </div>
            <div className="content-block mic extended-width">
              <div>Action</div>
            </div>
          </div>
          <div className="investment-breakdown-block w-120">
            {transactions.map((transaction) => (
              <TransactionItem transaction={transaction} removeTransaction={removeTransaction} />
            ))}

            <Loading isLoading={transactions.length === 0 && loading} />
            {transactions.length === 0 && !loading ? (
              <div className="no-active-pool">
                <div>No available transactions</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}
/**
 *
 * @param {{transaction:Transaction, removeTransaction: Function}} param0
 */
function TransactionItem({ transaction, removeTransaction }) {
  return (
    <div className="table-info-flex">
      <div className="column-content transction-history">
        <div className="content-block mobile-header">
          <div>Date</div>
        </div>
        <div>
          <div>{format(new Date(transaction.created_at), 'd LLL yyyy')}</div>
        </div>
      </div>
      <div className="column-content transction-history">
        <div className="content-block mobile-header">
          <div>Reference</div>
        </div>
        <div>
          <div>{transaction.reference}</div>
        </div>
      </div>

      <div className="column-content transction-history extended-width">
        <div className="content-block mobile-header">
          <div>User</div>
        </div>
        <div>
          <div>
            {transaction.user.fname} {transaction.user.lname}
          </div>
        </div>
      </div>
      <div className="column-content transction-history extended-width">
        <div className="content-block mobile-header">
          <div>Email</div>
        </div>
        <div>
          <div>{transaction.user.email}</div>
        </div>
      </div>
      <div className="column-content transaction-history-description extended-width">
        <div className="content-block mobile-header">
          <div>DESCRIPTION</div>
        </div>
        <div>
          <div>{transaction.desc}</div>
        </div>
      </div>
      <div className="column-content amount transaction-history">
        <div className="content-block mobile-header">
          <div>Amount</div>
        </div>
        <div>
          <div>{formatMoney(transaction.amount, transaction.currency === 'NGN' ? '₦' : '$')}</div>
        </div>
      </div>
      <div className="column-content mic extended-width">
        <div className="content-block mobile-header">
          <div>Action</div>
        </div>
        <div className="action-flex auto justify-contents-space-evenly">
          <div
            className="action-button text-center transaction-btn"
            onClick={() => {
              resendTransactionReceipt(transaction.reference);
            }}
          >
            Resend Receipt
          </div>
          <div className="action-button text-center transaction-btn">
            <div
              onClick={() => {
                downloadTransactionReceipt(transaction.reference, transaction.user);
              }}
            >
              Download Receipt
            </div>
          </div>
          <Permission roles={['super-admin']}>
            <Link to={`transaction-history/${transaction.id}/edit`} className="action-button  text-center transaction-btn">
              <div>Edit</div>
            </Link>
            <div className="action-button text-center transaction-btn delete">
              <div onClick={() => {removeTransaction(transaction.id)}}>Delete</div>
            </div>
          </Permission>
        </div>
      </div>
    </div>
  );
}
