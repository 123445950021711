/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { Link } from 'react-router-dom';
import { saveGrowth, getGrowths, deleteGrowthById, toggleGrowthStatus } from '../../services/growthService';
import format from 'date-fns/format';
// import Truncate from 'react-truncate-html';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dialog from '../../util/Dialog';
import Permission from '../../components/Permission'

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

const defaultGrowthFormState = {
  title: '',
  duration: '',
  cost: 0,
  payout_frequency: '',
  slot: 0,
  currency: '',
  body: '',
  accept_message: 1
};
export function VolitionGrowth() {
  const dispatch = useDispatch();
  const sendGrowthCall = useService(saveGrowth);

  const [page, setPage] = useState(1);
  const getGrowthCall = useService(getGrowths);
  const deleteGrowthCall = useService(deleteGrowthById);
  const toggleGrowthCall = useService(toggleGrowthStatus);


  /**
   * @type {VolitionGrowth[]}
   */
  const posts = getGrowthCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getGrowthCall?.data?.total ?? 0;

  useEffect(() => {

  }, [dispatch]);

  useEffect(() => {
    getGrowthCall.call(page).onError((e) => Notify.error(e.message));
  }, [dispatch, page]);

  /**
   * @type {[VolitionGrowth, Function]}
   */

  const [newGrowth, setGrowthData] = useState(defaultGrowthFormState);

  /**
   *
   * @param {'title'|'body'|'duration'|'cost'|'payout_frequency'|'slot'|'currency'} inputName
   * @param {string} inputData
   */
  const updateGrowthData = (inputName, inputData) => {
    setGrowthData({
      ...newGrowth,
      [inputName]: inputData,
    });
  };

  const sendGrowth = (e) => {
    e.preventDefault();

    sendGrowthCall
      .call(newGrowth)
      .onSuccess(() => {
        Notify.success('Volition Growth post created');
        setGrowthData(defaultGrowthFormState);

        getGrowthCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {number} GrowthId
   */
  const deleteGrowth = async (GrowthId) => {
    if(!await Dialog.confirm("Are you sure you want to delete this post?")){
      return;
    }

    deleteGrowthCall
      .call(GrowthId)
      .onSuccess(() => {
        Notify.success('Growth post deleted successfully');
        getGrowthCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {number} GrowthId
   * @param {string} status
   */
  const toggleStatus = async (GrowthId, status) => {
    if(!await Dialog.confirm("Are you sure you want to hide this post?")){
      return;
    }

    toggleGrowthCall
      .call(GrowthId, status)
      .onSuccess(() => {
        Notify.success('Growth post hidden successfully');
        getGrowthCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };


  return <>
    <div className="pool-section">
      <Permission roles={['super-admin', 'admin']}>
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Volition Growth</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="portolio-management-form-block">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" onSubmit={sendGrowth}>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Title" className="form-field-label">
                    Title
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.title}
                    onChange={(e) => updateGrowthData('title', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Title"
                    data-name="Title"
                    id="Title"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Duration" className="form-field-label">
                    Duration (months)
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.duration}
                    onChange={(e) => updateGrowthData('duration', e.target.value)}
                    type="text"
                    className="text-input-field portfolio-management w-input"
                    name="Duration"
                    data-name="Duration"
                    id="Duration"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Amount" className="form-field-label">
                    Amount per slot
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.cost}
                    onChange={(e) => updateGrowthData('cost', e.target.value)}
                    type="number"
                    className="text-input-field portfolio-management w-input"
                    name="Amount"
                    data-name="Amount"
                    id="Amount"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Slots" className="form-field-label">
                    Available Slots
                    <span className="gray-text" />
                  </label>
                  <input
                    value={newGrowth.slot}
                    onChange={(e) => updateGrowthData('slot', e.target.value)}
                    type="number"
                    className="text-input-field portfolio-management w-input"
                    name="Slots"
                    data-name="Slots"
                    id="Slots"
                    required
                  />
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Currency" className="form-field-label">
                    Currency
                    <span className="gray-text" />
                  </label>
                  <select
                    className="text-input-field portfolio-management w-input"
                    value={newGrowth.currency}
                    onChange={(e) => updateGrowthData('currency', e.target.value)}
                    required
                    name="Currency"
                    data-name="Currency"
                    id="Currency">
                      <option disabled value="">Select an option</option>
                      <option value="naira">Naira</option>
                      <option value="dollar">Dollars</option>
                  </select>
                </div>
              </div>
              <div className="portfolio-management-form-flex space-between">
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Message" className="form-field-label">
                    Body
                    <span className="gray-text" />
                  </label>
                  <CKEditor
                    config={ {
                      ckfinder: {
                        uploadUrl: baseUrl + '/image/upload'
                    }
                      // plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
                      // toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
                  } }
                    editor={ ClassicEditor }
                    data={newGrowth.body}
                    onChange={ ( event, editor ) => {
                      updateGrowthData('body', editor.getData())
                    } }
                  />
                </div>
              </div>
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Create"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
      </Permission>
      <div>
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">All Growth Posts</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <div className="investment-breakdown-header all-messages">
          <div className="content-block all-messages">
            <div>Date</div>
          </div>
          <div className="content-block all-message-name">
            <div>Title</div>
          </div>
          <div className="content-block all-Growth-name">
            <div>Body</div>
          </div>
          <div className="content-block all-messages">
            <div>Actions</div>
          </div>
        </div>
        <div className="investment-breakdown-block">
          <Loading isLoading={getGrowthCall.loading && posts.length===0} />
          {posts.map((post, index) => (
            <div className="table-info-flex all-mesages" key={index}>
              <div className="column-content all-messages">
                <div className="content-block mobile-header">
                  <div>Date</div>
                </div>
                <div>
                  <div>{format(new Date(post.created_at), 'd LLL yyyy')}</div>
                </div>
              </div>
              <div className="column-content all-messages-name">
                <div className="content-block mobile-header">
                  <div>title</div>
                </div>
                <div>
                  <div>{post.title} </div>
                </div>
              </div>
              <div className="column-content all-Growth-name">
                <div className="content-block mobile-header">
                  <div>Body</div>
                </div>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: post.body}} className='html-preview-body-content'></div>
                </div>
              </div>
                <div className="column-content all-messages-name">
                  <div className="content-block mobile-header">
                    <div>Action</div>
                  </div>
                  <div className="action-flex auto left">
                    <div className="content-block mobile-header"></div>
                    <Link to={`/volition-growth/${post.id}`} className="action-button transaction-history">
                      <div>View</div>
                    </Link>
                    <Permission roles={['super-admin', 'admin']}>
                      <Link to={`/volition-growth/${post.id}/edit`}  className="action-button transaction-history">
                        <div>Edit</div>
                      </Link>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleStatus(post.id, post.status === 'active' ? 'inactive': 'active');
                        }}
                        disabled={toggleGrowthCall.loading}
                        className={`action-button transaction-history`}
                        >
                        <div>{post.status === 'active'? 'Hide': 'Show'}</div>
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          deleteGrowth(post.id);
                        }}
                        disabled={deleteGrowthCall.loading}
                        className={`action-button transaction-history delete`}
                        >
                        <div>Delete</div>
                      </button>
                    </Permission>
                  </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Paginator setPage={setPage} total={total} page={page} />
  </>;
}
