// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../store/actions/userAction';
import { payoutUsers, exportPayout, payoutOTP } from '../../services/userService';
import { useDebouncedEffect } from '../../util/useDebouncedEffect';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import { UserRow } from './UserRows';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import Dialog from '../../util/Dialog';
import alertify from 'alertifyjs';
import Permission from '../../components/Permission'

export function MaturedInvestments () {
  const dispatch = useDispatch();
  const checkRef = useRef()
  const { total, loading, users } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
 const [userList, setUserList] = useState({});
 const [showConfirm, setShowConfirm] = useState(false);
 const [confirmLoading, setConfirmLoading] = useState(false);
 const [search, setSearch] = useState('')
//  const [filterStatus, setFilterStatus] = useState('')

  const payoutUsersCaller = useService(payoutUsers)
  const payoutOtpCaller = useService(payoutOTP)

  useDebouncedEffect(() => {
    dispatch(fetchUsers(page, search, { wallet_not_empty: true }));
  }, 300, [page, search]);

  useEffect(() => {
    if(Object.values(userList).length) {
      setShowConfirm(true)
    } else {
      setShowConfirm(false)
    }
  }, [userList]);

  const handleSubmit = (otp) => {
    setConfirmLoading(true)
    const payload = {transfers: Object.values(userList), otp}
    payoutUsersCaller.call(payload)
      .onSuccess(() => {
        setConfirmLoading(false)
        setUserList({})
        dispatch(fetchUsers(page, '', {wallet_not_empty: true}));
        Notify.success('Users payouts confirmed successfully');
      })
      .onError((e) => {
        setConfirmLoading(false)
        Notify.error(e.message)
      });
  }

  const sendOtp = () => {
    payoutOtpCaller.call()
      .onSuccess(() => {
        Notify.success('An OTP has been sent your email');
      })
      .onError((e) => {
        setConfirmLoading(false)
        Notify.error(e.message)
      });
  }


  const handleChange = () => {
    if (checkRef.current.checked) {
      const userList = {}
      users.forEach((user) => {
        userList[user.id] = {
          user_id: user.id,
          amount: user.wallet.amount,
          reason: "Wallet withdrawal to bank account"
        }
      })
      setUserList(userList)
    } else {
      setUserList({})
    }
  }

  return (
      <>
      <div className="pool-section">
        <div>
          <div className="section-heading-block margin-bottom">
            <h1 className="brown-text">Matured Investments</h1>
            <div className="extended-heading-line custom-pools"></div>
            <div className="filter-button-flex-parent">
              {showConfirm && <button className="button mobile-mt-10 w-button" onClick={async () => {
                if(!await Dialog.confirm(`Are you sure you want to confirm this users?`)){
                  return;
                }
                sendOtp()
                alertify.prompt('Enter Otp', 'Please enter the OTP that was sent to your mail', '0000'
                  , function (_, value) {
                    handleSubmit(value)
                    }
                  , function () { 

                  });
              }} disabled={confirmLoading}>{confirmLoading ? 'Confirming payout' : 'Confirm'}</button>}
              <div className="dummy-block smaller"></div>
              <button onClick={(event) =>{
              event.preventDefault();
                exportPayout();
            }} className="download-csv w-inline-block">
              <div>DOWNLOAD CSV</div>
            </button>
            </div>
          </div>
        </div>
        <div className="custom-input-wrapper">
          <div className="search-input-tag w-embed">
            <input
              type="text"
              className="text-input-field custom"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="filter-button-flex-parent">
            {showConfirm && (
              <div className="margin-bottom p-2">
                {`${Object.values(userList).length} item(s) selected`}
              </div>
            )}
            {/* <div className="margin-bottom w-embed">
              <select className="select-input custom no-caps">
                <option value="">Filter Investments</option>
              </select>
            </div>
            <div className="dummy-block smaller"></div>
            <div className="w-embed">
              <select value={filterStatus} onChange={(e)=>setFilterStatus(e.target.value)} className="select-input custom no-caps dark-brown">
                <option value="">Sort date:</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
            </div> */}
          </div>
        </div>
        <div>
          <svg className="checkbox-symbol">
            <symbol id="check" viewBox="0 0 12 10">
              <polyline
                points="1.5 6 4.5 9 10.5 1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></polyline>
            </symbol>
          </svg>
          <div className="investment-breakdown-header">
            <Permission roles={['super-admin']}>
            <div className="content-block">
                <div>
                  <input type="checkbox" className="checkbox-input" id="selectAll" ref={checkRef} onChange={handleChange} />
                  <label className="checkbox" htmlFor="selectAll">
                    <span>
                      <svg width="12px" height="10px">
                        <use xlinkHref="#check"></use>
                      </svg>
                    </span>
                  </label>
                </div>
            </div>
            </Permission>
            <div className="content-block">
              <div>Date</div>
            </div>
            <div className="content-block" style={{ width: '20%' }}>
              <div>Name</div>
            </div>
            <div className="content-block" style={{ width: '30%' }}>
              <div>Email</div>
            </div>
            <div className="content-block">
              <div>Amount</div>
            </div>
            <div className="content-block">
              <div>Action</div>
            </div>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={loading && users.length === 0} />
            {users.map(user => (
              <UserRow user={user} key={user.id} setUserList={setUserList} isChecked={!!userList[user.id]} />
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
      </>
  );
}
