/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { deleteUser, getAdmins, updateUserStatus } from '../../services/userService';
import clsx from 'clsx';
import { createAdmin } from '../../services/userService';
import Dialog from '../../util/Dialog';
import Permission from '../../components/Permission'


export function AdminManagement() {

  const getAdminCall = useService(getAdmins);
  const createAdminCall = useService(createAdmin);
  const updateAdminStatusCall = useService(updateUserStatus);

  const deleteUserCall = useService(deleteUser);

  const [page, setPage] = useState(1);
  const [role, setRole] = useState("")
  const [desc] = useState({
    admin: 'An admin can perform all operations except payment related operations',
    support: 'A support can view all operations',
    'super-admin': 'A super admin can perform all operations incuding payment related operations'
  })

  /**
   * @type {User[]}
   */
  const admins = getAdminCall?.data?.data ?? [];
  /**
   * @type {number}
   */
  const total = getAdminCall?.data?.total ?? 0;

  useEffect(() => {
    getAdminCall.call(page).onError((e) => Notify.error(e.message));
  }, [page]);
  /**
   *
   * @param {number} userId
   * @param {string} status
   */
  const updateStatus = (userId, status) => {
    updateAdminStatusCall
      .call(userId, status)
      .onSuccess(() => {
        getAdminCall.call(page).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const sudmitAdminForm = (e) => {
    e.preventDefault();
    const form = e.target;
    // @ts-ignore
    const data = new FormData(form);
    const firstName = data.get('first_name');
    const lastName = data.get('last_name');
    const phoneNumber = data.get('phone_number');
    const email = data.get('email');
    const password = data.get('password');
    const passwordConfirmation = data.get('password_confirmation');
    const role = data.get('role');

    const payload = {
      fname: firstName,
      lname: lastName,
      phone: phoneNumber,
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      role: role
    };
    if (passwordConfirmation !== password) {
      return Notify.error('Password confirmation does not match');
    }

    createAdminCall
      .call(payload)
      .onSuccess(() => {
        getAdminCall.call(1).onError((e) => Notify.error(e.message));
        Notify.success(`${role} created successfully!`);
        // @ts-ignore
        form.reset();
      })
      .onError((e) => Notify.error(e.message));
  };

   /**
   *
   * @param {User} user
   */
  const removeUser = async (user) => {
    if(!await Dialog.confirm(`Are you sure you want to delete ${user.fname} ${user.lname}'s account?`)){
      return;
    }

    deleteUserCall
      .call(user.id)
      .onSuccess(() => {
        Notify.success('Admin account deleted successfully');
        getAdminCall.call(1).onError((e) => Notify.error(e.message));
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Admin Management</h1>
          <div className="extended-heading-line custom-pools" />
        </div>
        <Permission roles={['super-admin']}>
          <div className="portolio-management-form-block">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form
                  // @ts-ignore
                  onSubmit={sudmitAdminForm}
                  id="password_confirmation-form"
                  name="password_confirmation-form"
                  data-name="Email Form"
                >
                  <div className="portfolio-management-form-flex edit-information flex-direction-row">
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="first_name" className="form-field-label small">
                        First Name
                        <span className="gray-text" />
                      </label>
                      <input
                        type="text"
                        className="text-input-field portfolio-management w-input"
                        maxLength={20}
                        name="first_name"
                        data-name="First Name"
                        id="first_name"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="last_name" className="form-field-label small">
                        Last Name
                        <span className="gray-text" />
                      </label>
                      <input
                        type="text"
                        className="text-input-field portfolio-management w-input"
                        maxLength={20}
                        name="last_name"
                        data-name="Last Name"
                        id="last_name"
                        required
                      />
                    </div>
                  </div>

                  <div className="portfolio-management-form-flex edit-information flex-direction-row">
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="phone_number" className="form-field-label small">
                        Phone Number
                        <span className="gray-text" />
                      </label>
                      <input
                        type="number"
                        className="text-input-field portfolio-management w-input"
                        maxLength={15}
                        name="phone_number"
                        data-name="Phone Number"
                        id="phone_number"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="email" className="form-field-label small">
                        Email
                        <span className="gray-text" />
                      </label>
                      <input
                        type="email"
                        className="text-input-field portfolio-management w-input"
                        name="email"
                        data-name="Email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="role" className="form-field-label small">
                      Role
                      <span className="gray-text" />
                    </label>
                    <select
                      className="text-input-field portfolio-management w-input"
                      name="role"
                      data-name="role"
                      id="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required>
                          <option value="" disabled>Please select an option</option>
                          <option value="support">Support</option>
                          <option value="admin">Admin</option>
                          <option value="super-admin">Super admin</option>
                    </select>
                    <div className="portfolio-management edit-information small">{desc[role]}</div>
                  </div>

                  <div className="portfolio-management-form-flex edit-information flex-direction-row">
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="password" className="form-field-label small">
                        Password
                        <span className="gray-text" />
                      </label>
                      <input
                        type="password"
                        className="text-input-field portfolio-management w-input"
                        name="password"
                        data-name="Password"
                        id="password"
                        required
                      />
                    </div>
                    <div className="portfolio-managment-input-block edit-information first">
                      <label htmlFor="password_confirmation" className="form-field-label small">
                        Password Confirmation
                        <span className="gray-text" />
                      </label>
                      <input
                        type="password"
                        className="text-input-field portfolio-management w-input"
                        name="password_confirmation"
                        data-name="Password Confirmation"
                        id="password_confirmation"
                        required
                      />
                    </div>
                  </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={createAdminCall.loading ? 'Please wait...' : 'Create Admin'}
                      className={clsx('button w-button', {
                        'btn-disabled': createAdminCall.loading,
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Permission>
        <div>
          <div className="investment-breakdown-header">
            <div className="content-block portfolio-managment">
              <div>ID</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>First Name</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Last Name</div>
            </div>
            <div className="content-block portfolio-management-pool">
              <div>Email</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Phone Number</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Role</div>
            </div>
            <div className="content-block portfolio-managment">
              <div>Status</div>
            </div>
            <Permission roles={['super-admin']}>
              <div className="content-block portfolio-management-pool">
                <div>Action</div>
              </div>
            </Permission>
          </div>
          <div className="investment-breakdown-block">
            <Loading isLoading={getAdminCall.loading && admins.length === 0} />
            {admins.map((admin) => (
              <div key={admin.id} className="table-info-flex">
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>ID</div>
                  </div>
                  <div>
                    <div>{admin.id}</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>First Name</div>
                  </div>
                  <div>
                    <div>{admin.fname}</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Last Name</div>
                  </div>
                  <div>
                    <div>{admin.lname}</div>
                  </div>
                </div>
                <div className="column-content portfolio-managment-pool">
                  <div className="content-block mobile-header">
                    <div>Email</div>
                  </div>
                  <div>
                    <div>{admin.email}</div>
                  </div>
                  <div className="w-embed">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n\t.portfolio-managment-pool {\n  \tword-break: break-all\n  }\n',
                      }}
                    />
                  </div>
                </div>
                <div className="column-content amount portfolio-managment">
                  <div className="content-block mobile-header">
                    <div>Phone Number</div>
                  </div>
                  <div>
                    <div>{admin.phone}</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Role</div>
                  </div>
                  <div>
                    <div>{admin.role}</div>
                  </div>
                </div>
                <div className="column-content portfolio-management">
                  <div className="content-block mobile-header">
                    <div>Status</div>
                  </div>
                  <div>
                    <div>{admin.status}</div>
                  </div>
                </div>
                <Permission roles={['super-admin']}>
                  <div className="action-block users">
                    <div className="content-block mobile-header">
                      <div>Action</div>
                    </div>
                    <div className="action-flex auto left">
                      <button
                        className="action-button transaction-history"
                        onClick={async () =>{
                          if(!await Dialog.confirm(`Are you sure you want to perform this action?`)){
                            return;
                          }
                          updateStatus(admin.id, admin.status === 'active' ? 'inactive' : 'active')
                        }

                        }
                      >
                        {admin.status === 'active' ? <div>Deactivate</div> : <div>Activate</div>}
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeUser(admin)
                        }}
                        disabled={false}
                        className={`action-button transaction-history delete`}
                        >
                        <div>Delete</div>
                      </button>
                    </div>
                  </div>
                </Permission>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </div>
  );
}
