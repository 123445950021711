import * as actionTypes from './actionTypes';
import api from '../../util/api';
import Notify from '../../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 * 
 * @param {number} page
 */
export const fetchTransaction = (page, query = {}) => {
  return async (dispatch) => {
    dispatch(fetchTransactionStarted());
    try {
      /**
       * @type any
      */
      const {
        data,
        total
      } = await api.get(`${baseUrl}/transactions`, {
        params: {
          page,
          ...query
        }
      });
      const transactions = data
      dispatch(fetchTransactionSuccess({
        transactions,
        total
      }));

    } catch (error) {
      dispatch(fetchTransactionError(error));
      Notify.error(error.message);
    }
  }
};


export const fetchTransactionStarted = () => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS
  };
};

export const fetchTransactionError = (error) => {
  return {
    payload: error,
    type: actionTypes.FETCH_TRANSACTIONS_ERROR
  };
};

export const fetchTransactionSuccess = (payload) => {
  return {
    payload,
    type: actionTypes.FETCH_TRANSACTIONS_SUCCESS
  };
};
