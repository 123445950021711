// @ts-nocheck
import * as actionTypes from './actionTypes';
import api from '../../util/api';
import Notify from '../../util/Notify';

//const oldBaseUrl = process.env.REACT_APP_BASE_URL;
const baseUrl = "https://api.volitioncap.com/api";

/**
 *
 * @param {number} page
 * @param {string} search
 * @param {{[key:string]:any}} query
 */
export const fetchUsers = (page, search, query = {}) => {
  return async (dispatch) => {
    dispatch(fetchUsersStarted());
    try {
      const {
        data,
        total
      } = await api.get(`${baseUrl}/users`,{
        params: {
          page,
          ...(search? {search}:{}),
          ...query
        }
      });
      dispatch(fetchUsersSuccess({
        users: data,
        total
      }));

    } catch (error) {
      dispatch(fetchUsersError(error));
      Notify.error(error.message);
    }
  }
};

export const fetchUsersStarted = () => {
  return {
    type: actionTypes.FETCH_USERS
  };
};

export const fetchUsersError = (error) => {
  return {
    payload: error,
    type: actionTypes.FETCH_USERS_ERROR
  };
};

export const fetchUsersSuccess = (payload) => {
  return {
    payload,
    type: actionTypes.FETCH_USERS_SUCCESS
  };
};
