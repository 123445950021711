import api from '../util/api';
// import Notify from '../util/Notify';
const baseUrl = 'https://api.volitioncap.com/api';;

/**
 * @param {number} usd_rate
 */
export function setDollarRate(usd_rate){

  return api.post(`${baseUrl}/exchange/set-usd`, {usd_rate});
}
