/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';
import format from 'date-fns/format';
import Notify from '../../util/Notify';
import { Paginator } from '../../components/Paginator';
import download from '../../util/download';
import api from '../../util/api';


export function Spooling() {
  const [page, setPage] = useState(1);

  const defaultspoolData = {
    type: '',
    end_date: '',
    start_date: '',
    status: ''
  }
  const [spools, setSpools] = useState([]);
  //   const [spoolType, setSpoolType] = useState('');
  const [loading, setLoading] = useState(false);
  // const [startDate, setStartDate] = useState(yesterday());
  // const [endDate, setEndDate] = useState(today());
  const [spdata, setSpdata] = useState(defaultspoolData);
  const [total, setTotal] = useState(0);
  const baseUrl = 'https://api.volitioncap.com/api/spool'
  


  // function today() {
  //   var curr = new Date();
  //   return curr.toISOString().substring(0, 10);
  // }

  // function yesterday() {
  //   var curr = new Date();
  //   curr.setDate(curr.getDate() - 1);
  //   return curr.toISOString().substring(0, 10);
  // }

  const updateSpoolData = (inputName, inputData) => {
    if(inputName === 'type') {
      // @ts-ignore
      setSpdata({
        end_date: '',
        start_date: '',
        status: '',
        [inputName]: inputData
      });
    } else {
      setSpdata({
        ...spdata,
        [inputName]: inputData,
      });
    }
  };


    useEffect(() => {
        getAllSpools()
    }, [page]);

  const getAllSpools = () => {
    // e.preventDefault();

    setLoading(true);
    setSpools([]);
    fetch(baseUrl + `/date?page=${page}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(spdata)
    })
      .then((response) => response.json())
      .then((data) => {
        setTotal(data?.data?.total)
        data.data !== undefined ? setSpools(data.data.data) : setSpools([])
      })
      .catch(error => {
        Notify.error(error.message || 'error occured!')
        // this.$toast.error(error.message)
        setLoading(false)
      })
      .finally((data) => setLoading(false));
  }

  function exportSpool(){
    return api.post(`${baseUrl}/export/${spdata.type === 'pool' ? 'active': spdata.status}`, {...spdata})
    .then(function(response){
      download('spool.csv', response);
      Notify.success("Users data exported successfully");
    }).catch(function(err){
      Notify.error(err.message);
    });
  }

  return (
    <>
      <div className="pool-section">
        <div className="section-heading-block margin-bottom">
          <h1 className="brown-text">Spooling</h1>
          <div className="extended-heading-line custom-pools"></div>
          <div className="filter-button-flex-parent">
            {/* <div className="margin-left">
              <label>Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="margin-left">
              <label>End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <button onClick={(event) => {
              event.preventDefault();
              getAllSpools();
            }} className="download-csv w-inline-block margin-left">
              <div>GET SPOOLS</div>
            </button> */}
            <button onClick={(event) => {
              event.preventDefault();
              exportSpool();
            }} className="download-csv w-inline-block margin-left">
              <div>DOWNLOAD CSV</div>
            </button>

          </div>
        </div>
        <div className="portolio-management-form-block">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" onSubmit={getAllSpools}>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Currency" className="form-field-label">
                    Type
                    <span className="gray-text" />
                  </label>
                  <select
                    className="text-input-field portfolio-management w-input"
                    value={spdata.type}
                    onChange={(e) => updateSpoolData('type', e.target.value)}
                    required
                    name="type"
                    data-name="type"
                    id="type">
                    <option disabled value="">Select an option</option>
                    <option value="range">Range</option>
                    <option value="pool">Pool</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Currency" className="form-field-label">
                    Value
                    <span className="gray-text" />
                  </label>
                  <select
                    className="text-input-field portfolio-management w-input"
                    value={spdata.status}
                    required
                    onChange={(e) => updateSpoolData('status', e.target.value)}
                    name="Status"
                    data-name="Status"
                    id="Status">
                    <option disabled value="">Select an option</option>
                    {spdata.type !== 'pool' ? <>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </> :
                      <>
                        <option value="red">Red</option>
                        <option value="green">Green</option>
                        <option value="black">Black</option>
                        <option value="platinum">Platinum</option>
                        <option value="custom">Custom</option>
                        <option value="privilege">Privilege</option>
                      </>}
                  </select>
                </div>
              </div>
              {spdata.type === 'range' && <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="Amount" className="form-field-label">
                    Start Date
                    <span className="gray-text" />
                  </label>
                  <input
                    value={spdata.start_date}
                    onChange={(e) => updateSpoolData('start_date', e.target.value)}
                    type="date"
                    className="text-input-field portfolio-management w-input"
                    name="StartDate"
                    data-name="StartDate"
                    id="StartDate"
                  />
                </div>
              </div>}
              <div>
                <div className="portfolio-managment-input-block full">
                  <label htmlFor="End Date" className="form-field-label">
                    End Date
                    <span className="gray-text" />
                  </label>
                  <input
                    value={spdata.end_date}
                    onChange={(e) => updateSpoolData('end_date', e.target.value)}
                    type="date"
                    className="text-input-field portfolio-management w-input"
                    name="endDate"
                    data-name="endDate"
                    id="endDate"
                  />
                </div>
              </div>
              <div className="submit-button-flex">
                <input
                  type="submit"
                  value="Spool"
                  data-wait="Please wait..."
                  className="send-message-button w-button"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="custom-input-wrapper">
          <div className="filter-button-flex-parent">
          </div>
        </div>
        <div className="parent-table-container overflow-scroll">
          <div className="investment-breakdown-header w-120">
            <div className="column-content transaction-history extended-width">
              <div>Full Name</div>
            </div>
            <div className="column-content transaction-history extended-width">
              <div>Email Address</div>
            </div>
            <div className="column-content transaction-history extended-width">
              <div>Phone Number</div>
            </div>
            <div className="column-content transaction-history">
              <div>Gender</div>
            </div>
            <div className="column-content transaction-history extended-width">
              <div>Pool Name</div>
            </div>
            <div className="column-content transaction-history extended-width">
              <div>Date</div>
            </div>
          </div>
          <div className="investment-breakdown-block w-120">
            {spools.length ? spools.map((details, index) => (
              <Spool details={details} key={index} />
            )): null}
            {spools.length === 0 && loading === false ? (
              <div className="no-active-pool">
                <div>No Spools to display</div>
              </div>
            ) : null}
            {loading === true ? (<Loading isLoading={true} />) : null}
          </div>
        </div>
      </div>
      <Paginator setPage={setPage} total={total} page={page} />
    </>
  );
}

function Spool({ details }) {
  return (
    <div className="table-info-flex">
      <div className="column-content transaction-history extended-width">
        <div className="content-block mobile-header">
          <div>Full Name</div>
        </div>
        <div>
          <div>{(details.fname + ' ' + details.lname) || 'N/A'}</div>
        </div>
      </div>
      <div className="column-content transaction-history extended-width">
        <div className="content-block mobile-header">
          <div>Email Address</div>
        </div>
        <div>
          <div>{details.email || 'N/A'}</div>
        </div>
      </div>
      <div className="column-content transaction-history extended-width">
        <div className="content-block mobile-header">
          <div>Phone Number</div>
        </div>
        <div>
          <div>{details.phone || 'N/A'}</div>
        </div>
      </div>
      <div className="column-content transaction-history">
        <div className="content-block mobile-header">
          <div>Gender</div>
        </div>
        <div>
          <div>{details.gender || 'N/A'}</div>
        </div>
      </div>
      <div className="column-content transaction-history extended-width">
        <div className="content-block mobile-header">
          <div>Pool Name</div>
        </div>
        <div>
          <div>{details.pool_name || 'N/A'}</div>
        </div>
      </div>
      <div className="column-content transaction-history extended-width">
        <div className="content-block mobile-header">
          <div>Date</div>
        </div>
        <div>
          <div>{details.created_at !== undefined ? format(new Date(details.created_at), 'd LLL yyyy') : 'N/A'}</div>
        </div>
      </div>
    </div>
  );
}
