import React, { useEffect } from 'react';
import './App.css';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
// import MovieTable from './components/MovieTable';

import LoadingBar from 'react-redux-loading-bar';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import initWebflow from './util/initWebflow';
import Login from './pages/Login/index';
import { useSelector } from 'react-redux';
import ContributionPool from './pages/ContributionPool';
import Navbar from './components/Navbar';
import { TransactionHistory } from './pages/TransactionHistory';
import { Users } from './pages/Users';
import { UserProfile } from './pages/UserProfile';
import { Referrals } from './pages/Referrals';
import { PortfolioManagement } from './pages/PortfolioManagement';
import { RateEdit } from './pages/PortfolioManagement/RateEdit';
import { CreatePool } from './pages/ContributionPool/CreatePool';
import Overview from './pages/Overview';
import PoolDetails from './pages/ContributionPool/PoolDetails';
import { EditPage } from './pages/EditPage';
import { BroadcastMessage } from './pages/BroadcastMessage';
import { ActivityLog } from './pages/ActivityLog';
import { AdminManagement } from './pages/AdminManagement';
import { VolitionBlue } from './pages/VolitionBlue';
import { VolitionBlueContent } from './pages/VolitionBlueContent';
import { AddSubscription } from './pages/UserProfile/AddSubscription';
import { SendStatement } from './pages/UserProfile/SendStatement';
import { Media } from './pages/Media';
import { VolitionBlueEdit } from './pages/VolitionBlueContent/VolitionBlueEdit';
import { VolitionGrowth } from './pages/VolitionGrowth';
import { VolitionGrowthContent } from './pages/VolitionGrowth/VolitionGrowthContent';
import { VolitionGrowthEdit } from './pages/VolitionGrowth/VolitionGrowthEdit';
import AllReferrals from './pages/Referrals/AllReferrals';
import { AchievedUsers } from './pages/Users/AchievedUsers';
import { EditPortfolio } from './pages/ContributionPool/EditPortfolio';
import { EditTransaction } from './pages/TransactionHistory/EditTransaction';
import { CmsPost } from './pages/CmsPost';
import { CreateTransaction } from './pages/TransactionHistory/CreateTransaction';
import { UpdateMembership } from './pages/UserProfile/UpdateMembership';
import { UpdateGrowthMembership } from './pages/UserProfile/UpdateGrowthMembership';
import { MaturedInvestments } from './pages/MaturedInvestments';
import { ReferralBonus } from './pages/ReferralBonus';
import { DollarRate } from './pages/DollarRate';
// import { Dubai } from './pages/Dubai';
// import { Greensboro } from './pages/Greensboro';
import { Spooling } from './pages/Spooling';
import scrollToTop from './util/scrollToTop';
import Community from './pages/Community/Community';

function App() {
  return (
    <>
      {/* This needs inline styling to work */}
      <LoadingBar
        showFastActions
        style={{
          backgroundColor: '#a0830b',
          height: '4px',
          zIndex: 2000,
          top: 0,
          position: 'fixed',
        }}
      />
      <NotificationContainer />
      <Routes />
    </>
  );
}

function Routes(params) {
  return (
    <Router>
      <Switch>
        <GuestRoute component={Login} path="/login" />
        <Navbar>
          <AuthRoute exact component={Overview} path="/" />
          <AuthRoute exact component={AdminManagement} path="/admins" />
          <AuthRoute exact component={EditPage} path="/edit-page" />
          <AuthRoute exact component={ContributionPool} path="/pools" />
          <AuthRoute exact component={CreatePool} path="/pools/create" />

          <AuthRoute exact component={PoolDetails} path="/pools/:id/details" />
          <AuthRoute
            exact
            component={EditPortfolio}
            path="/users/:user_id/portfolios/:portfolio_id/edit"
          />
          <AuthRoute exact component={PoolDetails} path="/users/:user_id/pools/:id/details" />
          <AuthRoute exact component={MaturedInvestments} path="/matured-investments" />
          <AuthRoute exact component={ReferralBonus} path="/referral-bonus" />
          <AuthRoute exact component={Users} path="/users" />
          <AuthRoute exact component={UserProfile} path="/users/:id" />
          <AuthRoute exact component={AddSubscription} path="/users/:id/add_subscription" />
          <AuthRoute exact component={SendStatement} path="/users/:id/send_statement" />
          <AuthRoute exact component={UpdateMembership} path="/users/:id/update_membership" />
          <AuthRoute
            exact
            component={UpdateGrowthMembership}
            path="/users/:id/update_growth_membership"
          />

          <AuthRoute exact component={TransactionHistory} path="/transaction-history" />
          <AuthRoute exact component={CreateTransaction} path="/transaction-history/create" />
          <AuthRoute exact component={EditTransaction} path="/transaction-history/:id/edit" />

          <AuthRoute exact component={VolitionBlue} path="/volition-extra" />
          <AuthRoute exact component={VolitionBlueContent} path="/volition-extra/:id" />
          <AuthRoute exact component={VolitionBlueEdit} path="/volition-extra/:id/edit" />

          <AuthRoute exact component={VolitionGrowth} path="/volition-growth" />
          <AuthRoute exact component={VolitionGrowthContent} path="/volition-growth/:id" />
          <AuthRoute exact component={VolitionGrowthEdit} path="/volition-growth/:id/edit" />

          <AuthRoute exact component={ActivityLog} path="/activity-logs" />
          <AuthRoute exact component={Referrals} path="/referrals" />
          <AuthRoute exact component={AchievedUsers} path="/archived-users" />
          <AuthRoute exact component={AllReferrals} path="/referrals/all" />
          <AuthRoute exact component={BroadcastMessage} path="/broadcast-messages" />
          <AuthRoute exact component={Media} path="/resources" />
          <AuthRoute exact component={PortfolioManagement} path="/portfolio-management" />
          <AuthRoute exact component={RateEdit} path="/portfolio-management/rate/:id" />

          <AuthRoute exact component={CmsPost} path="/cms-posts" />
          <AuthRoute exact component={DollarRate} path="/dollar-rate" />
          {/* <AuthRoute exact component={Dubai} path="/dubai" /> */}
          {/* <AuthRoute exact component={Greensboro} path="/greensboro" /> */}
          <AuthRoute exact component={Spooling} path="/spooling" />
          <AuthRoute component={Community} path="/community" />
          {/* <Redirect to='/' /> */}
        </Navbar>
        {/* <Redirect to='/' /> */}
      </Switch>
      <ScrollTop />
    </Router>
  );
}

/**
 *
 * @param {{component:React.ElementType,path:string, exact:boolean}} param0
 */
export const AuthRoute = ({ component: Component, ...props }) => {
  const isAuthenticated  = useSelector((state)=>state.auth.authenticated);
  return (
    <Route
      {...props}
      render={innerProps => (isAuthenticated ? <Component {...innerProps} /> : <Redirect to="/login" />)
      }
    />
  );
};

/**
 *
 * @param {{component:React.ElementType, path:string}} param0
 */
export const GuestRoute = ({ component: Component, ...props }) => {
  const isAuthenticated  = useSelector((state)=>state.auth.authenticated);
  return (
    <Route
      {...props}
      render={innerProps => (!isAuthenticated ? <Component {...innerProps} /> : <Redirect to="/" />)
      }
    />
  );
};

const ScrollTop = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  return <></>;
}

export default App;
