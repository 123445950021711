/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useService from '../../util/useService';
import Notify from '../../util/Notify';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { getTransaction, editTransaction } from '../../services/transactionService';
import { fetchPool } from '../../store/actions/poolAction';


export function EditTransaction() {
  const dispatch = useDispatch();
  const history = useHistory();
  /**
   * @type {{id:string}}
   */
  const param = useParams();

  const transactionId = Number(param.id);

  const [showPool, setShowPool] = useState(true);

  const amountRef = useRef(null);

  const descriptionRef = useRef(null);

  const poolInputRef = useRef(null);


  const getTransactionCall = useService(getTransaction);

  /**
   * @type {Pool[]}
   */


  const { pools, loading: poolLoading } = useSelector((state) => state.pool);

  const editTransactionCall = useService(editTransaction);




  useEffect(() => {
    getTransactionCall.call(transactionId).onSuccess((data)=>{
      if(data.pool_id){
        poolInputRef.current.value = data.pool_id;
        setShowPool(true);
      }
      else{
        setShowPool(false);
      }
      descriptionRef.current.value = data.desc;
      amountRef.current.value = data.amount;

    }).onError((e) => Notify.error(e.message));
  }, [transactionId]);

  useEffect(() => {
    dispatch(fetchPool());
  }, [dispatch]);


  /**
   *
   * @param {React.FormEvent<HTMLFontElement>} e
   */
  const submitEditTransaction = (e) => {
    e.preventDefault();
    // @ts-ignore
    const data = new FormData(e.target);

    const pool = data.get('pool');
    const amount = data.get('amount');
    const description = data.get('description');




    const transaction = {
      pool_id: pool,
      amount,
      description
    };


    editTransactionCall
      .call(transactionId, transaction)
      .onSuccess(() => {
        history.goBack();
        Notify.success('Transaction edited successfully!');
      })
      .onError((e) => Notify.error(e.message));
  };

  return (
    <div className="pool-section">
      <div className="back-button-parent">
        <span className="back-button-flex w-inline-block" onClick={history.goBack}>
          <div className="back-button-icon" />
          <div>Back</div>
        </span>
      </div>
      <div className="section-heading-block margin-bottom">
        <h1 className="brown-text">Edit Transaction</h1>
        <div className="extended-heading-line custom-pools" />
      </div>
      <div className="pool-section-inner-flex message">
        <div className="dashboard-content-wrapper messages create">
          <div className="dashboard-content-wrapper-top-border-wrapper">
            <div className="smaller-border-line" />
            <div className="dashboard-content-heading flex">
              <div className="margin-right">Transaction Details</div>
            </div>
            <div className="extender-border" />
          </div>
          <div className="dashboard-content-wrapper-inner">
            <div className="recent-transaction-card edit-information">
              <div className="w-form">
                <form onSubmit={submitEditTransaction} id="email-form" name="email-form" data-name="Email Form">
                  <div className="portfolio-management-form-flex edit-information">
                    {showPool? <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Select Investment Pool
                      </label>
                      <select
                        id="pool"
                        name="pool"
                        data-name="pool"
                        required
                        className="porfolio-management-select-input w-select"
                        ref={poolInputRef}
                      >
                        <option value="" disabled selected key="default">
                          {poolLoading ? 'Loading...' : ''}
                        </option>
                        {pools.map((pool) => (
                          <option key={pool.id} value={pool.id}>
                            {pool.name}
                          </option>
                        ))}
                      </select>
                    </div>:null
                    }<div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Amount
                      </label>
                      <input
                        ref={amountRef}
                        // min={selectedPool?.min_amount ?? 0}
                        type='number'
                        id="amount"
                        name="amount"
                        data-name="amount"
                        required
                        className="porfolio-management-select-input w-input"
                      />
                    </div>
                  </div>
                  <div className="portfolio-managment-input-block edit-information">
                      <label htmlFor="pool" className="form-field-label small">
                        Description
                      </label>
                      <textarea name='description' ref={descriptionRef} className="porfolio-management-select-input w-input"></textarea>
                    </div>
                  <div className="submit-button-flex">
                    <input
                      type="submit"
                      value={editTransactionCall.loading ? 'Please wait...' : 'Update'}
                      className={clsx('button w-button', {
                        'btn-disabled': editTransactionCall.loading,
                      })}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
