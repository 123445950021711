export const extractDayAndMonth = (dateString) => {
  const _months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const year = date.getFullYear();

  const month = date.getUTCMonth();
  return `${day} ${_months[month]}, ${year}`;
};
