import React from 'react';
import { Link, useHistory, Switch, Route } from 'react-router-dom';
// import Broadcast from './Broadcast';
import Members from './Members';
import SuggestedDeals from './SuggestedDeals';
import DealRoom from './DealRoom';
// import Members from './Members';

const communityNav = [
  {
    link: '/community',
    name: 'Members',

    icon_white: 'community-icon-white.svg',
    icon_black: 'community-icon-black.svg',
  },
  {
    link: '/broadcast-messages',
    // link: '/community/broadcast',
    name: 'Broadcast',

    icon_white: 'message-icon-white.svg',
    icon_black: 'message-icon-black.svg',
  },
  {
    link: '/community/suggested-deals',
    name: 'Suggested Deals',

    icon_white: 'handshake-icon-white.svg',
    icon_black: 'handshake-icon-black.svg',
  },
  {
    link: '/community/deal-room',
    name: 'Deal Room',

    icon_white: 'deal-icon-white.svg',
    icon_black: 'deal-icon-black.svg',
  },
  // {
  //   link: "/community/mastermind-group",
  //   name: "Join Mastermind Group"
  // }
];

const Community = ({ children }) => {
  const history = useHistory();

  const currentPath = history.location.pathname;
  return (
    <main className="cmnt_content_wrapper">
      <nav className="cmnt_content_options">
        {communityNav.map((item) => {
          return (
            <Link
              key={item.name}
              className={`cmnt_nav_link ${currentPath === item.link ? 'active' : ''}`}
              to={item.link}
            >
              <img
                src={`/assets/images/${
                  currentPath === item.link ? item.icon_white : item.icon_black
                }`}
                className="cmnt_nav_link_img"
                alt=""
              />
              <p className={`cmnt_nav_link_text ${currentPath === item.link ? 'active' : ''}`}>
                {item.name}
              </p>
            </Link>
          );
        })}
      </nav>
      <section class="cmnt_content_main">
        <Switch>
          <Route exact component={Members} path="/community" />
          {/* <Route exact component={Broadcast} path="/community/broadcast" /> */}
          <Route exact component={SuggestedDeals} path="/community/suggested-deals" />
          <Route exact component={DealRoom} path="/community/deal-room" />
        </Switch>
      </section>
    </main>
  );
};

export default Community;
